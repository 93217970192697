import { connect } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { uploadLicense } from '../../../actions/licenses.actions';
import { FileUploadDialog } from '../components/FileUploadDialog.component';
import { TFileUploadDialogProps } from '../types/FileUploadDialog.types';

type TFileUploadLicenseDialpgOwnProps = {
    visible: boolean;
    serverId: string;
};

const mapDispatchToProps = (dispatch, ownProps: TFileUploadLicenseDialpgOwnProps): Partial<TFileUploadDialogProps> => ({
    onClose: () => dispatch(closeDialog(DialogType.UPLOAD_LICENSE_DIALOG)),
    onSubmit: (license: File, serverId: string) => dispatch(uploadLicense({ license, serverId })),
});

export const FileUploadLicenseDialogContainer = connect(null, mapDispatchToProps)(FileUploadDialog);
