import React, { FC, useEffect } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import theme from './WikiEditor.component.scss';
import SplitPane from 'react-split-pane';
import RichTextEditor from '../../UIKit/components/RichTextEditor/Editor/RichTextEditor.component';
import { CommentsPanelContainer } from '@/modules/Comments/containers/CommentsPanel.container';
import { CommentPopoverContainer } from '@/modules/Comments/containers/CommentPopover.container';
import { NodeId } from '@/serverapi/api';
import { useDefaultCommentsSize } from '../../Comments/hooks/useDefaultCommentsSize';
import type { TDefaultCommentSize } from '@/modules/Comments/types/defaultCommentSize.types';

type TWikiEditorHandlers = {
    imageLinkMapper: (src: string, baseUrl: string) => string;
    readFromClipboard: (callback: (text: string) => void) => void;
    copyToClipboard: (text: string) => void;
};

type TWikiEditor = {
    id: string;
    value: string | undefined;
    zoomLevel?: number;
    onChange: (source: string) => void;
    onRendered?: () => void;
    handlers: TWikiEditorHandlers;
    panels?: {
        sidePanel?: React.ComponentType;
    };
    isShowCommentsPanel?: boolean;
    nodeId?: NodeId;
};

const WikiEditor: FC<TWikiEditor> = ({
    id,
    value,
    zoomLevel = 100,
    onChange,
    onRendered,
    handlers,
    isShowCommentsPanel,
    nodeId
}) => {

    const {defaultCommentSize, changeCommentsTabSizeHandler }: TDefaultCommentSize = useDefaultCommentsSize();

    useEffect(() => {
        if (onRendered) {
            onRendered();
        }
    }, []);

    return (
        <div className={theme.editorContainer} data-test='wiki-editor-outer_container'>
            {/* @ts-ignore */}
            <SplitPane
                split="vertical"
                primary="second"
                defaultSize={isShowCommentsPanel ? defaultCommentSize : 0}
                maxSize={isShowCommentsPanel ? 750 : 0}
                minSize={isShowCommentsPanel ? 300 : 0}
                paneStyle={{ overflow: 'auto' }}
                resizerClassName={isShowCommentsPanel ? theme.resizer : theme.hideResizer}
                pane2Style={{ borderLeft: '1px solid #d9d9d9' }}
                onChange={changeCommentsTabSizeHandler}

            >
                <RichTextEditor
                    id={id}
                    value={value}
                    zoomLevel={zoomLevel}
                    onChange={onChange}
                    handlers={handlers}
                    CommentPopoverContent={CommentPopoverContainer}
                    modelId={nodeId}
                />

                <CommentsPanelContainer modelNodeId={nodeId}/>
            </SplitPane>
        </div>
    );
};

export default WikiEditor;
