import * as React from 'react';
import { injectIntl } from 'react-intl';
import { GraphObjectToolbar } from '../../GraphObjectToolbar/GraphObjectToolbar.component';
import { homePageTabId } from '../../../../../models/home-page';
import { AdjustToolbarSizeComponent } from '../../AdjustToolbarSize/AdjustToolbarSize.component';
import { TObjectMenuItemProps } from './ObjectMenuItem.types';

const toolbarComponents = {
    Editor: GraphObjectToolbar,
};

class ObjectMenuItemComponent extends AdjustToolbarSizeComponent<TObjectMenuItemProps> {
    getToolbar(toolbarName: string) {
        const { compact, activeKey } = this.props;
        const ToolbarComponentTag = toolbarComponents[toolbarName];
        if (activeKey && activeKey.id !== homePageTabId.id) {
            // todo: 1647: mb bug
            return <ToolbarComponentTag compact={compact} />;
        }

        return super.getToolbar(toolbarName);
    }
}

const ObjectMenuItemWithIntl = injectIntl(ObjectMenuItemComponent);

export { ObjectMenuItemWithIntl as ObjectMenuItem };
