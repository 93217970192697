import React, { ChangeEvent, FC, useRef, useState, useEffect } from 'react';
import theme from './KanbanCardEditor.scss';
import { useIntl } from 'react-intl';
import messages from './KanbanCardEditor.messages';
import { IWorkspaceTabItemOpenKanbanCardEditorParams, TWorkspaceTab } from '../../../../../../../models/tab.types';
import {
    AttributeType,
    InternationalString,
    KanbanCardGridSettings,
    KanbanCardSectorSettings,
    KanbanCardType,
} from '../../../../../../../serverapi/api';
import { Button, Form, FormInstance } from 'antd';
import { MultiLangInputDialog } from '../../../../../../MultiLangInputDialog/MultiLangInputDialog.component';
import { useDispatch, useSelector } from 'react-redux';
import { WorkspaceTabDataSelectors } from '../../../../../../../selectors/workspaceTabData.selectors';
import {
    changeKanbanCardGridSettings,
    changeKanbanCardSectorSettings,
    changeKanbanCardTypeId,
    changeKanbanCardTypeName,
    deleteKanbanCardAttributes,
} from '../../../../../../../actions/workspaceTab/editKanbanCardWorkspaceTab.actions';
import { EditorFooterButtons } from '../../Footer/EditorFooterButtons.component';
import { workspaceRemoveTabByNodeId } from '../../../../../../../actions/tabs.actions';
import { submitKanbanCardRequestAction } from '../../../../../../../actions/symbol/symbolEditor.actions';
import { TSubmitKanbanCardRequestPayload } from '../../../../../../../actions/symbol/symbolEditor.actions.types';
import { KanbanCardGridControlDialog } from './KanbanCardGridControlDialog.component';
import { LocalesService } from '../../../../../../../services/LocalesService';
import {
    DEFAULT_KANBAN_ATTRIBUTE_TYPE_ID,
    getRangeAndSectorsToHighlight,
    getSectorSettingsOnMerge,
    getSectorSettingsOnSplit,
} from '../../util/KanbanCardEditor.utils';
import { KanbanCardSectorFormattingSettings } from './KanbanCardSectorFormattingEditor.component';
import { InputId } from '../../../../../../InputId/InputId.component';
import { ObjectTypeSelectors } from '../../../../../../../selectors/objectType.selectors';
import { KanbanCardAttributesEditor } from './KanbanCardAttributesEditor/KanbanCardAttributesEditor.component';
import { AttributeValueType } from '../../../../../../../modules/FloatingAttributes/components/AttributesEditor/Attribute.types';
import { TSectorPosition } from './KanbanCardEditor.types';

type TKanbanCardEditorProps = {
    tab: TWorkspaceTab;
};

const KanbanCardEditor: FC<TKanbanCardEditorProps> = (props) => {
    const params = props.tab.params as IWorkspaceTabItemOpenKanbanCardEditorParams;
    const intl = useIntl();
    const dispatch = useDispatch();
    const formRef = useRef<FormInstance>(null);
    const cardRef = useRef<HTMLDivElement>(null);
    const locale = LocalesService.getLocale();
    const { preset, breadCrumbsSymbolName, objectTypeName, isNewKanbanCardType, serverNode, kanbanCardTypeId } = params;
    const serverId = serverNode.nodeId.serverId;
    const presetId = preset.id;
    const [showKanbanCardGridControlDialog, setShowKanbanCardGridControlDialog] = useState(false);
    const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(false);
    const [rulesValidation, setRulesValidation] = useState<boolean>(true);

    const kanbanCardType: KanbanCardType = useSelector(
        WorkspaceTabDataSelectors.getEditKanbanCardType(presetId, kanbanCardTypeId),
    );
    const rows = kanbanCardType.gridSettings.rows;
    const cols = kanbanCardType.gridSettings.columns;
    const allSectorsSettings = kanbanCardType.sectorSettings;
    const allAttributes = kanbanCardType.sectorAttributes;

    const objectType = useSelector(
        ObjectTypeSelectors.byId({
            objectTypeId: kanbanCardType.objectTypeId,
            presetId,
            serverId,
        }),
    )!;

    const [pressedSector, setPressedSector] = useState<KanbanCardSectorSettings | null>(null);
    const [highLightedSectors, setHighLightedSectors] = useState<KanbanCardSectorSettings[]>([]);
    const [highlightedRange, setHighlightedRange] = useState<TSectorPosition | null>(null);
    const [selectedSector, setSelectedSector] = useState<KanbanCardSectorSettings | null>(allSectorsSettings[0]);

    useEffect(() => {
        document.getElementById(`card-${kanbanCardType.id}-sector-A1`)?.classList.add('kanbanCardSectorActive');
        document.addEventListener('click', onClickOutsideCard);
        checkSubmitEnabled();
        return () => document.removeEventListener('click', onClickOutsideCard);
    }, []);

    const onClickOutsideCard = (e) => {
        if (!cardRef?.current?.contains(e.target)) {
            let highlightedNodes = document.querySelectorAll(
                `#card-${kanbanCardType.id}-grid-container > .kanbanCardSectorActive`,
            );
            if (highlightedNodes.length <= 1) return;

            highlightedNodes.forEach((elem) => {
                elem.classList.remove('kanbanCardSectorActive');
            });

            setHighLightedSectors([]);
            setPressedSector(null);
            setHighlightedRange(null);

            setSelectedSector(null);
        }
    };

    const showNavigationTitle = () => (
        <>
            <span className={theme.navigationTitle}>
                {intl.formatMessage(messages.symbolBreadCrumbs, {
                    presetName: preset.name,
                    objectTypeName,
                    symbolName: breadCrumbsSymbolName,
                })}
            </span>
        </>
    );

    const showHeader = () => {
        const onChangeName = (multilingualName: InternationalString) => {
            dispatch(changeKanbanCardTypeName({ multilingualName, presetId, kanbanCardTypeId }));
            checkSubmitEnabled();
        };

        const onChangeId = (e: ChangeEvent<HTMLInputElement>) => {
            dispatch(changeKanbanCardTypeId({ presetId, kanbanCardTypeId, id: e.target.value.trim() }));
            checkSubmitEnabled();
        };

        return (
            <Form className={theme.headerInputs} ref={formRef} layout="vertical">
                <MultiLangInputDialog
                    placeholder={intl.formatMessage(messages.newSymbol)}
                    multiLangValue={kanbanCardType.multilingualName}
                    onChange={onChangeName}
                    label={intl.formatMessage(messages.name)}
                    required
                    generalForm={formRef.current}
                    mainInputName="multilingualName"
                    data-test="kanban-card-symbol-name-input"
                />

                <InputId
                    value={kanbanCardType.id}
                    onChange={onChangeId}
                    required
                    disabled={!isNewKanbanCardType}
                    mainInputName="GUID"
                />
            </Form>
        );
    };

    const checkSubmitEnabled = () => {
        const generalForm = formRef.current;
        const multilingualName: string = generalForm?.getFieldValue('multilingualName')?.trim();
        const guid: string = generalForm?.getFieldValue('GUID')?.trim();

        return setIsSubmitEnabled(multilingualName.length > 0 && guid.length > 0);
    };

    const showFooter = () => {
        return (
            <EditorFooterButtons
                buttons={[
                    {
                        name: intl.formatMessage(messages.cancel),
                        onAction: () => dispatch(workspaceRemoveTabByNodeId(props.tab.nodeId)),
                    },
                    {
                        name: isNewKanbanCardType ? intl.formatMessage(messages.create) : intl.formatMessage(messages.save),
                        type: "primary",
                        disabled: !isSubmitEnabled || kanbanCardType.id.length < 1 || !rulesValidation,
                        onAction: () => {
                            const form = formRef.current;
                            if (form) {
                                form.validateFields()
                                    .then(() => {
                                        const payload: TSubmitKanbanCardRequestPayload = {
                                            serverId,
                                            kanbanCardType,
                                            presetId,
                                            isNewKanbanCardType,
                                            needTabClose: true,
                                            tabNodeId: props.tab.nodeId,
                                        };
                                        dispatch(submitKanbanCardRequestAction(payload));
                                    })
                                    .catch(() => undefined);
                            }
                        },
                    },
                ]}
            />
        );
    };

    const highlightSectors = (hoveredSector: KanbanCardSectorSettings) => {
        if (!pressedSector) return;

        if (pressedSector.name === hoveredSector.name)
            return document
                .getElementById(`card-${kanbanCardType.id}-sector-${hoveredSector.name}`)
                ?.classList.add('kanbanCardSectorActive');

        const { highlightRange, sectorsToHighlight } = getRangeAndSectorsToHighlight(
            pressedSector,
            hoveredSector,
            allSectorsSettings,
        );

        setHighlightedRange(highlightRange);
        setHighLightedSectors(sectorsToHighlight);
        sectorsToHighlight.forEach(({ name }) => {
            document
                .getElementById(`card-${kanbanCardType.id}-sector-${name}`)
                ?.classList.add('kanbanCardSectorActive');
        });
    };

    const onCardSectorHover = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!pressedSector) return;
        const hoveredElement = e.target as HTMLElement;

        highLightedSectors.forEach(({ name }) => {
            document
                .getElementById(`card-${kanbanCardType.id}-sector-${name}`)
                ?.classList.remove('kanbanCardSectorActive');
        });
        setHighLightedSectors([]);
        setHighlightedRange(null);

        const hoveredSector = allSectorsSettings.find((sector) => hoveredElement.dataset.name === sector.name);
        if (hoveredSector) highlightSectors(hoveredSector);
    };

    const showCardSectors = () => {
        return (
            <>
                {allSectorsSettings.map((sector) => (
                    <div
                        style={{
                            gridColumnStart: sector.position.start.column,
                            gridColumnEnd: sector.position.end.column + 1,
                            gridRowStart: sector.position.start.row,
                            gridRowEnd: sector.position.end.row + 1,
                        }}
                        data-name={sector.name}
                        className={theme.cardSector}
                        key={sector.name}
                        id={`card-${kanbanCardType.id}-sector-${sector.name}`}
                        onMouseEnter={onCardSectorHover}
                    >
                        {sector.name}
                    </div>
                ))}
            </>
        );
    };

    const deleteAttributesBySectorNames = (sectorNames: string[]) => {
        const attributeIdsToDelete: string[] = [];
        allAttributes.forEach((attr) => {
            if (sectorNames.includes(attr.sectorName)) {
                attributeIdsToDelete.push(attr.id);
            }
        });
        dispatch(
            deleteKanbanCardAttributes({
                presetId,
                attributeIds: attributeIdsToDelete,
                kanbanCardTypeId,
            }),
        );
    };

    const onGridSettingsSubmit = (
        gridSettings: KanbanCardGridSettings,
        sectorSettings: KanbanCardSectorSettings[] | null,
    ): void => {
        dispatch(changeKanbanCardGridSettings({ gridSettings, presetId, kanbanCardTypeId }));
        if (sectorSettings) {
            if (sectorSettings.length < allSectorsSettings.length) {
                const deletedSectorNames = allSectorsSettings
                    .filter((sector) => !sectorSettings.find(({ name }) => name === sector.name))
                    .map(({ name }) => name);
                deleteAttributesBySectorNames(deletedSectorNames);
            }
            dispatch(changeKanbanCardSectorSettings({ sectorSettings, presetId, kanbanCardTypeId }));
        }
    };

    const updateCardSectorsSettings = (settings: KanbanCardSectorSettings[]): void => {
        const newSettings = [...settings];
        dispatch(
            changeKanbanCardSectorSettings({
                sectorSettings: newSettings.sort((sectorA, sectorB) => sectorA.name.localeCompare(sectorB.name)),
                presetId,
                kanbanCardTypeId,
            }),
        );
    };

    const updateSelectedCardSectorSettings = (sectorSettings: KanbanCardSectorSettings): void => {
        const newSettings: KanbanCardSectorSettings[] = allSectorsSettings.filter(
            ({ name }) => name !== sectorSettings.name,
        );
        newSettings.push(sectorSettings);
        updateCardSectorsSettings(newSettings);
        setSelectedSector(sectorSettings);
    };

    const onSectorMerge = (): void => {
        if (!highlightedRange || !highLightedSectors) return;

        const { newCardSectorSettings, deletedSectorNames } = getSectorSettingsOnMerge(
            highlightedRange,
            highLightedSectors,
            allSectorsSettings,
        );
        deleteAttributesBySectorNames(deletedSectorNames);
        setSelectedSector(newCardSectorSettings[0]);
        updateCardSectorsSettings(newCardSectorSettings);
        setHighLightedSectors([]);
        setHighlightedRange(null);
    };

    const onSectorSplit = () => {
        if (!selectedSector) return;

        const newCardSectorSettings = getSectorSettingsOnSplit(selectedSector, allSectorsSettings);
        setSelectedSector(newCardSectorSettings[0]);
        updateCardSectorsSettings(newCardSectorSettings);
    };

    const onCardGridMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        allSectorsSettings.forEach(({ name }) => {
            document
                .getElementById(`card-${kanbanCardType.id}-sector-${name}`)
                ?.classList.remove('kanbanCardSectorActive');
        });
        const pressedElement = e.target as HTMLElement;
        setHighLightedSectors([]);
        pressedElement.classList.add('kanbanCardSectorActive');

        const pressedSector = allSectorsSettings.find((sector) => pressedElement.dataset.name === sector.name);
        if (!pressedSector) return;
        setPressedSector(pressedSector);
        setSelectedSector(pressedSector);
    };

    const showSectorsActionBtn = () => {
        if (highLightedSectors.length > 1) {
            return (
                <Button className={theme.cardSectorChangeBtn} onClick={onSectorMerge}>
                    {intl.formatMessage(messages.mergeCells)}
                </Button>
            );
        }

        return highLightedSectors.length === 0 &&
            (selectedSector?.position.start.column !== selectedSector?.position.end.column ||
                selectedSector?.position.start.row !== selectedSector?.position.end.row) ? (
            <Button className={theme.cardSectorChangeBtn} onClick={onSectorSplit}>
                {intl.formatMessage(messages.splitCells)}
            </Button>
        ) : null;
    };

    const showSectorFormattingSettings = () => {
        if (!selectedSector || highLightedSectors.length > 1) return null;

        return (
            <KanbanCardSectorFormattingSettings
                sector={selectedSector}
                onChangeSettings={updateSelectedCardSectorSettings}
            />
        );
    };

    const resetSelectedSector = () => {
        allSectorsSettings.forEach(({ name }) => {
            document
                .getElementById(`card-${kanbanCardType.id}-sector-${name}`)
                ?.classList.remove('kanbanCardSectorActive');
        });
        setSelectedSector(null);
    };

    const showAttributesEditor = () => {
        const attributeTypes: AttributeType[] = objectType.nodeAttributes.map(
            ({ id, presetId, name, multilingualName, valueType }) => ({
                id,
                presetId,
                valueType,
                name: LocalesService.internationalStringToString(multilingualName, locale) || name,
            }),
        );
        const defaultAttributeType = {
            id: DEFAULT_KANBAN_ATTRIBUTE_TYPE_ID,
            presetId,
            valueType: AttributeValueType.STRING,
            name: intl.formatMessage(messages.plainTextOutput),
        } as AttributeType;
        const allAttributeTypes = [defaultAttributeType, ...attributeTypes];

        const sectorNames: string[] = allSectorsSettings.map((sector) => sector.name);
        return (
            <KanbanCardAttributesEditor
                attributeTypes={allAttributeTypes}
                sectorAttributes={allAttributes}
                presetId={presetId}
                serverId={serverId}
                kanbanCardTypeId={kanbanCardTypeId}
                sectorNames={sectorNames}
                setRulesValidation={setRulesValidation}
            />
        );
    };

    return (
        <div className={theme.symbolEditorTabContainer}>
            <KanbanCardGridControlDialog
                visible={showKanbanCardGridControlDialog}
                onClose={() => {
                    setShowKanbanCardGridControlDialog(false);
                }}
                onSubmit={onGridSettingsSubmit}
                gridSettings={kanbanCardType.gridSettings}
                sectorSettings={kanbanCardType.sectorSettings}
                id={kanbanCardType.id}
                resetSelectedSector={resetSelectedSector}
            />
            {showNavigationTitle()}
            <div className={theme.tabContent}>
                {showHeader()}
                <div className={theme.mainSettings}>
                    <div className={theme.left}>
                        <div className={theme.gridSettingWrapper}>
                            <button
                                className={theme.gridSettingsBtn}
                                onClick={() => setShowKanbanCardGridControlDialog(true)}
                            >
                                {intl.formatMessage(messages.cardGridControl)}
                            </button>
                            <div
                                ref={cardRef}
                                className={theme.cardGrid}
                                id={`card-${kanbanCardType.id}-grid-container`}
                                style={{
                                    gridTemplateColumns: `repeat(${cols.length}, 1fr)`,
                                    gridTemplateRows: `repeat(${rows.length}, 1fr)`,
                                }}
                                onMouseDown={onCardGridMouseDown}
                                onMouseUp={() => {
                                    setPressedSector(null);
                                }}
                            >
                                {showCardSectors()}
                            </div>
                            {showSectorsActionBtn()}
                        </div>
                        {showSectorFormattingSettings()}
                    </div>
                    <div className={theme.right}>{showAttributesEditor()}</div>
                </div>
            </div>
            <div className={theme.footer}>{showFooter()}</div>
        </div>
    );
};

export { KanbanCardEditor };
