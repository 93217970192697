// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Icon__container__PboBS{width:16px;height:16px;border-radius:0}.Icon__icon_disabled__Wf070{-webkit-filter:grayscale(1) opacity(0.4);filter:grayscale(1) opacity(0.4)}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/Icon/Icon.scss"],"names":[],"mappings":"AAAA,wBACC,UAAA,CACA,WAAA,CACC,eAAA,CAGF,4BACI,wCAAA,CAAA,gCAAA","sourcesContent":[".container {\r\n\twidth: 16px;\r\n\theight: 16px;\r\n  border-radius: 0;\r\n}\r\n\r\n.icon_disabled {\r\n    filter: grayscale(1) opacity(.4);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Icon__container__PboBS",
	"icon_disabled": "Icon__icon_disabled__Wf070"
};
export default ___CSS_LOADER_EXPORT___;
