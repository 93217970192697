import type { IComplexSymbol, ISymbolPreviewGeometry, TRootCellValue } from './symbols/ComplexSymbol.class.types';
import type { DiagramElement, ObjectInstance, Symbol } from '../../serverapi/api';
import type { ObjectDefinitionImpl } from '../../models/bpm/bpm-model-impl';
import type { MethodologiesGraph } from '../MethodologiesGraph';
import type { MxCell, MxPopupMenu, MxRectangle } from '../mxgraph';
import { v4 as uuid } from 'uuid';
import { cellsTreeWalkerDown } from './utils';
import { BPMMxGraph } from '../bpmgraph';
import { SymbolTypeId, SequenceSymbolTypeId } from './symbols/ComplexSymbol.constants';
import { findComplexSymbolImplementation } from './ComplexSymbolTypeId.map';
import createComplexSymbol from './symbols/createComplexSymbol';
import { differenceBy, uniqBy } from 'lodash-es';
import { MoveLayer } from '@/models/movelayer';
import {
    changeCellsLayerIndex,
    hideCellBox,
    showCellBox,
    copySizesToCell,
    copyStylesToCell,
    copyPositionToLabel,
} from '@/utils/bpm.mxgraph.utils';

interface IComplexSymbolManager {
    createComplexSymbol<P = any>(symbol: Symbol, objectDefinition: ObjectDefinitionImpl, overrideProps: P): void;
    restoreComplexSymbol(element: TRootCellValue, symbols: Symbol[], customProps?: any): MxCell;
    removeManagedCell(cell: MxCell): void;
    loadPopupMenu(menu: MxPopupMenu, cell: MxCell): void;
    convertValueToString(cell: MxCell): string;
    prepareDiagramElements(cells: MxCell[]): DiagramElement[];
    refreshCells(): void;
    getSearchValue(cell: MxCell): string;
    moveCellsLayer(moveLayer: MoveLayer): void;
    redrawCellBoxes(cells: MxCell[], prevCells: MxCell[]): MxCell[];
    formatSelectedCellByExample(): void;
    setObjectToCell(cell: MxCell, object: ObjectDefinitionImpl): void;
}

interface IComplexSymbolManagerOptions {
    staticSymbols?: boolean;
}

export class ComplexSymbolManager implements IComplexSymbolManager {
    graph: BPMMxGraph;
    managedCells: MxCell[];
    staticSymbols: boolean;

    static getSymbolType(symbol: Symbol): SymbolTypeId | SequenceSymbolTypeId {
        if (typeof symbol?.symbolTypeId === 'string') {
            return symbol.symbolTypeId as SymbolTypeId;
        }
        // обратная совместимость. Раньше в id символа хранился тип символа.
        // нужно возвращать только symbolTypeId, когда он будет храниться во всех символах всех пресетов

        return findComplexSymbolImplementation(symbol.id).symbolTypeId;
    }

    static isComplexSymbolCell(cell: MxCell): boolean {
        return !!cell?.complexSymbolRef;
    }

    static getComplexSymbolInstance(cell: MxCell): IComplexSymbol | null {
        return cell?.complexSymbolRef || null;
    }

    // TODO впоследствии нужно вынести в классы символов
    // getBase64String, isSymbolWithPreview
    static getBase64String(symbol: Symbol, graph?: MethodologiesGraph): string | undefined {
        const symbolType = ComplexSymbolManager.getSymbolType(symbol) as SymbolTypeId;

        if (symbolType === SymbolTypeId.SIMPLE) {
            const svg = graph?.convertSymbolToSvgImage(symbol);

            return svg ? `data:image/svg+xml;base64,${Base64.encode(svg.svgString)}` : undefined;
        } else {
            return symbol.icon;
        }
    }

    static isSymbolWithPreview(symbol: Symbol): boolean {
        const symbolType = ComplexSymbolManager.getSymbolType(symbol) as SymbolTypeId;

        return symbolType === SymbolTypeId.SIMPLE;
    }

    static isHiddenEdgeConnectableObject({ symbolId }: ObjectInstance, symbols: Symbol[]) {
        const hiddenEdgeConnectableSymbols = [
            SymbolTypeId.HORIZONTAL_SWIMLANE,
            SymbolTypeId.VERTICAL_SWIMLANE,
            SymbolTypeId.HORIZONTAL_POOL,
            SymbolTypeId.VERTICAL_POOL,
            SymbolTypeId.CROSS,
        ];
        const symbol = symbols.find(({ id }) => id === symbolId);

        if (!symbolId || !symbol) {
            return false;
        }

        const symbolType = ComplexSymbolManager.getSymbolType(symbol) as SymbolTypeId;

        return hiddenEdgeConnectableSymbols.includes(symbolType);
    }

    static getComplexSymbolRootCell(cell: MxCell): MxCell | undefined {
        return ComplexSymbolManager.getComplexSymbolInstance(cell)?.getRootCell();
    }

    static getComplexSymbolLabelCell(cell: MxCell): MxCell | undefined {
        return ComplexSymbolManager.getComplexSymbolInstance(cell)?.getLabelCell();
    }

    static isComplexSymbolRootCell(cell: MxCell): boolean {
        return ComplexSymbolManager.getComplexSymbolRootCell(cell)?.getId() === cell.getId();
    }

    static isComplexSymbolLabelCell(cell: MxCell): boolean {
        return ComplexSymbolManager.getComplexSymbolLabelCell(cell)?.getId() === cell.getId();
    }

    /**
     *
     * Возвращает ячейку, которую можно копировать.
     *
     * - в случае, если ячейка не является частью сложного символа, то можно копировать саму ячейку;
     * - в случае, если ячейка является частью сложного символа, то ячейку для копирования определяет инстанс символа.
     *
     * @param cell
     *
     */
    static getCopyableCell(cell: MxCell): MxCell | null {
        const symbolInstance = ComplexSymbolManager.getComplexSymbolInstance(cell);

        if (!symbolInstance) {
            return cell;
        }

        return !symbolInstance.isCellDisabled() ? symbolInstance.getCopyableCell() : null;
    }

    static isCellMovable(cell: MxCell): boolean {
        const symbolInstance = ComplexSymbolManager.getComplexSymbolInstance(cell);

        if (!symbolInstance) {
            return true;
        }

        return symbolInstance.isCellMovable(cell);
    }

    static getMovableCells(cells: MxCell[]): MxCell[] {
        const commonCells: MxCell[] = [];
        const result: Record<string, MxCell> = {};

        cells.forEach((cell) => {
            const symbolInstance = ComplexSymbolManager.getComplexSymbolInstance(cell);

            // в случае несложного символа просто возвращаем ячейку
            if (!symbolInstance) {
                commonCells.push(cell);

                return;
            }

            if (!symbolInstance.isCellMovable(cell)) {
                return;
            }

            // порядок добавления ячеек важен
            // если добавить сначала лейбл, то при перемещении в другой контейнер лейбл будет перекрыт основной ячейкой
            result[cell.id] = cell;

            const labelCell = symbolInstance.getLabelCell();

            // если перемещается лейбл ячейка, то берем только лейбл
            // если перемещается главная ячейка, то берем и лейбл
            if (labelCell && labelCell?.id !== cell.id) {
                result[labelCell.id] = labelCell;
            }
        });

        return [...Object.values(result), ...commonCells];
    }

    /**
     *
     * Определяет, можно ли изменять стиль ячейки.
     *
     * - в случае, если ячейка не является частью сложного символа, то стиль можно редактировать;
     * - в случае, если ячейка является частью сложного символа, то возможность редактирования стиля определяет инстанс символа.
     *
     * @param cell
     *
     */
    static isCellStyleEditable(cell: MxCell): boolean {
        const symbolInstance = ComplexSymbolManager.getComplexSymbolInstance(cell);

        if (!symbolInstance) {
            return true;
        }

        return symbolInstance.isCellStyleEditable(cell);
    }

    static isCellEditable(cell: MxCell): boolean {
        const symbolInstance = ComplexSymbolManager.getComplexSymbolInstance(cell);

        if (!symbolInstance) {
            return true;
        }

        return !!symbolInstance.hasEditableLabel() && !symbolInstance.isCellDisabled();
    }

    static getCellForRename(cell: MxCell): MxCell {
        const symbolInstance = ComplexSymbolManager.getComplexSymbolInstance(cell);

        if (!symbolInstance) {
            return cell;
        }

        return symbolInstance.getCellForRename(cell);
    }

    static getResizedCells(cells: MxCell[], bounds: MxRectangle[]): [MxCell[], MxRectangle[]] {
        const newCells: MxCell[] = [];
        const newBounds: MxRectangle[] = [];

        cells.forEach((cell, index) => {
            if (ComplexSymbolManager.isComplexSymbolLabelCell(cell)) {
                return;
            }

            const bound = bounds[index];
            const symbolInstance = ComplexSymbolManager.getComplexSymbolInstance(cell);

            if (symbolInstance?.getResizedCells) {
                const [updatedCells, updatedBounds] = symbolInstance?.getResizedCells?.(bound);

                updatedCells.forEach((t) => newCells.push(t));
                updatedBounds.forEach((t) => newBounds.push(t));
            }
        });

        return [newCells, newBounds];
    }

    /**
     *
     * Возвращает все ячейки сложных символов.
     *
     * @param cell
     *
     */
    static getSymbolCells(cells: MxCell[]): MxCell[] {
        // ячейки помещаются в объект для того, чтобы избежать дублирования
        // в случае, когда на вход придет несколько ячеек одного символа
        const cellsWithParts: Record<string, MxCell> = {};

        cells.forEach((cell) => {
            const symbolInstance = ComplexSymbolManager.getComplexSymbolInstance(cell);
            const symbolCells: MxCell[] = symbolInstance ? symbolInstance.getManagedCells() : [cell];

            symbolCells.forEach((symbolCell) => (cellsWithParts[symbolCell.id] = symbolCell));
        });

        return Object.values(cellsWithParts);
    }

    static getSymbolPreviewGeometry(symbol: Symbol, cell?: MxCell): ISymbolPreviewGeometry {
        const geo = {
            labelHeight: symbol.labelHeight || cell?.geometry?.height,
            labelWidth: symbol.labelWidth || cell?.geometry?.width,
            labelXOffset: symbol.labelXOffset,
            labelYOffset: symbol.labelYOffset,
        };

        if (!cell) {
            return geo;
        }

        const symbolInstance = ComplexSymbolManager.getComplexSymbolInstance(cell);

        if (!symbolInstance) {
            return geo;
        }

        return symbolInstance.getSymbolPreviewGeometry(symbol);
    }

    static isConnectableCell(cell: MxCell): boolean {
        return !ComplexSymbolManager.isComplexSymbolCell(cell) || ComplexSymbolManager.isComplexSymbolRootCell(cell);
    }

    static isOverlayCell(cell: MxCell): boolean {
        return !ComplexSymbolManager.isComplexSymbolCell(cell) || ComplexSymbolManager.isComplexSymbolRootCell(cell);
    }

    static isObjectCell(cell: MxCell): boolean {
        return !ComplexSymbolManager.isComplexSymbolCell(cell) || ComplexSymbolManager.isComplexSymbolRootCell(cell);
    }

    static isSelectableCell(cell: MxCell): boolean {
        return !ComplexSymbolManager.isComplexSymbolCell(cell) || ComplexSymbolManager.isComplexSymbolRootCell(cell);
    }

    static isSnapCell(cell: MxCell): boolean {
        return !ComplexSymbolManager.isComplexSymbolLabelCell(cell);
    }

    static getConnectionCell(cell: MxCell): MxCell {
        return ComplexSymbolManager.getComplexSymbolRootCell(cell) || cell;
    }

    static getSelectableCell(cell: MxCell): MxCell {
        return ComplexSymbolManager.getComplexSymbolRootCell(cell) || cell;
    }

    static getCellForEdit(cell: MxCell): MxCell {
        const instance = ComplexSymbolManager.getComplexSymbolInstance(cell);

        if (!instance) {
            return cell;
        }

        return (!!instance?.hasEditableLabel() && ComplexSymbolManager.getComplexSymbolLabelCell(cell)) || cell;
    }

    static getFormatExampleCell(cell: MxCell): MxCell {
        return ComplexSymbolManager.getComplexSymbolLabelCell(cell) || cell;
    }

    constructor(graph: BPMMxGraph, options?: IComplexSymbolManagerOptions) {
        this.graph = graph;
        this.managedCells = [];
        this.staticSymbols = !!options?.staticSymbols;
    }

    public createComplexSymbol(symbol: Symbol, objectDefinition?: ObjectDefinitionImpl, overrideProps: any = {}) {
        const createRootCellValue = () => {
            const { id, width, height, labelWidth, labelHeight, labelXOffset, labelYOffset } = symbol;
            const objectDefinitionId = objectDefinition?.nodeId.id;
            const rootCellValue = {
                type: 'object',
                id: uuid(),
                symbolId: id,
                objectDefinitionId,
                width,
                height,
                labelWidth,
                labelHeight,
                labelXOffset,
                labelYOffset,
                ...overrideProps,
            };

            return rootCellValue;
        };

        const { customProps, ComplexSymbolClass } = findComplexSymbolImplementation(
            symbol.id,
            symbol.symbolTypeId || '',
            symbol.objectType,
        );
        const rootCellValue = createRootCellValue();

        const isNewObjectDefinition = !objectDefinition?.updatedAt;
        const symbolInstance = createComplexSymbol(ComplexSymbolClass, {
            rootCellValue,
            graph: this.graph,
            customProps: {
                ...customProps,
                isNewObjectDefinition,
                symbol,
                staticSymbol: this.staticSymbols,
            },
        });

        const symbolManagedCells = symbolInstance.getManagedCells();
        this.managedCells.push(...symbolManagedCells);

        return symbolInstance;
    }

    public restoreComplexSymbol(element: TRootCellValue, symbols: Symbol[], customProps?: any): MxCell {
        const symbol = symbols?.find((s) => s.id === element.symbolId);
        const symbolTypeId = this.getSymbolTypeIdFromSymbol(symbol) || SymbolTypeId.SIMPLE;

        const { ComplexSymbolClass } = findComplexSymbolImplementation(symbolTypeId);
        const symbolInstance = createComplexSymbol(ComplexSymbolClass, {
            rootCellValue: element,
            graph: this.graph,
            customProps: {
                ...(customProps || {}),
                symbol,
                staticSymbol: this.staticSymbols,
            },
        });

        const rootCell = symbolInstance.getRootCell();
        const symbolManagedCells = symbolInstance.getManagedCells();
        this.managedCells.push(...symbolManagedCells);

        return rootCell;
    }

    public removeManagedCell(cell: MxCell) {
        const symbolInstance = ComplexSymbolManager.getComplexSymbolInstance(cell);

        if (!symbolInstance) {
            return;
        }

        const symbolManagedCells = symbolInstance.getManagedCells() || [];
        this.managedCells = differenceBy(this.managedCells, symbolManagedCells, 'id');
    }

    public loadPopupMenu(menu: MxPopupMenu, cell: MxCell) {
        ComplexSymbolManager.getComplexSymbolInstance(cell)?.loadPopupMenu?.(menu, cell);
    }

    public convertValueToString(cell: MxCell): string {
        try {
            return ComplexSymbolManager.getComplexSymbolInstance(cell)?.convertValueToString(cell) || '';
        } catch (e) {
            return '';
        }
    }

    public prepareDiagramElements(cells: MxCell[]): DiagramElement[] {
        const { root } = this.graph.getModel();
        const result: Record<string, DiagramElement> = {};
        const ids = cells.map(({ id }) => id);

        function grabHandler(cell: MxCell) {
            const symbolInstance = ComplexSymbolManager.getComplexSymbolInstance(cell);

            if (!symbolInstance || !ids.includes(cell.id)) {
                return;
            }

            const objectInstance = symbolInstance.serialize();

            result[objectInstance.id] = objectInstance;
        }

        cellsTreeWalkerDown([root], grabHandler);

        return Object.values(result);
    }

    public refreshCells() {
        if (this.managedCells.length === 0) return;

        this.managedCells.forEach((cell) => ComplexSymbolManager.getComplexSymbolInstance(cell)?.redraw());
    }

    public getSearchValue(cell: MxCell): string {
        const symbolInstance = ComplexSymbolManager.getComplexSymbolInstance(cell);

        if (!symbolInstance) {
            return '';
        }

        const labelCell = ComplexSymbolManager.getComplexSymbolLabelCell(cell);

        if (!labelCell) {
            return '';
        }

        return this.graph.getView().getState(labelCell)?.text?.value;
    }

    public moveCellsLayer(moveLayer: MoveLayer): void {
        const selectedCells: MxCell[] = this.graph.getSelectionCells();

        const mainCells: MxCell[] = uniqBy(
            selectedCells.map((cell) => ComplexSymbolManager.getComplexSymbolRootCell(cell) || cell),
            'id',
        );
        const labelCells: MxCell[] = mainCells
            .map((cell) => ComplexSymbolManager.getComplexSymbolLabelCell(cell))
            .filter(Boolean) as MxCell[];

        switch (moveLayer) {
            case MoveLayer.Top:
                this.graph.orderCells(false, mainCells);
                this.graph.orderCells(false, labelCells);
                break;
            case MoveLayer.Down:
                changeCellsLayerIndex(mainCells, this.graph, false);
                break;
            case MoveLayer.Up:
                changeCellsLayerIndex(mainCells, this.graph, true);
                break;
            case MoveLayer.Bottom:
                this.graph.orderCells(true, labelCells);
                this.graph.orderCells(true, mainCells);
                break;
            default:
        }
    }

    public redrawCellBoxes(cells: MxCell[], prevCells: MxCell[]): MxCell[] {
        const selectedCellsWithLabel: MxCell[] = cells.filter(
            (cell) => !ComplexSymbolManager.isComplexSymbolLabelCell(cell),
        );
        const deletedSelections: MxCell[] = prevCells.filter(
            (cell) => !selectedCellsWithLabel.some(({ id }) => cell.id === id),
        );
        const addedSelections: MxCell[] = selectedCellsWithLabel.filter(
            (cell) => !prevCells.some(({ id }) => cell.id === id),
        );

        deletedSelections.forEach((cell) => {
            const labelCell: MxCell | undefined = ComplexSymbolManager.getComplexSymbolLabelCell(cell);
            const graphCell: MxCell | undefined = labelCell && this.graph.getModel().getCell(labelCell.id);

            if (graphCell) {
                hideCellBox(this.graph, graphCell);
            }
        });

        const updatedCells = prevCells.filter((cell) => !deletedSelections.some(({ id }) => cell.id === id));

        addedSelections.forEach((cell) => {
            const labelCell: MxCell | undefined = ComplexSymbolManager.getComplexSymbolLabelCell(cell);
            const graphCell: MxCell | undefined = labelCell && this.graph.getModel().getCell(labelCell.id);

            if (graphCell) {
                showCellBox(this.graph, graphCell);
            }

            if (!updatedCells.some(({ id }) => cell.id === id)) {
                updatedCells.push(cell);
            }
        });

        return updatedCells;
    }

    public formatSelectedCellByExample(): void {
        this.graph.bpmMxGraphContext.applyCellStyle = false;
        const selectedCell: MxCell | undefined = this.graph.getSelectionCells()[0];
        const selectedLabel: MxCell | undefined = ComplexSymbolManager.getFormatExampleCell(selectedCell);
        const formatExampleLabel: MxCell | undefined = this.graph.bpmMxGraphContext.selectedCell;
        const formatExampleCell: MxCell | undefined =
            (formatExampleLabel && ComplexSymbolManager.getComplexSymbolRootCell(formatExampleLabel)) ||
            formatExampleLabel;

        this.graph.getModel().beginUpdate();

        copySizesToCell(selectedCell, formatExampleCell, this.graph);
        copyStylesToCell(selectedCell, formatExampleCell);

        copyPositionToLabel(selectedCell, selectedLabel, formatExampleCell, formatExampleLabel, this.graph);
        copySizesToCell(selectedLabel, formatExampleLabel, this.graph);
        copyStylesToCell(selectedLabel, formatExampleLabel);

        this.graph.removeSelectionCell(selectedCell);
        this.graph.bpmMxGraphContext.selectedCell = undefined;
        this.graph.getModel().endUpdate();
        this.graph.refresh();
    }

    public setObjectToCell(cell: MxCell, object: ObjectDefinitionImpl): void {
        const symbolInstance = ComplexSymbolManager.getComplexSymbolInstance(cell);

        if (!symbolInstance) {
            return;
        }

        symbolInstance.setObjectToCell(cell, object);
    }

    private getSymbolTypeIdFromSymbol(symbol?: Symbol): SymbolTypeId | undefined {
        if (symbol?.symbolTypeId) {
            return symbol.symbolTypeId as SymbolTypeId;
        }

        return symbol?.objectType as SymbolTypeId;
    }
}
