import { call, put, select, takeEvery } from 'redux-saga/effects';
import { FileFolderType, TreeItemContextMenuAction, TreeItemType } from '../modules/Tree/models/tree';
import { openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../actionsTypes/tree.actionTypes';
import { FILE_DOWNLOAD } from '../actionsTypes/uploader.actionTypes';
import { TServerEntity } from '../models/entities.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { Node } from '../serverapi/api';
import { TreeSelectors } from '../selectors/tree.selectors';
import { v4 as uuid } from 'uuid';
import { TTreeItemContextMenuAction } from '../actions/tree.actions.types';
import { nodeService } from '../services/NodeService';
import { treeItemAdd } from '../actions/tree.actions';
import { TreeNode } from '../models/tree.types';
import { fileDownload } from '../actions/file.actions';
import { TabsBusActions } from '../actionsTypes/tabsBus.actionTypes';
import { BrowserDaoService } from '../services/dao/BrowserDaoService';
import { LocalStorageDaoService } from '../services/dao/LocalStorageDaoService';

const ALLOWABLE_FILE_PARENT = [TreeItemType.FileFolder, TreeItemType.Repository, TreeItemType.Folder];

function* handleTreeItemsFileAdd({ payload: { nodeId, type, action } }: TTreeItemContextMenuAction) {
    if (ALLOWABLE_FILE_PARENT.includes(type)) {
        if (action === TreeItemContextMenuAction.ADD_FILE) {
            yield put(
                openDialog(DialogType.FILE_UPLOAD_DIALOG, { nodeId: { ...nodeId, id: uuid() }, parentNodeId: nodeId }),
            );
        }
    }
    if (type === TreeItemType.FileFolder) {
        if (action === TreeItemContextMenuAction.ADD_FILE_FOLDER) {
            yield put(
                openDialog(DialogType.FOLDER_DIALOG, {
                    type,
                    parentNodeId: nodeId,
                    extraProperties: { fileFolderType: FileFolderType.NONE },
                }),
            );
        }
    }
    if (type === TreeItemType.File) {
        if (action === TreeItemContextMenuAction.REPLACE) {
            // загрузка через контекстное меню дерева так что можно брать ноду из стора
            const file: Node = yield select(TreeSelectors.itemById(nodeId));
            yield put(openDialog(DialogType.FILE_UPLOAD_DIALOG, { parentNodeId: file.parentNodeId, nodeId }));
        }
        if (action === TreeItemContextMenuAction.DOWNLOAD_FILE) {
            yield put(fileDownload(nodeId));
        }
    }
}

function* handleFileDownload({ payload: { nodeId } }: TTreeItemContextMenuAction) {
    let fileNode: Node | undefined = yield select(TreeSelectors.itemById(nodeId));

    try {
        if (!fileNode) {
            fileNode = yield nodeService().loadNodeFromServer(nodeId);
            yield put(treeItemAdd({ ...fileNode } as TreeNode));
        }
        if (!fileNode) {
            LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);

            return;
        }
        const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));
        const response = yield call(() =>
            server.api.file.downloadFile({
                fileId: nodeId.id,
                repositoryId: nodeId.repositoryId,
            }),
        );
        const file = yield call(() => response.blob());
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);
        BrowserDaoService.downloadFile(file, { defaultPath: fileNode.name || nodeId.id });
    } catch (e) {
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);
        throw e;
    }
}

export function* fileSaga() {
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleTreeItemsFileAdd);
    yield takeEvery(FILE_DOWNLOAD, handleFileDownload);
}
