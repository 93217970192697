import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import formMessages from '../../../../models/formDefault.messages';
import messages from '../../messages/groups.messages';
import { Button } from 'antd';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { TRemoveUsersFromGroupPayload } from '../../../../actions/groups.actions.types';
import { DEFAULT_DIALOG_WIDTH } from '../../../../config/config';
import { TRemoveUserFromGroupDialogActionProps } from '../RemoveUserFromGroupDialog.types';

type TRemoveUserFromGroupDialogProps = {
    visible: boolean;
    removePayload: TRemoveUsersFromGroupPayload;
};

type TRemoveUserFromGroupDialogFullProps = TRemoveUserFromGroupDialogProps &
    TRemoveUserFromGroupDialogActionProps &
    WrappedComponentProps;

const RemoveUserFromGroupDialog = (props: TRemoveUserFromGroupDialogFullProps) => {
    const { onCancel, intl, visible, onConfirm, removePayload } = props;
    const handleRemoveUserSubmit = () => removePayload.usersIds && onConfirm(removePayload);
    const footer = [
        <Button key="cancel" size="large" onClick={onCancel}>
            {intl.formatMessage(formMessages.cancelButton)}
        </Button>,
        <Button
            danger
            data-test="user-managment-group_delete-user-from-group_submite"
            key="ok"
            size="large"
            type="primary"
            onClick={handleRemoveUserSubmit}
        >
            {intl.formatMessage(formMessages.deleteButtonLabel)}
        </Button>,
    ];

    return (
        <Dialog
            onCancel={onCancel}
            title={intl.formatMessage(messages.deleteUsersFromGroupTitle)}
            open={visible}
            width={DEFAULT_DIALOG_WIDTH}
            footer={footer}
            onOk={handleRemoveUserSubmit}
        >
            {`${intl.formatMessage(messages.deleteUsersFromGroup)}?`}
        </Dialog>
    );
};

export default injectIntl(RemoveUserFromGroupDialog);
