import { defineMessages } from 'react-intl';

export default defineMessages({
    currentPermTab: {
        id: 'InstancePermissionsDialog.currentPermTab',
        defaultMessage: 'Текущие права',
    },
    resultPermTab: {
        id: 'InstancePermissionsDialog.resultPermTab',
        defaultMessage: 'Результирующие права',
    },
    additionalPermTab: {
        id: 'InstancePermissionsDialog.additionalPermTab',
        defaultMessage: 'Дополнительные права',
    },
    repositoryTitle: {
        id: 'InstancePermissionsDialog.repositoryTitle',
        defaultMessage: 'Права доступа к базе данных:',
    },
    modelTitle: {
        id: 'InstancePermissionsDialog.modelTitle',
        defaultMessage: 'Права доступа к модели:',
    },
    objectTitle: {
        id: 'InstancePermissionsDialog.objectTitle',
        defaultMessage: 'Права доступа к объекту:',
    },
    folderTitle: {
        id: 'InstancePermissionsDialog.folderTitle',
        defaultMessage: 'Права доступа к папке:',
    },
    scriptTitle: {
        id: 'InstancePermissionsDialog.scriptTitle',
        defaultMessage: 'Права доступа к скрипту:',
    },
    scriptFolderTitle: {
        id: 'InstancePermissionsDialog.scriptFolderTitle',
        defaultMessage: 'Права доступа к папке со скриптами:',
    },
    wikiTitle: {
        id: 'InstancePermissionsDialog.wikiTitle',
        defaultMessage: 'Права доступа к Wiki:',
    },
    matrixTitle: {
        id: 'InstancePermissionsDialog.matrixTitle',
        defaultMessage: 'Права доступа к матричной модели:',
    },
    simulationModelTitle: {
        id: 'InstancePermissionsDialog.simulationModelTitle',
        defaultMessage: 'Права доступа к имитационной модели:',
    },
    fileTitle: {
        id: 'InstancePermissionsDialog.fileTitle',
        defaultMessage: 'Права доступа к файлу:',
    },
    fileFolderTitle: {
        id: 'InstancePermissionsDialog.fileFolderTitle',
        defaultMessage: 'Права доступа к папке с файлами:',
    },
    spreadsheetTitle: {
        id: 'InstancePermissionsDialog.spreadsheetTitle',
        defaultMessage: 'Права доступа к таблице:',
    },
    searchPlaceholder: {
        id: 'InstancePermissionsDialog.searchPlaceholder',
        defaultMessage: 'Поиск по имени, названию группы',
    },
    addButton: {
        id: 'InstancePermissionsDialog.addButton',
        defaultMessage: 'Добавить',
    },
    deleteButton: {
        id: 'InstancePermissionsDialog.deleteButton',
        defaultMessage: 'Удалить',
    },
    cancelButton: {
        id: 'InstancePermissionsDialog.cancelButton',
        defaultMessage: 'Отмена',
    },
    saveButton: {
        id: 'InstancePermissionsDialog.saveButton',
        defaultMessage: 'Сохранить',
    },
    fetchError: {
        id: 'InstancePermissionsDialog.fetchError',
        defaultMessage: 'Ошибка загрузки',
    },
    selectFilterAllUsers: {
        id: 'InstancePermissionsDialog.selectFilterAllUsers',
        defaultMessage: 'Все пользователи',
    },
    selectFilterUsersWithPermissions: {
        id: 'InstancePermissionsDialog.selectFilterUsersWithPermissions',
        defaultMessage: 'Только пользователи с правами',
    },
    selectFilterUsersWithoutPermissions: {
        id: 'InstancePermissionsDialog.selectFilterUsersWithoutPermissions',
        defaultMessage: 'Только пользователи без прав',
    },
});
