import electron from '../electron';

export function urlClick(uri: string) {
    if (electron === null) {
        const link = document.createElement('a');
        link.download = '';
        link.href = uri;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        const { shell } = electron;
        shell.openExternal(uri);
    }
}

export const replaceLastSlash = (url: string): string => (url ? url.replace(/\/$/, '') : url);

export const getOrigin = (): string => {
    const hostname: string = window.location.origin;
    if (hostname.includes('file:')) {
        return 'http://localhost:8080';
    }

    return hostname;
};

export const openLink = (url: string) => {
    const fullURL = `${window.location.origin}${url}`;
    window.open(fullURL, '_blank');
};