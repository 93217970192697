import { connect } from 'react-redux';
import { ServerEditDialog } from '../components/ServerEditDialog.component';
import { TRootState } from '../../../reducers/root.reducer.types';
import { ServerSelectors } from '../../../selectors/entities/server.selectors';
import { deleteServer, editServer } from '../../../actions/entities/servers.actions';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { v4 as uuid } from 'uuid';
import { TServerConnectInfo, TServerEditDialogProps, TServerForm } from '../ServerForm.types';

type TServerEditDialogContainerProps = TServerEditDialogProps & {
    entityId: string;
};

const mapStateToProps = (
    state: TRootState,
    props: TServerEditDialogContainerProps,
): Partial<TServerEditDialogProps> => {
    const server = ServerSelectors.serverList(state).find((s) => s.id === props.entityId);
    const allServers = ServerSelectors.serverList(state);
    const isConnected = !!ServerSelectors.connected(state).find((id) => id === props.entityId);
    const isEditMode = !!props.entityId;
    const value: TServerConnectInfo = !server
        ? {
            id: uuid(),
        }
        : {
            id: server.id || uuid(),
            url: server.url,
            name: server.name,
        };

    return { error: props.error, visible: props.visible, value, isEditMode, isConnected, allServers };
};

const mapDispatchToProps = (dispatch): Partial<TServerEditDialogProps> => ({
    onSubmit: (data: TServerForm, isConnected: boolean) => {
        if (!isConnected) {
            dispatch(editServer(data));
        }
    },

    onCancel: () => dispatch(closeDialog(DialogType.SERVER_EDIT)),

    onDelete: (id: string, name: string, isConnected: boolean) => {
        if (!isConnected) {
            dispatch(deleteServer(id));
            dispatch(closeDialog(DialogType.SERVER_EDIT));
        }
    },
});

export const ServerEditDialogContainer = connect(mapStateToProps, mapDispatchToProps)(ServerEditDialog);
