import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { MxCell } from '../../../../mxgraph';
import { CommentToolTip } from '../CommentToolTip/CommentToolTip.component';
import { CommentsSelectors } from '../../../../selectors/comments.selectors';
import { NodeId, Comment } from '../../../../serverapi/api';
import theme from './CommentTooltipContainer.scss';
import { useDispatch, useSelector } from 'react-redux';
import { NewCommentArea } from '../NewCommentArea/NewCommentArea.component';
import { deleteEditingComment } from '../../../../actions/comments.actions';
import { getActiveGraph } from '../../../../selectors/editor.selectors';
import { compareNodeIds } from '../../../../utils/nodeId.utils';
import { BPMMxGraph } from '../../../../mxgraph/bpmgraph';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';

type TFullCommentToolTip = {
    cell: MxCell;
    graph: BPMMxGraph;
    setFullToolTipMode: (isFullToolTipMode: boolean) => void;
    onClose: () => void;
    resize: () => void;
    style: CSSStyleDeclaration;
    div: HTMLDivElement;
};

export const CommentToolTipContainer = forwardRef((props: TFullCommentToolTip, ref) => {
    const { cell, onClose, graph, setFullToolTipMode, resize, style, div} = props;

    const dispatch = useDispatch();
    const [isFullFormat, setIsFullFormat] = useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const modelId: NodeId = { ...cell.value.comment.commentId, id: cell.value.comment.nodeId } as NodeId;
    const commentId: string = cell.id;
    const maxTooltipHeight = graph.container?.clientHeight * 0.8 || 'auto';

    const comment: Comment | undefined = useSelector(CommentsSelectors.getCommentById(modelId, commentId));
    const childrenComments: Comment[] = useSelector(CommentsSelectors.getCommentsByParentId(modelId, commentId));
    const isEditingComment: boolean = !!useSelector(CommentsSelectors.getEditingComment(modelId));
    const activeGraphId: NodeId | undefined = useSelector(getActiveGraph);
    const tooltip: boolean | undefined = useSelector(CommentsSelectors.getTooltip(modelId));
    const tooltipStyles: {
        left?: string | undefined;
        top?: string | undefined;
    } | undefined  = useSelector(CommentsSelectors.getTooltipStyles(modelId));
    const isEditedComment: boolean = useSelector(CommentsSelectors.isEditedComment(modelId, commentId));

    useEffect(() => {
        resize();
    });

    const hideTooltip = () => {
        dispatch(deleteEditingComment(modelId));
        setIsEditMode(false);
        onClose();
    };

    useImperativeHandle(ref, () => ({
        hideTooltip: () => {
            if (isEditingComment && tooltip) {
                if (isEditedComment) {
                    dispatch(openDialog(DialogType.DELETE_EDITING_COMMENT_DIALOG, { onCancel:  () => {
                        hideTooltip();
                    }}));
                } else {
                    hideTooltip();
                }
            } 
        },
    }), [isEditingComment, isEditMode, tooltip, isEditedComment]);

    useEffect(() => {
        if (
            (isFullFormat && isEditingComment) ||
            (isFullFormat && !isEditingComment && tooltip) ||
            (isFullFormat && !isEditingComment && !isEditedComment) ||
            (tooltip && !isEditingComment && !isEditedComment)
        ) {
            div.style.visibility = 'hidden';
            div.style.display = 'none';
        }

        if (compareNodeIds(activeGraphId, modelId) && tooltip) {
            div.style.visibility = 'visible';
            div.style.display = 'block';
            div.style.left = tooltipStyles?.left || '0';
            div.style.top = tooltipStyles?.top || '0';
        } 
    }, [activeGraphId]);

    const handleCkick = () => {

        setFullToolTipMode(true);
        setIsFullFormat(true);
    };
    const onChangeEditMode = (newStatus: boolean) => {
        setIsEditMode(newStatus);
    };

    return (
        <div onClick={handleCkick}>
            {isFullFormat && (
                <div
                    className={theme.fullTooltip}
                    style={{ maxHeight: `${maxTooltipHeight}px` }}
                >
                    {isEditMode ? (
                        <NewCommentArea
                            onlyFullSizeMode
                            modelId={modelId}
                            comment={comment}
                            onFinishEditing={() => onChangeEditMode(false)}
                            onChangeVisible={() => onChangeEditMode(false)}
                            showInTooltip
                            popupStyles={style}
                        />
                    ) : (
                        <CommentToolTip
                            onChangeEditMode={onChangeEditMode}
                            isHeadComment
                            modelId={modelId}
                            commentId={commentId}
                            onClose={onClose}
                            tooltip={tooltip}
                        />
                    )}
                    {!!childrenComments.length &&
                        childrenComments.map((childComment) => {
                            return (
                                <CommentToolTip
                                    modelId={modelId}
                                    commentId={childComment.commentId.id}
                                    key={childComment.commentId.id}
                                />
                            );
                        })}
                    <NewCommentArea modelId={modelId} comment={comment} isAnswer showInTooltip popupStyles={style} />
                </div>
            )}
            {!isFullFormat && <CommentToolTip modelId={modelId} commentId={commentId} isSmallFormat />}
        </div>
    );
});
