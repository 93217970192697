import {
    MxCell,
    MxCellEditor,
    MxCellState,
    MxEvent,
    MxEventObject,
    MxGeometry,
    MxGraph,
    MxGraphView,
    MxMouseEvent,
    MxPoint,
    MxPopupMenuHandler,
    MxRectangle,
    MxTooltipHandler,
    MxUtils,
} from './mxgraph';
import { ApiBundle } from '../services/api/api-bundle';
import { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { MenuInfo } from 'rc-menu/lib/interface';
import { BPMMxGraph } from './bpmgraph';
import { isNullOrUndefined } from 'is-what';
import { noop } from 'lodash-es';
import Portal from '../modules/UIKit/components/Portal/Portal.component';
import { BPMPopupMenu } from './components/BPMPopupMenu.component';
import { TContextTreeData } from './components/BPMPopupMenu.types';
import { CommentToolTipContainer } from '../modules/Comments/components/FullCommentToolTip.component/CommentToolTipContainer.component';
import { getStore } from '../store';
import { Provider } from 'react-redux';
import { createRef } from 'react';
import { getTooltipContainerCoordinates } from '../modules/Comments/utils/commentsUtils';
import { isCommentCell } from '@/utils/bpm.mxgraph.utils';
import { ComplexSymbolManager } from './ComplexSymbols/ComplexSymbolManager.class';

type TTooltipRef = {
    hideTooltip: () => void;
};

export class BPMMxCellEditor extends MxCellEditor {
    isContentEditing() {
        const state = this.graph.view.getState(this.editingCell);

        return state != null /* && state.style['html'] == 1 */;
    }
}

export class BPMMxGraphView extends MxGraphView {
    graph: BPMMxGraph;
    commentCells: Set<MxCell>;

    constructor(grpah) {
        super(grpah);
        this.commentCells = new Set<MxCell>();
    }

    getPoint(state: MxCellState, geometry: MxGeometry) {
        const gx = geometry != null ? geometry.x / 2 : 0;
        const dist = Math.round((gx + 0.5) * state.length);
        if (dist < 0) {
            const offset = geometry.offset || { x: 0, y: 0 };
            const x = state.getCenterX() + offset.x;
            const y = state.getCenterY() + offset.y;

            return new MxPoint(x, y);
        }

        return super.getPoint(state, geometry);
    }

    getBackgroundPageBounds(): MxRectangle {
        const layout = this.graph.getPageLayout();
        const page = this.graph.getPageSize();

        return new MxRectangle(
            this.scale * (this.translate.x + layout.x * page.width),
            this.scale * (this.translate.y + layout.y * page.height),
            this.scale * layout.width * page.width,
            this.scale * layout.height * page.height,
        );
    }

    validate(cell?: MxCell): void {
        if (this.graph.container != null && MxUtils.hasScrollbars(this.graph.container)) {
            const pad = this.graph.getPagePadding();
            const size = this.graph.getPageSize();

            // Updating scrollbars here causes flickering in quirks and is not needed
            // if zoom method is always used to set the current scale on the graph.
            // var tx = this.translate.x;
            // var ty = this.translate.y;
            this.translate.x = pad.x / this.scale - (this.x0 || 0) * size.width;
            this.translate.y = pad.y / this.scale - (this.y0 || 0) * size.height;
        }

        super.validate(cell);

        this.commentCells.forEach((commentCell) => {
            this.validateCellState(commentCell, false);
        });
        this.commentCells.clear();
    }
}

export class BPMMxPopupMenuHandler extends MxPopupMenuHandler {
    visible: boolean;
    dropdown: JSX.Element;
    items: TContextTreeData[];
    handlers: { [key: string]: Function };
    containsItems: boolean;
    menuWidth: number;
    root: any;
    pageY: number;
    graph: BPMMxGraph;

    constructor(graph: MxGraph, factoryMethod: Function) {
        // tslint:disable-line:no-any

        super(graph, factoryMethod);

        this.visible = false;
        this.div.removeAttribute('class');
        this.div.style.position = 'absolute';
        this.div.style.width = '0px';
        this.div.style.height = '0px';
        this.div.style.overflow = 'hidden';
        this.div.innerHTML = '';

        this.changeVisibility = this.changeVisibility.bind(this);

        this.items = [];
        this.menuWidth = 200;
        this.handlers = {};
        this.onClick = this.onClick.bind(this);
    }

    getCellForPopupEvent(me: MxMouseEvent): MxCell | null {
        let result: MxCell | null = super.getCellForPopupEvent(me);

        if (!isNullOrUndefined(result)) {
            result = ComplexSymbolManager.getComplexSymbolRootCell(result) || result;
            const cell = this.graph.getCellAt(me.graphX, me.graphY, result);

            if (isCommentCell(cell)) {
                result = cell;
            }
        }

        return result;
    }

    mouseUp(sender: BPMMxGraph, me: MxMouseEvent) {
        if (this.popupTrigger && this.inTolerance && this.triggerX != null && this.triggerY != null) {
            const cell: MxCell | null = this.getCellForPopupEvent(me);
            this.pageY = me.evt.pageY;

            if (!isNullOrUndefined(cell)) {
                const symbolCells = ComplexSymbolManager.getSymbolCells([cell]);
                const additionalCell: MxCell =
                    symbolCells.length > 1 ? (cell.id === symbolCells[0].id ? symbolCells[1] : symbolCells[0]) : cell;

                // Selects the cell for which the context menu is being displayed
                if (
                    this.graph.isEnabled() &&
                    this.isSelectOnPopup(me) &&
                    cell != null &&
                    !this.graph.isCellSelected(cell) &&
                    !this.graph.isCellSelected(additionalCell)
                ) {
                    this.graph.setSelectionCell(cell);
                }
            } else if (this.clearSelectionOnBackground && isNullOrUndefined(cell)) {
                this.graph.clearSelection();
            }

            // Hides the tooltip if there is one
            this.graph.tooltipHandler.hide();

            // Menu is shifted by 1 pixel so that the mouse up event
            // is routed via the underlying shape instead of the DIV
            const origin = MxUtils.getScrollOrigin();

            this.popup(me.getX() + origin.x + 1, me.getY() + origin.y + 1, cell, me.getEvent());
            me.consume();
        }

        this.popupTrigger = false;
        this.inTolerance = false;
    }

    addItem(
        title: string,
        image: string,
        funct: Function,
        parent: TContextTreeData,
        iconCls: string,
        enabled: boolean = true,
        addTooltip: () => string,
        dataTest?: string,
        key?: string,
    ) {
        this.itemCount++;
        this.handlers[key || this.itemCount] = () => {
            if (enabled) {
                funct();
            }
        };
        const item = {
            key: key || this.itemCount.toString(10),
            value: this.itemCount.toString(10),
            label: title,
            children: [],
            enabled,
            image,
            dataTest,
            addTooltip,
        } as TContextTreeData;

        if (!parent) {
            this.items.push(item);
        } else {
            parent.children.push(item);
        }

        return item;
    }

    addSeparator() {
        this.itemCount++;
        this.handlers[this.itemCount] = noop;
        const item = {
            key: `${this.itemCount}_separator`,
            value: this.itemCount.toString(10),
            label: '',
            children: [],
            enabled: true,
            isDivider: true,
        } as TContextTreeData;
        this.items.push(item);
    }

    changeVisibility(flag: boolean) {
        this.visible = flag;
    }

    onClick(event: MenuInfo) {
        this.handlers[event.key]();
        super.hideMenu();
        this.hideMenu();
    }

    render() {
        this.root = ReactDOM.createRoot(this.div);

        this.root.render(
            <Portal container={this.div}>
                <BPMPopupMenu
                    visible={this.visible}
                    items={this.items}
                    onClick={this.onClick}
                    width={this.menuWidth}
                    graph={this.graph}
                    triggerX={this.triggerX}
                    pageY={this.pageY}
                />
            </Portal>,
        );
    }

    popup(x: number, y: number, cell: any, evt: any): void {
        this.items = [];
        this.handlers = {};
        super.popup(x, y, cell, evt);
    }

    showMenu() {
        super.showMenu();
        this.visible = true;
        this.render();
    }

    hideMenu() {
        if (this.div != null) {
            this.root?.unmount();

            if (this.visible) {
                this.hideSubmenu(this);
                this.containsItems = false;
                this.fireEvent(new MxEventObject(MxEvent.HIDE));
            }
            this.visible = false;
        }
    }

    isMenuShowing() {
        return this.visible;
    }

    destroy(): void {
        this.root?.unmount();
        super.destroy();
    }
}

export interface BPMMxGraphContext {
    serverId: string;
    api: ApiBundle;
    applyCellStyle?: boolean;
    selectedCell?: MxCell;
    objectDefinitionCopyPasteContext: {
        selectedObjectDefinition?: ObjectDefinitionImpl;
        doInsert?: any;
    };
}

export class BPMMxTooltipHandler extends MxTooltipHandler {
    root: ReactDOM.Root;
    isFullToolTipMode: boolean;
    lastX: number;
    lastY: number;
    state: MxCellState;
    stateSource: boolean;
    graph: BPMMxGraph;

    constructor(graph: BPMMxGraph, delay: number) {
        super(graph, delay);
        this.close = this.close.bind(this);
        this.setFullToolTipMode = this.setFullToolTipMode.bind(this);
        this.resize = this.resize.bind(this);
        this.isFullToolTipMode = false;
    }

    commentToolTipRef = createRef<TTooltipRef>();

    setFullToolTipMode(isFullToolTipMode: boolean) {
        this.isFullToolTipMode = isFullToolTipMode;
    }

    resize() {
        if (!this.graph?.container || !this.div) return;
        const [newTop, newLeft] = getTooltipContainerCoordinates(this.graph.container, this.div);

        this.div.style.top = `${newTop}px`;
        this.div.style.left = `${newLeft}px`;
    }

    close() {
        if (this.div != null) {
            this.div.style.visibility = 'hidden';
            this.div.innerHTML = '';
        }
    }

    hideTooltip() {
        const node = this.div?.querySelector?.('#commentTextAreaContainer');
        if (!node) {
            this.close();
        }
    }

    mouseUp(sender, me: MxMouseEvent) {
        this.commentToolTipRef.current?.hideTooltip();
        this.setFullToolTipMode(false);
        this.reset(me, true);
        this.hideTooltip();
    }

    mouseMove(sender, me: MxMouseEvent) {
        if (me.getX() !== this.lastX || me.getY() !== this.lastY) {
            const ignoreFn = (state: MxCellState) => !isCommentCell(state.cell);
            const cell = this.graph.getCellAt(me.graphX, me.graphY, null, true, false, ignoreFn);
            const state = this.graph.view.getState(cell);
            this.reset(me, true, state);
            if (
                this.isHideOnHover() ||
                state !== this.state ||
                (me.getSource() !== this.node &&
                    (!this.stateSource ||
                        (state != null && this.stateSource === (me.isSource(state.shape) || !me.isSource(state.text)))))
            ) {
                if (!this.isFullToolTipMode) {
                    this.hideTooltip();
                }
            }
        }

        this.lastX = me.getX();
        this.lastY = me.getY();
    }

    init() {
        if (document.body != null) {
            this.div = document.createElement('div');
            this.div.className = 'mxTooltip';
            this.div.style.visibility = 'hidden';

            document.body.appendChild(this.div);

            MxEvent.addGestureListeners(
                this.div,
                MxUtils.bind(this, (evt) => {
                    const source = MxEvent.getSource(evt);

                    if (source.nodeName !== 'A' && !source.closest('.mxTooltip')) {
                        this.hideTooltip();
                    }
                }),
            );
        }
    }

    show(tip: MxCell) {
        if (tip?.value?.type === 'CommentMarker') {
            if (this.div == null) {
                this.init();
            }
            if (!this.div) return;
            const { container } = this.graph;
            const origin = MxUtils.getScrollOrigin(container);
            const tipState = this.graph.view.getState(tip);
            const { x, y } = tipState;
            this.div.style.left = `${container.getBoundingClientRect().x + (x - origin.x) - 14}px`;
            this.div.style.top = `${container.getBoundingClientRect().y + (y - origin.y) - 14}px`;
            this.div.style.background = 'white';
            this.div.style.zIndex = '0';
            this.div.style.visibility = 'visible';
            this.div.style.cursor = 'pointer';
            this.div.style.width = '344px';
            this.div.style.borderRadius = '8px';
            this.div.style.borderWidth = '0px';
            this.div.style.padding = '12px';
            this.div.style.fontFamily = 'Segoe UI';
            this.div.style.display = 'block';
            this.root = ReactDOM.createRoot(this.div);

            // eslint-disable-next-line consistent-return
            return this.root.render(
                <Provider store={getStore()}>
                    <Portal container={this.div}>
                        <CommentToolTipContainer
                            ref={this.commentToolTipRef}
                            cell={tip}
                            onClose={this.close}
                            graph={this.graph as BPMMxGraph}
                            setFullToolTipMode={this.setFullToolTipMode}
                            resize={this.resize}
                            style={this.div.style}
                            div={this.div}
                        />
                    </Portal>
                </Provider>,
            );
        }
    }
}
