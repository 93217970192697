import React from 'react';
import messages from '../../messages/ImportDialog.messages';
import { Alert, Button, Form, Input } from 'antd';
import { injectIntl } from 'react-intl';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { TDialogProps } from '../../../UIKit/components/Dialog/Dialog.types';
import { FormGroup } from '../../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import theme from './ImportRepositoryDialog.scss';
import { TImportRepositoryDialogProps } from '../../types/ImportDialog.types';

type TImportRepositoryDialogState = {
    newName: string;
    isSubmitEnabled: boolean;
};

class ImportRepositoryDialog extends React.Component<TImportRepositoryDialogProps, TImportRepositoryDialogState> {
    state = {
        newName: '',
        isSubmitEnabled: false,
    };

    handleSubmit = () => {
        this.props.onSubmit(this.state.newName);
    };

    handleNameChange = (value: string) => {
        this.setState({
            newName: value,
            isSubmitEnabled: value.length > 0,
        });
    };

    render() {
        const { intl, visible } = this.props;
        const dialogProps: Partial<TDialogProps> = {
            visible,
            title: intl.formatMessage(messages.dialogCloneRepository),
        };

        const footer = [
            <Button data-test="window-data-base-clone_cancel_btn" key="cancel" size="large" onClick={this.props.onCancel} disabled={false}>
                {intl.formatMessage(messages.cancelButton)}
            </Button>,
            <Button data-test="window-data-base-clone_accept_btn" key="apply" size="large" onClick={this.handleSubmit} disabled={!this.state.isSubmitEnabled} type="primary">
                {intl.formatMessage(messages.accept)}
            </Button>,
        ];

        return (
            <Dialog {...dialogProps} footer={footer} closable={false}>
                {/* todo 2979 удалить алерт */}
                {this.props.error && <Alert type="error" message={intl.formatMessage(this.props.error)} showIcon />}
                <Form layout="vertical" hideRequiredMark>
                    <FormGroup>
                        <Form.Item
                            className={theme.itemType}
                            label={intl.formatMessage(messages.dialogNewNameRepository)}
                        >
                            <Input data-test="window-data-base-clone_new-name_input"
                                className={theme.input}
                                type="text"
                                onChange={(event) => this.handleNameChange(event.target.value)}
                            />
                        </Form.Item>
                    </FormGroup>
                    <p />
                </Form>
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(ImportRepositoryDialog);

export { IntlComponent as ImportRepositoryDialog };
