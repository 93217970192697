//@test-ignore_ru

const GridLayoutConfig = {
    cells: [],
    columns: [
        {
            id: 'c1',
            name: { en: 'General process', ru: 'Основной процесс' },
        },
        {
            id: 'c2',
            name: { en: 'Consist of', ru: 'Состоит из' },
        },
        {
            id: 'c3',
            name: { en: 'Consist of', ru: 'Состоит из' },
        },
    ],
    rows: [
        {
            id: 'r1',
            name: { en: 'Script', ru: 'Сценарий' },
        },
        {
            id: 'r2',
            name: { en: 'Belongs to', ru: 'Принадлежит к' },
        },
        {
            id: 'r3',
            name: { en: 'Belongs to', ru: 'Принадлежит к' },
        },
    ],
};

export default GridLayoutConfig;

