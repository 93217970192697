import { TRootState } from '../../../reducers/root.reducer.types';
import { connect } from 'react-redux';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { EditorMode } from '../../../models/editorMode';
import { IWorkspaceTabItemModelParams } from '../../../models/tab.types';
import { MatrixEditorToolbar } from '../components/MatrixEditorToolbar/MatrixEditorToolbar.component';
import { TMatrixEditorToolbarProps } from '../components/MatrixEditorToolbar/MatrixEditorToolbar.types';
import { openDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { matrixEditorModeChangedAction } from '../../../actions/entities/matrix.actions';
import { LocalesService } from '../../../services/LocalesService';

const mapStateToProps = (state: TRootState): Partial<TMatrixEditorToolbarProps> => {
    const activeScheme = TabsSelectors.getActiveTab(state);

    return {
        activeWorkspaceTab: activeScheme,
        compact: state.generalMenu.isCompact,
        isActiveScheme: !!TabsSelectors.getActiveTabId(state), // convert number to boolean
        locale: getCurrentLocale(state),
        locales: LocalesService.getLocaleList(),
        params: activeScheme
            ? (activeScheme.params as IWorkspaceTabItemModelParams)
            : ({} as IWorkspaceTabItemModelParams),
        editorMode: activeScheme ? activeScheme.mode : undefined,
    };
};

type TMatrixEditorContainerOwnProps = {
    id: string;
};

const mapDispatchToProps = (
    dispatch,
    ownProps: TMatrixEditorContainerOwnProps,
): Partial<TMatrixEditorToolbarProps> => ({
    onModeChange: (mode: EditorMode) => {
        dispatch(matrixEditorModeChangedAction(mode));
    },
    openSettings: () => {
        dispatch(openDialog(DialogType.MATRIX_SETTINGS_DIALOG));
    },
});

export const MatrixEditorToolbarContainer = connect(mapStateToProps, mapDispatchToProps)(MatrixEditorToolbar);
