import { fonts } from '../../../../../../utils/configuration';
import { find } from 'lodash-es';

const addFontFamilyStyle = (fontFamilyName: string) => ({
    [`fontfamily-${fontFamilyName}`]: {
        open: () => `<FONTFAMILY_${fontFamilyName}>`,
        close: () => '</FONTFAMILY>',
    },
});

const fontsFamilyStyles = {
    fontFamily: addFontFamilyStyle,
    ...fonts.reduce((result, font) => ({ ...result, ...addFontFamilyStyle(font.value) }), {}),
};

// @TODO: Output in draftjs-utils - need fix it
const addFontFamilyBlockStyle = (fontFamilyName: string) => ({
    [`fontfamily-${fontFamilyName}`]: {
        open: () => {
            const font = find(fonts, { value: fontFamilyName });

            return `<span style="font-family: ${font ? font.style.fontFamily : ''}">`;
        },

        close: () => '</span>',
    },
});

const fontFamilyBlockStyles = {
    fontFamily: addFontFamilyBlockStyle,

    // @TODO: draft-js utils cannot generate or add when not find font family
    ...fonts.reduce((result, font) => ({ ...result, ...addFontFamilyBlockStyle(font.value) }), {}),
};

export { fontFamilyBlockStyles, fontsFamilyStyles };
