import React from 'react';
import { useIntl } from 'react-intl';
import messages from './DeleteConfirmationDialog.messages';
import theme from './DeleteConfirmationDialog.scss';
import { Dialog } from '../../../../../../UIKit/components/Dialog/Dialog.component';
import { closeDialog } from '../../../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../../../DialogRoot/DialogRoot.constants';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

type TDeleteConfirmationDialogProps = {
    visible: boolean;
    onDelete: () => void;
    deleteQuestion?: string;
    dialogContent?: React.ReactNode;
};

export const DeleteConfirmationDialog = (props: TDeleteConfirmationDialogProps) => {
    const { onDelete, deleteQuestion, dialogContent, visible } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const onCancel = () => dispatch(closeDialog(DialogType.DELETE_CONFIRMATION));

    const onOk = () => {
        onDelete();
        dispatch(closeDialog(DialogType.DELETE_CONFIRMATION));
    };

    const footer = [
        <Button className={theme.cancelButton} key="cancel" size="large" onClick={onCancel} data-test="type-group-name-button-cancel">
            {intl.formatMessage(messages.cancel)}
        </Button>,
        <Button className={theme.okButton} key="ok" size="large" type="primary" onClick={onOk} data-test="type-group-name-button-ok">
            {intl.formatMessage(messages.delete)}
        </Button>,
    ];

    return (
        <Dialog
            title={<span className={theme.title}>{deleteQuestion}</span> || intl.formatMessage(messages.deleteTitle)}
            className={classnames(theme.dialog, !dialogContent ? theme.emptyDialogContent : '')}
            onCancel={onCancel}
            onOk={onOk}
            open={visible}
            footer={footer}
        >
            {dialogContent}
        </Dialog>
    );
};
