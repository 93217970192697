import { BPMMxGraph } from '@/mxgraph/bpmgraph';
import { GridCell, GridHeader, GridLayout, InternationalString } from '@/serverapi/api';
import { LocalesService } from '@/services/LocalesService';
import { MxCell, MxConstants, MxPoint, MxPopupMenu, MxUtils } from 'MxGraph';
import { v4 as uuid } from 'uuid';
import messages from './GridDiagram.messages';
import { cellsTreeWalkerDown } from '@/mxgraph/ComplexSymbols/utils';
import { BPMMxConstants } from '@/mxgraph/bpmgraph.constants';
import GridLayoutConfig from './GridLayout.config';

const DEFAULT_GRID_LAYOUT: GridLayout = GridLayoutConfig;

interface IGridDiagram {
    serialize: () => GridLayout;
    loadPopupMenu: (menu: MxPopupMenu, cell: MxCell) => void;
}

export class GridDiagram implements IGridDiagram {
    static titleHeight: number = 30;
    static headerWidth: number = 20;
    static headerHeight: number = 20;
    static tableWidth: number = 200;
    static tableHeight: number = 200;
    private rootContainer: MxCell;
    private graph: BPMMxGraph;

    public serialize(): GridLayout {
        const result: GridLayout = {
            cells: [],
            rows: [],
            columns: [],
        };

        const getIntenationalString = (text: string) => {
            const locale = LocalesService.getLocale();

            return { [locale]: text };
        };

        const serializeCell = (cell: MxCell, r: number, c: number) => {
            if (r == 0 && c == 0) {
                return;
            }

            if (r === 0) {
                result.columns.push({
                    id: cell.getId(),
                    name: getIntenationalString(cell.getValue()),
                });

                return;
            }

            if (c === 0) {
                result.rows.push({
                    id: cell.getId(),
                    name: getIntenationalString(cell.getValue()),
                });

                return;
            }

            const { id: rowId } = result.rows[r - 1];
            const { id: columnId } = result.columns[c - 1];

            result.cells.push({
                id: cell.getId() || uuid(),
                rowId,
                columnId,
            });
        };

        this.rootContainer.children.forEach((row, r) => {
            row.children.forEach((cell, c) => {
                serializeCell(cell, r, c);
            });
        });

        return result;
    }

    constructor(graph: BPMMxGraph, gridLayout: GridLayout = DEFAULT_GRID_LAYOUT, name = '') {
        this.graph = graph;
        this.initStyleForDiagram();
        this.rootContainer = this.render(gridLayout, name);
    }

    private render(gridLayout: GridLayout, name: string) {
        const parent = this.graph.getDefaultParent();
        const { columns, rows, cells } = gridLayout;
        const intl = LocalesService.useIntl();
        const table = this.graph.createTable(
            rows.length + 1,
            columns.length + 1,
            GridDiagram.tableWidth,
            GridDiagram.tableHeight,
            name || intl.formatMessage(messages.defaultModelName),
            GridDiagram.titleHeight,
            null,
            null,
            null,
        );

        this.setTableHeadersSize(table);
        this.setTableContent(table, cells);

        this.tableBinding(table, gridLayout);
        const [root] = this.graph.importCellsWithIds([table], new MxPoint(0, 0), parent);

        return root[0];
    }

    private setTableHeadersSize(table: MxCell) {
        const cell = table.children[0].children[0];
        this.graph.setTableColumnWidth(cell, -GridDiagram.tableWidth + GridDiagram.headerWidth, true);
        this.graph.setTableRowHeight(cell, -GridDiagram.tableHeight + GridDiagram.headerHeight, true);
    }

    private getInternetionalText(internationalString: InternationalString | undefined) {
        if (!internationalString) {
            return '';
        }

        const locale = LocalesService.getLocale();
        return internationalString[locale];
    }

    private setTableContent(table: MxCell, cells: GridCell[]) {
        for (let i = 1; i < table.children.length; i++) {
            const row = table.children[i];
            for (let j = 1; j < row.children.length; j++) {
                const cell = row.children[j] || [];

                cell.setStyle(cell.getStyle() + ';swimlane;startSize=0;');
            }
        }
    }

    private tableBinding(root: MxCell, gridLayout: GridLayout) {
        const { columns, rows, cells } = gridLayout;

        const setHeadProperty = (headCell: MxCell, gridHeader: GridHeader) => {
            const { id, name } = gridHeader;
            const text = this.getInternetionalText(name);

            headCell.setId(id || uuid());

            headCell.setValue(text);
        };

        const setContentProperty = (contentCell: MxCell, r: number, c: number) => {
            const { id: columnId } = columns[c];
            const { id: rowId } = rows[r];

            const gridCell = cells.find((gridCell) => {
                return gridCell.columnId == columnId && gridCell.rowId === rowId;
            }) || { id: uuid() };

            const { id } = gridCell;

            contentCell.setId(id);
        };

        const bindCell = (cell: MxCell, r: number, c: number) => {
            if (r == 0 && c == 0) {
                return;
            }

            if (r == 0) {
                setHeadProperty(cell, columns[c - 1]);
                const style = `${cell.getStyle()};headHorizontal`;
                cell.setStyle(style);

                return;
            }

            if (c == 0) {
                setHeadProperty(cell, rows[r - 1]);
                const style = `${cell.getStyle()};headVertical`;
                cell.setStyle(style);

                return;
            }

            setContentProperty(cell, r - 1, c - 1);
        };

        root.children.forEach((row, r) => {
            row.setId(uuid());
            row.children.forEach((cell, c) => {
                cell.setId(uuid());
                bindCell(cell, r, c);
            });
        });
        root.setId(uuid());
    }

    public loadPopupMenu(menu: MxPopupMenu, cell: MxCell, disabled: boolean = false) {
        const intl = LocalesService.useIntl();
        const addPopupItem = (text, handler, enabled = true) => {
            menu.addItem(text, null, handler, null, '', !disabled && enabled);
        };

        addPopupItem(intl.formatMessage(messages.addRow), () => {
            this.addRow(cell);
        });
        addPopupItem(
            intl.formatMessage(messages.removeRow),
            () => {
                this.deleteRow(cell);
            },
            this.graph.isTableRow(cell),
        );
        addPopupItem(intl.formatMessage(messages.addColumn), () => {
            this.addColumn(cell);
        });
        addPopupItem(
            intl.formatMessage(messages.removeColumn),
            () => {
                this.deleteColumn(cell);
            },
            this.graph.isTableRow(cell),
        );
    }

    private addRow(cell: MxCell) {
        this.graph.insertTableRow(cell);
        this.setCellDefaultValue(cell);
    }

    private addColumn(cell: MxCell) {
        this.graph.insertTableColumn(cell, false);
        this.setCellDefaultValue(cell);
    }

    private deleteRow(cell: MxCell) {
        this.graph.deleteTableRow(cell);
    }

    private deleteColumn(cell: MxCell) {
        this.graph.deleteTableColumn(cell);
    }

    private setCellDefaultValue(cell: MxCell) {
        cellsTreeWalkerDown([cell], (childCell: MxCell) => {
            if (!childCell.getValue()) {
                childCell.setValue('');
            }
            if (!!Number(childCell.getId())) {
                childCell.setId(uuid());
            }
        });
    }

    private initStyleForDiagram() {
        const style1 = {};
        const styleHeadVertical = MxUtils.clone(style1);
        styleHeadVertical[MxConstants.STYLE_SHAPE] = MxConstants.SHAPE_RECTANGLE;
        styleHeadVertical[MxConstants.STYLE_HORIZONTAL] = false;
        styleHeadVertical[MxConstants.STYLE_MOVABLE] = 0;
        styleHeadVertical[MxConstants.STYLE_RESIZABLE] = 0;
        styleHeadVertical[MxConstants.STYLE_DELETABLE] = 1;
        styleHeadVertical[BPMMxConstants.STYLE_SELECTABLE] = 0;
        this.graph.getStylesheet().putCellStyle('headVertical', styleHeadVertical);

        const styleHeadHorizontal = MxUtils.clone(styleHeadVertical);
        styleHeadHorizontal[MxConstants.STYLE_HORIZONTAL] = true;
        this.graph.getStylesheet().putCellStyle('headHorizontal', styleHeadHorizontal);
    }
}
