import { put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import { TreeItemContextMenuAction, TreeItemType } from '../../modules/Tree/models/tree';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../../actionsTypes/tree.actionTypes';
import { TTreeItemContextMenuAction } from '../../actions/tree.actions.types';
import { DashboardNode } from '../../serverapi/api';
import { closeDialog, openDialog } from '../../actions/dialogs.actions';
import { DialogType } from '../../modules/DialogRoot/DialogRoot.constants';
import {
    TDashboardCreate,
    TDashboardEditorModeChangedAction,
    TDashboardRestore,
    TDashboardSave,
    TLoadDashboardByIdAction,
} from '../../actions/dashboard.actions.types';
import {
    DASHBOARD_CREATE,
    DASHBOARD_EDITOR_MODE_CHANGED,
    DASHBOARD_RESTORE,
    DASHBOARD_SAVE,
    LOAD_DASHBOARD_BY_ID,
} from '../../actionsTypes/dashboard.actionTypes';
import { TServerEntity } from '@/models/entities.types';
import { ServerSelectors } from '@/selectors/entities/server.selectors';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import {
    workspaceActivateTab,
    workspaceAddTab,
    workspaceRemoveTab,
    workspaceRemoveTabRequest,
} from '@/actions/tabs.actions';
import { TabsBusActions } from '@/actionsTypes/tabsBus.actionTypes';
import { getActiveModelContext, getContentLoadingPageTab } from '../utils';
import { DashboardDaoService } from '@/services/dao/DashboardDAOService';
import { IWorkspaceTabItemModelParams, TWorkspaceTab } from '@/models/tab.types';
import { WorkSpaceTabTypes } from '@/modules/Workspace/WorkSpaceTabTypesEnum';
import { EditorMode } from '@/models/editorMode';
import { recentAddModel } from '@/actions/recent.actions';
import { treeItemChildAdd } from '@/actions/tree.actions';
import { TreeNode } from '@/models/tree.types';
import { editorModeChangedAction } from '@/actions/editor.actions';
import { IModelContext } from '../utils.types';
import { LocalStorageDaoService } from '@/services/dao/LocalStorageDaoService';
import { TDashboard } from '@/modules/Dashboard/Dashboard.types';
import {
    addWidget,
    loadDashboardById,
    restoreDashboard,
    saveDashboard,
    selectWidget,
    setCellsCount,
} from '@/actions/dashboard.actions';
import { DashboardSelector } from '@/selectors/entities/dashboard.selectors';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { spreadsheetLoadById } from '@/actions/entities/spreadsheet.actions';
import { WORKSPACE_TABS_REMOVE_REQUEST } from '@/actionsTypes/tabs.actionTypes';
import { TWorkspaceTabsRemoveAction } from '@/actions/tabs.actions.types';

function* handleTreeItemsDashboardAdd({ payload: { nodeId, type, action } }: TTreeItemContextMenuAction) {
    if (
        (type === TreeItemType.Folder || type === TreeItemType.Repository) &&
        action === TreeItemContextMenuAction.ADD_DASHBOARD
    ) {
        yield put(openDialog(DialogType.DASHBOARD_CREATE_DIALOG, { parentNodeId: nodeId }));
    }
}

export function* handleDashboardCreate({ payload: { parentNodeId, name } }: TDashboardCreate) {
    const { repositoryId, serverId } = parentNodeId;

    const nodeId = {
        id: uuid(),
        repositoryId,
        serverId,
    };

    const savedDashboard = yield DashboardDaoService.saveDashboard(undefined, nodeId, name, parentNodeId);
    yield put(
        treeItemChildAdd({
            parentNodeId: savedDashboard.parentNodeId!,
            child: [savedDashboard as TreeNode],
        }),
    );
    yield put(closeDialog(DialogType.DASHBOARD_CREATE_DIALOG));
    yield put(loadDashboardById(nodeId, true));
}

export function* handleDashboardSave({ payload: { nodeId } }: TDashboardSave) {
    const dashboard: TDashboard | undefined = yield select(DashboardSelector.dashboardById(nodeId));
    const { name, parentNodeId }: TreeNode = yield select(TreeSelectors.itemById(nodeId));

    yield DashboardDaoService.saveDashboard(dashboard, nodeId, name, parentNodeId!);
}

export function* handleDashboardRestore({ payload: { nodeId, dataValue } }: TDashboardRestore) {
    const serverId: string = yield select(ServerSelectors.serverId);
    if (dataValue) {
        const dashboard: TDashboard | undefined = JSON.parse(dataValue || '');
        if (dashboard) {
            const { horizontalCount, verticalCount, widgets = [] } = dashboard;
            if (horizontalCount && verticalCount) {
                yield put(setCellsCount(nodeId, verticalCount, horizontalCount));
            }
            for (const widget of widgets) {
                if (widget.source?.id) {
                    yield put(spreadsheetLoadById({...widget.source.id, serverId}));
                }
                yield put(addWidget(widget, nodeId));
            }
        }
    }
}

function* handleLoadDashboardById({ payload: { nodeId, isCreateAtion } }: TLoadDashboardByIdAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));
    if (!server) {
        throw new Error(`Cannot find server with ID=${nodeId.id}`); // tslint:disable-line:no-console
    }
    const schema = yield select(TabsSelectors.byId(nodeId));
    if (schema) {
        yield put(workspaceActivateTab(schema));

        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);

        return;
    }
    const contentLoadingPageTab = yield getContentLoadingPageTab(nodeId);
    try {
        yield put(workspaceAddTab(contentLoadingPageTab));
        const dashboard: DashboardNode = yield DashboardDaoService.loadDashboard(nodeId);
        yield put(restoreDashboard(nodeId, dashboard.dataValue || ''));

        const workspaceTab: TWorkspaceTab = <TWorkspaceTab>{
            title: dashboard?.name || '',
            type: WorkSpaceTabTypes.DASHBOARD,
            nodeId,
            mode: isCreateAtion ? EditorMode.Edit : EditorMode.Read,
            content: {
                nodeId,
                parentNodeId: dashboard.parentNodeId,
                serverId: nodeId.serverId,
                type: 'DASHBOARD',
            },

            actions: {
                copy: false,
                cut: false,
                delete: false,
                focusSearch: false,
                paste: false,
                redo: false,
                undo: false,
                zoomFit: false,
            },
            params: {
                closable: true,
                serverId: server.id,
            } as IWorkspaceTabItemModelParams,
        };
        yield put(workspaceRemoveTab(contentLoadingPageTab));
        yield put(workspaceAddTab(workspaceTab));

        yield put(
            recentAddModel({
                nodeId,
                type: dashboard.type as TreeItemType,
                parentId: null,
                createdAt: new Date().toISOString(),
                title: dashboard.name,
                modelTypeId: dashboard.modelTypeId,
                modelTypeName: dashboard?.name || '',
            }),
        );
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);
    } catch (e) {
        yield put(workspaceRemoveTabRequest(contentLoadingPageTab));
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);
        throw e;
    }
}

function* handleModeChanged({ payload: { mode } }: TDashboardEditorModeChangedAction) {
    const activeModelContext: IModelContext = yield getActiveModelContext();
    if (activeModelContext?.schema?.type === WorkSpaceTabTypes.DASHBOARD) {
        yield put(editorModeChangedAction(mode));

        if (mode !== EditorMode.Edit) {
            yield put(saveDashboard(activeModelContext.nodeId));
            yield put(selectWidget(activeModelContext.nodeId, ''));
        }
    }
}

function* handleTabDashboardClose(action: TWorkspaceTabsRemoveAction) {
    const { workspaceTab } = action.payload;
    if (workspaceTab.type === WorkSpaceTabTypes.DASHBOARD) {
        yield put(saveDashboard(workspaceTab.nodeId));
        yield put(selectWidget(workspaceTab.nodeId, ''));
        yield put(workspaceRemoveTab(workspaceTab));
    }
}

export function* dashboardSaga() {
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleTreeItemsDashboardAdd);
    yield takeEvery(DASHBOARD_CREATE, handleDashboardCreate);
    yield takeEvery(DASHBOARD_SAVE, handleDashboardSave);
    yield takeEvery(DASHBOARD_RESTORE, handleDashboardRestore);
    yield takeEvery(LOAD_DASHBOARD_BY_ID, handleLoadDashboardById);
    yield takeEvery(DASHBOARD_EDITOR_MODE_CHANGED, handleModeChanged);
    yield takeEvery(WORKSPACE_TABS_REMOVE_REQUEST, handleTabDashboardClose);
}
