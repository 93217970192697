import React, { memo, FC } from 'react';
import { TSpriteSymbol } from '../../../../models/spriteSymbol.types';
import classnames from 'classnames';
import theme from './Icon.scss';

type TIconProps = {
    spriteSymbol: TSpriteSymbol;
    disabled?: boolean;
    className?: string;
    onClick?: (e?: React.MouseEvent) => void;
    dataTest?: string;
} & JSX.IntrinsicAttributes;

const Icon: FC<TIconProps> = ({ spriteSymbol, className, onClick, disabled, dataTest }) => {
    return spriteSymbol ? (
        <svg
            onClick={onClick}
            className={classnames(theme.container, className, disabled ? theme.icon_disabled : '')}
            viewBox={spriteSymbol.viewBox}
        >
            <use data-test={dataTest} xlinkHref={`#${spriteSymbol.id}`} />
        </svg>
    ) : null;
};

const withMemo = memo(Icon);

export { withMemo as Icon };
