import { SearchRule } from '@/serverapi/api';

export interface ISearchRuleWithId extends SearchRule {
    id: string;
}

export enum SearchRuleAttributeType {
    SYSTEM = 'SYSTEM',
    USER = 'USER',
}
