import React, { useContext } from 'react';
import { getSelectedBlocksMetadata } from 'draftjs-utils';
import { useIntl } from 'react-intl';
import { Select, Tooltip } from 'antd';
import { DEFAULT_LINE_HEIGHT } from './Controls.constants';
import messages from '../CommonToolbar.messages';
import { Icon } from '@/modules/UIKit';
import icArrowDown from 'icons/toolbar/controls/ic-arrow-down.svg';
import { setSelectedBlocksData } from '../../common/contentBlocks.utils';
import ControlsContext from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';

const getFocusedBlock = (editorState) => {
    return getSelectedBlocksMetadata(editorState).get('line-height') || DEFAULT_LINE_HEIGHT;
};

const getChangedBlock = (editorState, lineHeight) => {
    return setSelectedBlocksData(editorState, { 'line-height': lineHeight });
};

const baseOptions = [
    {
        value: '1',
        label: '1',
    },
    {
        value: '1.2',
        label: '1.2',
    },
    {
        value: '1.5',
        label: '1.5',
    },
    {
        value: '1.75',
        label: '1.75',
    },
    {
        value: '2',
        label: '2',
    },
    {
        value: '3',
        label: '3',
    },
    {
        value: '3.5',
        label: '3.5',
    },
    {
        value: '4',
        label: '4',
    },
    {
        value: '4.5',
        label: '4.5',
    },
    {
        value: '5',
        label: '5',
    },
    {
        value: '5.5',
        label: '5.5',
    },
    {
        value: '6',
        label: '6',
    },
];

const LineHeightComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const intl = useIntl();

    return (
        <span data-test="wiki-toolbar-group_select-line-interval">
            <Tooltip title={intl.formatMessage(messages.lineHeight)} mouseLeaveDelay={0}>
                <Select
                    value={value}
                    style={{ width: 80 }}
                    onChange={setCurrentState}
                    options={baseOptions}
                    suffixIcon={<Icon spriteSymbol={icArrowDown} />}
                />
            </Tooltip>
        </span>
    );
};

export default LineHeightComponent;
