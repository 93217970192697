import type { TFloatingAttributesDialogContentRef } from './FloatingAttributesPanel.types';
import React, { useRef, useState } from 'react';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import messages from './FloatingAttributes.messages';
import { Button } from 'antd';
import { useIntl } from 'react-intl';
import { FloatingAttributesPanel } from './FloatingAttributesPanel.component';
import { TFloatingAttributesDialogProps } from '../FloatingAttributes.types';

export const FloatingAttributesDialog = (props: TFloatingAttributesDialogProps) => {
    const [rulesIsValid, setRulesValidity] = useState<boolean>(true);
    const { onCancel, visible } = props;
    const intl = useIntl();
    const ref = useRef<TFloatingAttributesDialogContentRef>(null);
    const handleSubmit = () => ref.current?.submit();
    const footer = [
        <Button key="cancel" size="large" onClick={onCancel}>
            {intl.formatMessage(messages.cancel)}
        </Button>,
        <Button disabled={!rulesIsValid} key="ok" size="large" type="primary" onClick={handleSubmit}>
            {intl.formatMessage(messages.save)}
        </Button>,
    ];

    return (
        <Dialog width={750} open={visible} footer={footer} onCancel={onCancel}>
            <FloatingAttributesPanel ref={ref} {...props} setRulesValidity={setRulesValidity} />
        </Dialog>
    );
};
