import React, { useEffect, useState, FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectObjectDialogPrepareDataRequest,
    selectObjectDialogSubmit,
} from '../../../../../actions/selectObjectDefinitionDialog.actions';
import { SelectObjectDefinitionDialogSelectors } from '../../../../../selectors/selectObjectDefinitionDialog.selectors';
import { ExistingDefinitionSelectView } from '../view/ExistingDefinitionSelectView.component';
import { SelectedStrategy } from '../../../../../models/selectObjectDialog.types';
import messages from '../SelectExistingDefinitionDialogs.messages';
import { useIntl } from 'react-intl';
import Flag from '../../../../../resources/icons/ic-tree-e-epc-event.svg';
import { RadioChangeEvent } from 'antd';
import { Icon } from '../../../../UIKit/components/Icon/Icon.component';
import theme from '../view/ExistingDefinitionSelectView.scss';
import { closeDialog } from '../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { setFocusAndStartEditAction } from '../../../../../actions/editor.actions';
import { ObjectDefinitionImpl } from '../../../../../models/bpm/bpm-model-impl';
import { useObjectTableData } from '../hooks/useObjectTableData';

type TSelectObjectDefinitionContainerProps = {
    instances: ObjectDefinitionImpl[];
    cellId: string;
    visible: boolean;
    objectName: string;
};

export const SelectObjectDefinitionContainer: FC<TSelectObjectDefinitionContainerProps> = ({
    instances,
    cellId,
    visible,
    objectName
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [selectedInstanceId, setSelectedInstanceId] = useState<string | null>(instances?.[0]?.nodeId.id || null);
    const [selectedStrategy, setSelectedStrategy] = useState<SelectedStrategy>(SelectedStrategy.setNewName);

    const { oldName, objectTypeTitle, isObjectDeletable } = useSelector(
        SelectObjectDefinitionDialogSelectors.selectObjectDefinitionDialogState,
    );

    useEffect(() => {
        dispatch(selectObjectDialogPrepareDataRequest(instances, cellId));
    }, []);

    const nameRenderer = (objectDefinition: ObjectDefinitionImpl) => {
        const isSelected = objectDefinition.nodeId.id === selectedInstanceId;

        return (
            <div className={!isSelected ? theme.name : ''}>
                {isSelected && <Icon className={theme.flag} spriteSymbol={Flag} />}
                {objectDefinition.name}
            </div>
        );
    };

    const { columns, dataSource, onRow } = useObjectTableData(instances, nameRenderer, (key) =>
        setSelectedInstanceId(key),
    );

    const onClose = useCallback(() => {
        dispatch(setFocusAndStartEditAction(cellId));
        dispatch(closeDialog(DialogType.SELECT_OBJECT_DIALOG));
    }, [cellId]);

    const handleSubmit = () => {
        const selectedInstance = instances.find(({ nodeId: { id } }) => id === selectedInstanceId);

        if (!selectedInstance) return;

        dispatch(selectObjectDialogSubmit({ strategy: selectedStrategy }, cellId, selectedInstance, isObjectDeletable, objectName));
    };

    const handleSelectStrategy = useCallback((e: RadioChangeEvent) => {
        setSelectedStrategy(e.target.value);
    }, []);

    return (
        <ExistingDefinitionSelectView
            visible={visible}
            onOk={handleSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.selectObject)}
            text={intl.formatMessage(messages.objectHeaderMessage, { objectTypeTitle })}
            tableProps={{
                disabled: selectedStrategy === SelectedStrategy.setNewName,
                dataSource,
                columns,
                onRow,
            }}
            radioProps={{
                selectedStrategy,
                handleSelectStrategy,
                data: [
                    {
                        value: SelectedStrategy.setNewName,
                        text: intl.formatMessage(messages.createNewObject),
                        dataTest: 'select-object_set-new-name-object-radio',
                    },
                    {
                        value: SelectedStrategy.useExisting,
                        text: intl.formatMessage(messages.useSelectedObject),
                        dataTest: 'select-object_use-existing-object-radio',
                        warningText: isObjectDeletable
                            ? intl.formatMessage(messages.deleteObjectNotification, { name: `"${oldName}"` })
                            : '',
                    },
                ],
            }}
        />
    );
};
