import { defineMessages } from 'react-intl';

export default defineMessages({
    formName: {
        id: 'WikiLinkDialog.formName',
        defaultMessage: 'Установить ссылку',
    },
    formStatusError: {
        id: 'WikiLinkDialog.formStatusError',
        defaultMessage: 'Ошибка',
    },
    formConfirmButton: {
        id: 'WikiLinkDialog.formConfirmButton',
        defaultMessage: 'Создать',
    },

    formDeclineButton: {
        id: 'WikiLinkDialog.wikiFormDeclineButton',
        defaultMessage: 'Отмена',
    },

    formValidationMessageRequiredUrl: {
        id: 'WikiLinkDialog.formValidationMessageRequiredUrl',
        defaultMessage: 'Укажите url ссылки',
    },

    formValidationMessageRequiredLabel: {
        id: 'WikiLinkDialog.formValidationMessageRequiredLabel',
        defaultMessage: 'Укажите текст ссылки',
    },

    linkUrlLabel: {
        id: 'WikiLinkDialog.linkUrlLabel',
        defaultMessage: 'Url',
    },

    linkUrlRequiredError: {
        id: 'WikiLinkDialog.linkUrlRequiredError',
        defaultMessage: 'Введите урл',
    },

    linkTitleLabel: {
        id: 'WikiLinkDialog.linkTitleLabel',
        defaultMessage: 'Текст подсказки',
    },

    linkTextLabel: {
        id: 'WikiLinkDialog.linkTextLabel',
        defaultMessage: 'Текст ссылки',
    },
});
