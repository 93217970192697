import React, { Component } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import formMessages from '../../../../models/formDefault.messages';
import dialogMessages from '../../UserManagement/messages/userManagment.messages';
import { UserDTOLicensesEnum } from '../../../../serverapi/api';
import { Button, Table } from 'antd';
import licenseTypes from '../../../../models/licenseTypes';
import licenseTypesMsg from '../../../../models/licenseTypes.messages';
import { DEFAULT_DIALOG_WIDTH } from '../../../../config/config';
import { toggleArrayItem } from '../../../../utils/toggleArrayItem';
import { TAssignUserLicenseDialogActionProps, TAssignUserLicenseDialogProps } from '../AssignUserLicenseDialog.types';
import theme from './AssignUserLicenseDialog.scss';

type TAssignUserLicenseDialogFullProps = TAssignUserLicenseDialogProps &
    TAssignUserLicenseDialogActionProps &
    WrappedComponentProps;

type TAssignUserLicenseTableRecord = {
    key: string;
    title: string;
};

class AssignUserLicenseDialog extends Component<TAssignUserLicenseDialogFullProps> {
    state = {
        selectedRowKeys: this.props.userData.licenses
            ? (this.props.userData.licenses.map((g, i) => g || i) as string[])
            : [],
    };

    handleSubmit = () => {
        this.props.onSubmit(this.state.selectedRowKeys as UserDTOLicensesEnum[]);
    };

    onSelectChange = (selectedRowKeys: any) => {
        this.setState({ selectedRowKeys });
    };

    getColumns = () => {
        return [
            {
                title: this.props.intl.formatMessage(dialogMessages.titleCol),
                dataIndex: 'title',
            },
        ];
    };

    getData = () => {
        return Object.keys(licenseTypes).map((k) => ({
            key: k,
            title: this.props.intl.formatMessage(licenseTypesMsg[k]),
        }));
    };

    addRowDisableClassName = () => {
        return '';
    };

    onRow = (row: TAssignUserLicenseTableRecord) => {
        const newState: string[] = toggleArrayItem<string>(row.key, this.state.selectedRowKeys);

        return {
            onClick: () => this.setState({ selectedRowKeys: newState }),
        };
    };

    render() {
        const { onClose, intl, visible } = this.props;
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const footer = [
            <Button key="cancel" size="large" onClick={onClose}>
                {intl.formatMessage(formMessages.cancelButton)}
            </Button>,
            <Button
                data-test="user-managment_add-licenses-to-user-submite"
                key="ok"
                size="large"
                type="primary"
                onClick={this.handleSubmit}
            >
                {intl.formatMessage(formMessages.choiceButtonLabel)}
            </Button>,
        ];

        return (
            <Dialog
                className={theme.dialog}
                onOk={this.handleSubmit}
                onCancel={onClose}
                title={intl.formatMessage(dialogMessages.assignedLicenses)}
                open={visible}
                width={DEFAULT_DIALOG_WIDTH}
                footer={footer}
            >
                <div className={theme.container}>
                    <Table
                        size="middle"
                        rowSelection={rowSelection}
                        className={theme.table}
                        rowClassName={this.addRowDisableClassName}
                        columns={this.getColumns()}
                        dataSource={this.getData()}
                        pagination={false}
                        onRow={this.onRow}
                        scroll={{
                            y: 'max-content',
                            x: 'max-content',
                        }}
                    />
                </div>
            </Dialog>
        );
    }
}

export default injectIntl(AssignUserLicenseDialog);
