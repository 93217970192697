import { Button } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../../../../actions/dialogs.actions";
import { DEFAULT_DIALOG_WIDTH } from "../../../../../config/config";
import { DialogType } from "../../../../DialogRoot/DialogRoot.constants";
import { Dialog } from "../../../../UIKit/components/Dialog/Dialog.component";
import messages from '../../../messages/NewSimulationNodeDIalog.messages';

export const SaveSimulationParamsDialog = (props) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const {activeSimulation, saveSimulation} = props;
    const handleCancel = () => {
        dispatch(closeDialog(DialogType.SAVE_SIMULATION_PARAMS_DIALOG));
    };

    const handleOk = () => {
        saveSimulation(activeSimulation);
    };
    
    const footer = [
        <Button key="cancel" size="large" onClick={handleCancel}>
            {intl.formatMessage(messages.cancel)}
        </Button>,
        <Button
            key="ok"
            size="large"
            type="primary"
            onClick={handleOk}
        >
            {intl.formatMessage(messages.continueSave)}
        </Button>,
    ];

    return (
        <Dialog
            onCancel={handleCancel}
            title={intl.formatMessage(messages.saveParamsLaunch)}
            visible
            width={DEFAULT_DIALOG_WIDTH}
            footer={footer}
        >
            {intl.formatMessage(messages.clearResults)}
        </Dialog>
    );
};

