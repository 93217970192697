import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import messages from './UserProfileDialog.messages';
import { MultiLangInputDialog } from '../../MultiLangInputDialog/MultiLangInputDialog.component';
import { InternationalString } from '../../../serverapi/api';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { addServerProfile } from '../../../actions/serverProfile.actions';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { MetaDataSelectors } from '../../../selectors/admintools.selectors';
import { Form } from 'antd';
import { LocalesService } from '../../../services/LocalesService';

type TAddUserProfileDialogProps = {
    visible: boolean;
};

const AddUserProfileDialog: FC<TAddUserProfileDialogProps> = (props) => {
    const { visible } = props;
    const intl = useIntl();
    const [generalForm] = Form.useForm();
    const dispatch = useDispatch();
    const currentLocale = useSelector(getCurrentLocale);
    const serverId = useSelector(MetaDataSelectors.getCurrentServerId);
    const [multilingualName, setMultilingualName] = useState<InternationalString>({});

    return (
        <Dialog
            onOk={() => {
                generalForm.validateFields().then(() => {
                    const serverProfile = {
                        name: LocalesService.internationalStringToString(multilingualName, currentLocale),
                        multilingualName,
                        id: uuid(),
                    };
                    dispatch(
                        addServerProfile({
                            serverProfile,
                            serverId,
                        }),
                    );
                });
            }}
            onCancel={() => {
                dispatch(closeDialog(DialogType.ADD_USER_PROFILE));
            }}
            cancelText={intl.formatMessage(messages.profileCancelButton)}
            okText={intl.formatMessage(messages.profileConfirmButton)}
            title={intl.formatMessage(messages.profileName)}
            open={visible}
            width={DEFAULT_DIALOG_WIDTH}
        >
            <Form form={generalForm} layout="vertical">
                <MultiLangInputDialog
                    placeholder={intl.formatMessage(messages.profileNamePlaceholder)}
                    multiLangValue={multilingualName}
                    data-test="profile-form_profile-name-input"
                    onChange={setMultilingualName}
                    label={intl.formatMessage(messages.profileNameLabel)}
                    mainInputName="multilingualName"
                    generalForm={generalForm}
                    required
                    autoFocus
                />
            </Form>
        </Dialog>
    );
};

export { AddUserProfileDialog };
