import { defineMessages } from 'react-intl';

export default defineMessages({
    cut: {
        id: 'WikiEditor.cut',
        defaultMessage: 'Вырезать',
    },
    paste: {
        id: 'WikiEditor.paste',
        defaultMessage: 'Вставить',
    },
    copy: {
        id: 'WikiEditor.copy',
        defaultMessage: 'Копировать',
    },
    delete: {
        id: 'WikiEditor.delete',
        defaultMessage: 'Удалить',
    },
    wikiModel: {
        id: 'WikiEditor.wikiModel',
        defaultMessage: 'Wiki-страница',
    },
    toggleComment: {
        id: 'WikiEditor.toggleComment',
        defaultMessage: 'Цитировать',
    },
});
