import * as React from 'react';
import { GroupDTO } from '../../../../serverapi/api';
import formMessages from '../../../../models/formDefault.messages';
import messages from '../../UserManagement/messages/userManagment.messages';
import { Button, Table } from 'antd';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { ALLUSERS_GROUPID } from '../../data/admintool';
import theme from './AssignUserGroupsDialog.scss';
import { toggleArrayItem } from '../../../../utils/toggleArrayItem';
import { TAssignUserGroupsDialogActionProps, TAssignUserGroupsDialogProps } from '../AssignUserGroupsDialog.types';

type TAssignUserGroupsDialogFullProps = TAssignUserGroupsDialogProps &
    TAssignUserGroupsDialogActionProps &
    WrappedComponentProps;

type TAssignUserGroupsTableRecord = {
    key: number;
    title: string;
};

class AssignUserGroupsDialog extends React.Component<TAssignUserGroupsDialogFullProps> {
    state = {
        selectedRowKeys: this.props.usersGroups ? this.props.usersGroups.map((g) => g.id) : [],
    };

    componentDidMount() {
        this.props.fetchGroups(this.props.serverId);
    }

    handleSubmit = () => {
        if (this.state.selectedRowKeys) {
            const groups = this.props.groups.filter((g) => g.id && this.state.selectedRowKeys.includes(g.id));
            this.props.onSubmit(groups);
        }
    };

    onSelectChange = (selectedRowKeys: any) => {
        if (!selectedRowKeys.includes(`${ALLUSERS_GROUPID}`)) selectedRowKeys.push(`${ALLUSERS_GROUPID}`);
        this.setState({
            selectedRowKeys,
        });
    };

    getColumns = () => {
        return [
            {
                title: this.props.intl.formatMessage(messages.titleCol),
                dataIndex: 'title',
            },
        ];
    };

    getData = (srcData: GroupDTO[]) => {
        return srcData.map((item) => ({
            key: item.id,
            title: item.groupName,
        }));
    };

    addRowClassName = (record: any) => (record.key === `${ALLUSERS_GROUPID}` ? theme.disabled : '');

    onRow = (row: TAssignUserGroupsTableRecord) => {
        const newState: number[] = toggleArrayItem<number>(row.key, this.state.selectedRowKeys);

        return {
            onClick: () => this.setState({ selectedRowKeys: newState }),
        };
    };

    render() {
        const { intl, onClose, visible, groups } = this.props;

        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        const footer = [
            <Button key="cancel" size="large" onClick={onClose}>
                {intl.formatMessage(formMessages.cancelButton)}
            </Button>,
            <Button
                data-test="user-managment_add-user-to-group-submite"
                key="ok"
                size="large"
                type="primary"
                onClick={this.handleSubmit}
                disabled={!hasSelected}
            >
                {intl.formatMessage(formMessages.choiceButtonLabel)}
            </Button>,
        ];

        return (
            <Dialog
                className={theme.dialog}
                onOk={this.handleSubmit}
                onCancel={onClose}
                title={intl.formatMessage(messages.inGroups)}
                open={visible}
                width="480px"
                footer={footer}
            >
                {groups && (
                    <div className={theme.container}>
                        <Table
                            size="middle"
                            rowClassName={this.addRowClassName}
                            rowSelection={rowSelection}
                            className={theme.table}
                            columns={this.getColumns()}
                            dataSource={this.getData(groups)}
                            pagination={false}
                            onRow={this.onRow}
                            scroll={{
                                y: 'max-content',
                                x: 'max-content',
                            }}
                        />
                    </div>
                )}
            </Dialog>
        );
    }
}

export default injectIntl(AssignUserGroupsDialog);
