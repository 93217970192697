import React, { FC } from 'react';
import theme from './AttributeFilter.scss';
import cx from 'classnames';
import messages from './AttributeFilter.messages';
import icDelete from '../../../resources/icons/Deleted.svg';
import { Icon, NUIButton } from '@/modules/UIKit';
import { v4 as uuid } from 'uuid';
import icAdd from '../../../resources/icons/ic-kanban-add.svg';
import { SearchRuleAttributeType, ISearchRuleWithId } from './AttributeFilter.types';
import { AttributeType, AttributeTypeValueTypeEnum, NodeId, SearchRuleQueryRuleEnum } from '@/serverapi/api';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { AttributeValueType, RuleType } from '@/modules/FloatingAttributes/components/AttributesEditor/Attribute.types';
import { getQueryRulesByAttributeType } from './AttributeFilter.utils';
import { useIntl } from 'react-intl';
import { AttributeValueInput } from './AttributeValueInput.component';

type TAttributeFilterProps = {
    searchRules: ISearchRuleWithId[];
    setSearchRules: (searchRules: ISearchRuleWithId[]) => void;
    attributeTypes: AttributeType[];
    nodeId: NodeId;
    isHidden: boolean;
};

export const AttributeFilter: FC<TAttributeFilterProps> = (props) => {
    const { searchRules, setSearchRules, attributeTypes, nodeId, isHidden } = props;
    const currentLocale = useSelector(getCurrentLocale);
    const intl = useIntl();

    const onAddCondition = () => {
        setSearchRules([
            ...searchRules,
            {
                id: uuid(),
                attributeTypeId: attributeTypes[0]?.id,
                attributeType: SearchRuleAttributeType.USER,
                queryRule: RuleType.HAS_VALUE,
                values: [],
            },
        ]);
    };

    const onDeleteCondition = (id: string) => {
        setSearchRules(searchRules.filter((rule) => rule.id !== id));
    };

    const onAttributeTypeIdChange = (ruleId: string, attributeTypeId: string) => {
        setSearchRules(
            searchRules.map((rule) => {
                if (rule.id !== ruleId) return rule;

                return {
                    ...rule,
                    attributeTypeId,
                    queryRule: RuleType.HAS_VALUE,
                    values: [],
                };
            }),
        );
    };

    const onQueryRuleChange = (
        ruleId: string,
        queryRule: SearchRuleQueryRuleEnum,
        valueType: AttributeTypeValueTypeEnum | undefined,
    ) => {
        setSearchRules(
            searchRules.map((rule) => {
                if (rule.id !== ruleId) return rule;
                if (
                    queryRule === RuleType.EQUALS &&
                    valueType === AttributeValueType.BOOLEAN &&
                    rule.values.length === 0
                )
                    return {
                        ...rule,
                        queryRule,
                        values: ['false'],
                    };
                if (queryRule !== RuleType.HAS_VALUE) {
                    return {
                        ...rule,
                        queryRule,
                    };
                }

                return {
                    ...rule,
                    queryRule,
                    values: [],
                };
            }),
        );
    };

    const onAttributeValueChange = (ruleId: string, values: string[]) => {
        setSearchRules(
            searchRules.map((rule) => {
                if (rule.id !== ruleId) return rule;

                return {
                    ...rule,
                    values,
                };
            }),
        );
    };

    return (
        <div className={cx(theme.attributeFilterContainer, { [theme.hidden]: isHidden })}>
            {searchRules.length ? (
                <div className={theme.conditionsContainer}>
                    <div className={theme.header}>
                        <div>{intl.formatMessage(messages.attribute)} </div>
                        <div className={theme.conditionTitle}>{intl.formatMessage(messages.condition)}</div>
                        <div className={theme.valueTitle}>{intl.formatMessage(messages.value)}</div>
                    </div>
                    {searchRules.map((rule) => {
                        const attributeType = attributeTypes.find((attrType) => attrType.id === rule.attributeTypeId);
                        const queryRules = getQueryRulesByAttributeType(attributeType?.valueType);

                        return (
                            <div className={theme.condition} key={rule.id}>
                                <Select
                                    value={rule.attributeTypeId}
                                    onChange={(attrTypeId) => {
                                        onAttributeTypeIdChange(rule.id, attrTypeId);
                                    }}
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                    virtual={false}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                        return ((option?.children ?? '') as string)
                                            .toLowerCase()
                                            .includes(input.toLocaleLowerCase().trim());
                                    }}
                                >
                                    <Select.OptGroup label={intl.formatMessage(messages.methodologyAttributes)}>
                                        {attributeTypes.map((attr) => (
                                            <Select.Option value={attr.id} key={attr.id}>
                                                {attr.multilingualName
                                                    ? attr.multilingualName[currentLocale]
                                                    : attr.name}
                                            </Select.Option>
                                        ))}
                                    </Select.OptGroup>
                                </Select>
                                <Select
                                    value={rule.queryRule}
                                    onChange={(queryRule) => {
                                        onQueryRuleChange(rule.id, queryRule, attributeType?.valueType);
                                    }}
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                >
                                    {queryRules.map((queryRule) => (
                                        <Select.Option value={queryRule} key={queryRule}>
                                            {intl.formatMessage(messages[queryRule])}
                                        </Select.Option>
                                    ))}
                                </Select>
                                <div className={theme.conditionValueContainer}>
                                    <AttributeValueInput
                                        key={rule.attributeTypeId}
                                        ruleId={rule.id}
                                        attributeType={attributeType}
                                        queryRule={rule.queryRule}
                                        values={rule.values}
                                        onAttributeValueChange={onAttributeValueChange}
                                        nodeId={nodeId}
                                    />
                                </div>

                                <button className={theme.deleteConditionBtn} onClick={() => onDeleteCondition(rule.id)}>
                                    <Icon spriteSymbol={icDelete} />
                                </button>
                            </div>
                        );
                    })}
                </div>
            ) : null}
            <NUIButton className={theme.addConditionBtn} onClick={onAddCondition} disabled={!attributeTypes.length}>
                <Icon spriteSymbol={icAdd} /> {intl.formatMessage(messages.addCondition)}
            </NUIButton>
        </div>
    );
};
