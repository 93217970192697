import React, { FC, useRef, useState } from 'react';
import menuItemTheme from '../items/MenuItem.scss';
import { TSpriteSymbol } from '../../../../models/spriteSymbol.types';
import { Dropdown, Menu, Tooltip } from 'antd';
import { CompactableDropdownButton } from '../CompactableDropdownButton/CompactableDropdownButton.component';
import { IconWithText } from '../IconWithText/IconWithText.component';
import { MenuInfo } from 'rc-menu/lib/interface';
import { TCompactableSelectItemType, ICompactableSelectItemValue } from './CompactableSelect.types';
import { TooltipPlacement } from 'antd/es/tooltip';
import cx from 'classnames';
import { useClickOutside } from '../../../UIKit/components/Select/useClickOutside';

type TCompactableSelectItem<T> = ICompactableSelectItemValue<T> | ICompactableSelectItemDivider;

interface ICompactableSelectItemDivider {
    type: TCompactableSelectItemType.Divider;
}

interface ICompactableSelectProps<T> {
    items: TCompactableSelectItem<T>[];
    value?: T;
    compact: boolean | undefined;
    disabled?: boolean;
    onSelect?: (value: T) => void;
    defaultSprite?: TSpriteSymbol;
    className?: string;
    onOpenChange?: (visible: boolean) => void;
    placement?: TooltipPlacement | undefined;
    title: string;
    disabledTitle?: string;
    rotateSymbol?: boolean | undefined;
}

function createAlignMenu<T>(props: ICompactableSelectProps<T>) {
    const { items, onSelect, rotateSymbol } = props;

    return (
        <Menu
            className={rotateSymbol ? menuItemTheme.alignMenuList : ''}
            onClick={(e: MenuInfo) => {
                if (onSelect) {
                    onSelect(items[e.key].value);
                }
            }}
        >
            {items.map((item, idx) => {
                if (item.type === TCompactableSelectItemType.Divider) {
                    return <Menu.Divider key={idx} />;
                }
                if (item.type === TCompactableSelectItemType.Value) {
                    return (
                        <Menu.Item key={idx} disabled={item.disabled}>
                            <IconWithText spriteSymbol={item.spriteSymbol} text={item.label} disabled={item.disabled} dataTest={item.dataTest} />
                        </Menu.Item>
                    );
                }

                return <Menu.Divider key={idx} />;
            })}
        </Menu>
    );
}

export function createCompactableSelect<T>(compactableSelectProps?: {
    rotateSymbol?: boolean;
}): FC<ICompactableSelectProps<T>> {
    return (props: ICompactableSelectProps<T>) => {
        const { items, value, disabled, defaultSprite, onOpenChange, title, disabledTitle = '', placement } = props;
        const current = items.find(
            (i) => i.type === TCompactableSelectItemType.Value && i.value === value,
        ) as ICompactableSelectItemValue<T>;

        const currentSprite: TSpriteSymbol | undefined =
            !current && defaultSprite ? defaultSprite : current.spriteSymbol;

        const containerRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
        const [isOpenDropdownMenu, setIsOpenDropdownMenu] = useState<boolean>(false);

        const onClick = () => {
            setIsOpenDropdownMenu((prevState) => !prevState);
        };

        useClickOutside(containerRef, () => setIsOpenDropdownMenu(false));

        return (
            <Dropdown
                onOpenChange={onOpenChange}
                overlay={createAlignMenu({ ...props, ...compactableSelectProps })}
                disabled={disabled}
                trigger={['click']}
                destroyPopupOnHide
                open={isOpenDropdownMenu}
            >
                <Tooltip
                    placement={placement}
                    title={!disabled ? props.compact && title : disabledTitle}
                    mouseLeaveDelay={0}
                    overlayStyle={{ maxWidth: '350px' }}
                >
                    <div
                        ref={containerRef}
                        className={cx(menuItemTheme.relativeTooltipContainer, disabled && menuItemTheme.buttonDisabled)}
                    >
                        <CompactableDropdownButton
                            title={title}
                            className={`${props.className || ''} e2e-test_header-menu_button`}
                            compact={props.compact}
                            disabled={disabled}
                            spriteSymbol={currentSprite}
                            onClick={onClick}
                        />
                    </div>
                </Tooltip>
            </Dropdown>
        );
    };
}
