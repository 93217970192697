import React, { FC, useEffect, useRef, useState } from 'react';
import theme from './GraphicalComparison.scss';
import { TGraphicalComparisonProps } from './CompareModels.types';
import cx from 'classnames';

type TPostitionState = {
    x: number;
    y: number;
    z: number;
};

const initPosition = { x: 0, y: 0, z: 1 };

export const GraphicalComparison: FC<TGraphicalComparisonProps> = (props) => {
    const {
        tab: {
            params: { model1Png, model2Png },
        },
    } = props;

    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [position, setPosition] = useState<TPostitionState>(initPosition);

    const wheelHandler = (e: WheelEvent) => {
        if (e.ctrlKey) {
            e.preventDefault();

            if (e.deltaY > 0) {
                setPosition((prevPos) => {
                    if (prevPos.z < 0.2) return prevPos;
                    return {
                        x: prevPos.x + e.offsetX / 10,
                        y: prevPos.y + e.offsetY / 10,
                        z: prevPos.z - e.deltaY / 1000,
                    };
                });
            } else {
                setPosition((prevPos) => {
                    if (prevPos.z > 5) return prevPos;
                    return {
                        x: prevPos.x - e.offsetX / 10,
                        y: prevPos.y - e.offsetY / 10,
                        z: prevPos.z - e.deltaY / 1000,
                    };
                });
            }
        } else if (e.shiftKey) {
            setPosition((prevPos) => {
                return { ...prevPos, x: prevPos.x + e.deltaY };
            });
        } else {
            setPosition((prevPos) => {
                return { ...prevPos, y: prevPos.y - e.deltaY };
            });
        }
    };

    const containerRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        const mouseDownHandler = (e: PointerEvent) => {
            if (e.button === 0 || e.button === 1) setIsDragging(true);
        };

        const mouseUpHandler = () => {
            setIsDragging(false);
        };

        document.addEventListener('pointerup', mouseUpHandler, true);

        if (containerRef.current) {
            containerRef.current.addEventListener('pointerdown', mouseDownHandler, true);
            containerRef.current.addEventListener('wheel', wheelHandler, true);
        }
        return () => {
            document.removeEventListener('pointerup', mouseUpHandler, true);
            if (containerRef.current) {
                containerRef.current.removeEventListener('pointerdown', mouseDownHandler, true);
                containerRef.current.removeEventListener('wheel', wheelHandler, true);
            }
        };
    }, []);

    const onMouseMoveHandler = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        if (isDragging) {
            setPosition((prevPos) => {
                return { ...prevPos, x: prevPos.x + e.movementX, y: prevPos.y + e.movementY };
            });
        }
    };

    return (
        <div
            onDragStart={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            ref={containerRef}
            className={theme.compareImagesContainer}
            onMouseMove={onMouseMoveHandler}
        >
            <div className={cx(theme.imageContainer, theme.dotBorder)}>
                <img
                    className={theme.leftCompareImage}
                    src={model1Png.url}
                    style={{ transform: `matrix(${position.z}, 0, 0, ${position.z}, ${position.x}, ${position.y})` }}
                />
            </div>
            <div className={theme.imageContainer}>
                <img
                    className={theme.leftCompareImage}
                    src={model2Png.url}
                    style={{ transform: `matrix(${position.z}, 0, 0, ${position.z}, ${position.x}, ${position.y})` }}
                />
            </div>
        </div>
    );
};
