import React from 'react';
import { Button } from 'antd';
import { injectIntl } from 'react-intl';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import messages from './DeleteCommentDialog.messages';
import { TDeleteCommentDialogProps } from './DeleteCommentDialog.types';

class DeleteCommentDialog extends React.Component<TDeleteCommentDialogProps, {}> {
    handleSubmit = () => {
        const { modelId, commentId, onSubmit } = this.props;
        if (onSubmit) {
            onSubmit(modelId, commentId);
        }
    };

    render() {
        const { intl, isAnswer, onClose } = this.props;

        const footer = [
            <Button data-test="cancel-delete-comment_btn" key="cancel" size="large" onClick={onClose}>
                <div>{intl.formatMessage(messages.cancel)}</div>
            </Button>,
            <Button data-test="confirm-delete-comment_btn" key="ok" size="large" type="primary" danger onClick={this.handleSubmit}>
                {intl.formatMessage(messages.deleteComment)}
            </Button>,
        ];

        return (
            <Dialog
                visible
                onOk={this.handleSubmit}
                onCancel={onClose}
                title={intl.formatMessage(messages.title)}
                footer={footer}
            >
                <div>{isAnswer ? intl.formatMessage(messages.textForAnswer) : intl.formatMessage(messages.text)}</div>
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(DeleteCommentDialog);

export { IntlComponent as DeleteCommentDialog };
