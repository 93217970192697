import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';

const getDialogs = (state: TRootState) => state.dialogs;

export namespace DialogsSelectors {
    export const isVisibleDialog = createSelector(getDialogs, (state) => !![...state.visible].length);
    export const getLastVisibleDialogType = createSelector(getDialogs, (state): DialogType | undefined => {
        return state.visible[state.visible.length - 1]?.type ;
    });
}
