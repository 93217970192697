import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { UserDTO } from '../../../../serverapi/api';
import { Button, Table, Input } from 'antd';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { TAddUsersToGroupRequestPayload } from '../../../../actions/groups.actions.types';
import adminToolTheme from '../../style/AdminTools.scss';
import formMessages from '../../../../models/formDefault.messages';
import groupMessages from '../../messages/groups.messages';
import userMessages from '../../UserManagement/messages/userManagment.messages';
import { DEFAULT_DIALOG_WIDTH } from '../../../../config/config';
import { SearchOutlined } from '@ant-design/icons';
import { sortByAlpha } from '../../../../utils/sortByAlpha';
import { toggleArrayItem } from '../../../../utils/toggleArrayItem';
import { TAddUserToGroupDialogActionProps, TAddUserToGroupDialogProps } from '../AddUserToGroupDialog.types';

type TAddUserToGroupDialogFullProps = TAddUserToGroupDialogActionProps &
    TAddUserToGroupDialogProps &
    WrappedComponentProps;

type TAddUserToGroupTableRecord = {
    key: number;
    login: string;
    userFIO: string;
};

class AddUserToGroupDialog extends Component<TAddUserToGroupDialogFullProps> {
    state = {
        searchInput: '',
        selectedRowKeys: this.props.usersInGroup ? this.props.usersInGroup.map((u, i) => (u.id ? u.id : i)) : [],
    };

    handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== this.state.searchInput) {
            this.setState({
                searchInput: e.target.value.trim().toLowerCase(),
            });
        }
    };

    onSelectChange = (selectedRowKeys: number[]) => {
        let newSelectedRowKeys: number[] = selectedRowKeys;
        const usersData = this.getData(this.props.allUsers);
        if (this.state.searchInput !== '' && this.state.selectedRowKeys) {
            newSelectedRowKeys.push(...this.state.selectedRowKeys.filter(
                (id) => !usersData.some((user) => user.key === id),
           ))
        }
        this.setState({
            selectedRowKeys: newSelectedRowKeys,
        });
    };

    handleSubmit = () => {
        const { serverId, groupId, onSubmit } = this.props;

        if (groupId) {
            const submitData: TAddUsersToGroupRequestPayload = {
                serverId,
                groupId,
                usersIds: [...this.state.selectedRowKeys],
            };
            onSubmit(submitData);
        }
    };

    getColumns = () => {
        return [
            {
                title: this.props.intl.formatMessage(userMessages.userLogin),
                dataIndex: 'login',
                key: 'login',
                width: 150,
                sorter: (a: any, b: any) => sortByAlpha(a.login, b.login),
            },
            {
                title: this.props.intl.formatMessage(userMessages.userFIO),
                dataIndex: 'userFIO',
                key: 'userFIO',
                width: 150,
                sorter: (a: any, b: any) => sortByAlpha(a.userFIO, b.userFIO),
            },
        ];
    };

    getData = (srcData: UserDTO[]) => {
        const data: UserDTO[] =
            this.state.searchInput === ''
                ? srcData
                : srcData.filter(
                    (item) =>
                        [item.login, item.lastName, item.firstName, item.middleName]
                            .filter((s) => s)
                            .map((s) => `${s}`.toLowerCase())
                            .filter((s) => s.includes(this.state.searchInput)).length,
                );

        return data.map((item) => ({
            key: item.id && item.id.toString(),
            login: item.login || '',
            userFIO: [item.lastName, item.firstName, item.middleName].filter((s) => s).join(' '),
        }));
    };

    onRow = (row: TAddUserToGroupTableRecord) => {
        const newState: number[] = toggleArrayItem<number>(row.key, this.state.selectedRowKeys);

        return {
            onClick: () => this.setState({ selectedRowKeys: newState }),
        };
    };

    render() {
        const { intl, onClose, visible, allUsers } = this.props;
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        const footer = [
            <Button key="cancel" size="large" onClick={onClose}>
                {intl.formatMessage(formMessages.cancelButton)}
            </Button>,
            <Button data-test="user-managment-group_add-user-to-group_submite" key="ok" size="large" type="primary" onClick={this.handleSubmit} disabled={!hasSelected}>
                {intl.formatMessage(formMessages.addButtonLabel)}
            </Button>,
        ];

        return (
            <Dialog
                onOk={this.handleSubmit}
                onCancel={onClose}
                title={intl.formatMessage(groupMessages.addUsersToGroup)}
                open={visible}
                width={DEFAULT_DIALOG_WIDTH}
                footer={footer}
            >
                <div>
                    <Input suffix={<SearchOutlined />} onChange={this.handleSearch} style={{ width: 200 }} />
                </div>
                <div>
                    {allUsers && (
                        <Table
                            className={adminToolTheme.dataTable}
                            size="middle"
                            rowSelection={rowSelection}
                            columns={this.getColumns()}
                            dataSource={this.getData(allUsers)}
                            scroll={{ y: 400 }}
                            pagination={false}
                            onRow={this.onRow}
                        />
                    )}
                </div>
            </Dialog>
        );
    }
}

export default injectIntl(AddUserToGroupDialog);
