import { connect } from 'react-redux';
import { openDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { v4 as uuid } from 'uuid';
import RichTextEditorToolbar from '@/modules/UIKit/components/RichTextEditor/Toolbar/RichTextEditorToolbar.component';

const mapStateToProps = (state, props) => {
    return {
        ...props,
        id: 'WikiModel',
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const { nodeId } = ownProps;

    return {
        handlers: {
            openImageDialog: (onSubmit) => {
                dispatch(
                    openDialog(DialogType.IMAGE_EDIT_DIALOG_WIKI, {
                        id: uuid(),
                        workspaceId: nodeId,
                        onSubmit,
                    }),
                );
            },
            openLinkDialog: (onSubmit) => {
                const { serverId } = nodeId;
                dispatch(openDialog(DialogType.LINK_DIALOG, { serverId, onSubmit }));
            },
            readFromClipboard: (callback) => {
                navigator.clipboard.readText().then(callback);
            },
            copyToClipboard: (text: string) => {
                navigator.clipboard.writeText(text);
            },
        },
    };
};

export const WikiEditorToolbarContainer = connect(mapStateToProps, mapDispatchToProps)(RichTextEditorToolbar);
