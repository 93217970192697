import { Record } from 'immutable';
import { getCurrentBlock, setBlockData, modifySelectedBlocks, insertEmptyBlock } from './contentBlocks.utils';

const BLOCK_INDNENT_ID_KEY = 'text-indent';
const MAX_INDENT_AMOUNT = 16;

export function hasIndentionInBlock(editorState) {
    const currentBlock = getCurrentBlock(editorState);

    return !!currentBlock.getData().get(BLOCK_INDNENT_ID_KEY);
}

export function handleReturn(editorState) {
    return insertNewLine(editorState);
}

export const setBlockIndent = (editorState) => {
    const handler = (finalEditorState, selection, block) => {
        const indent = +block.getData().get(BLOCK_INDNENT_ID_KEY) || 0;
        const nextIndent = indent + 2 >= MAX_INDENT_AMOUNT ? MAX_INDENT_AMOUNT : indent + 2;

        return setBlockData(finalEditorState, block, { [BLOCK_INDNENT_ID_KEY]: nextIndent });
    };

    return modifySelectedBlocks(editorState, handler);
};

export const removeIndent = (editorState) => {
    const handler = (finalEditorState, selection, block) => {
        const indent = +block.getData().get(BLOCK_INDNENT_ID_KEY) || 0;
        const nextIndent = indent - 2 < 0 ? 0 : indent - 2;

        return setBlockData(finalEditorState, block, { [BLOCK_INDNENT_ID_KEY]: nextIndent });
    };

    return modifySelectedBlocks(editorState, handler);
};

export const insertNewLine = (editorState) => {
    const currentBlock = getCurrentBlock(editorState);
    const indent = currentBlock.getData().get(BLOCK_INDNENT_ID_KEY);

    const data = indent ? { [BLOCK_INDNENT_ID_KEY]: indent } : {};

    return insertEmptyBlock(editorState, data);
};

const LineAnchor = Record({
    line: Number(0),

    offset: Number(0),
});

LineAnchor.prototype.getLine = function () {
    return this.get('line');
};

LineAnchor.prototype.getOffset = function () {
    return this.get('offset');
};
