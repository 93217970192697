import {
    MATRIX_OPEN,
    MATRIX_OPEN_BY_NODE_ID,
    MATRIX_CREATE,
    MATRIX_CREATE_SUCCESS,
    MATRIX_REQUEST_SUCCESS,
    MATRIX_SAVE_REQUEST,
    MATRIX_SAVE_REQUEST_SUCCESS,
    MATRIX_SAVE_REQUEST_FAILURE,
    MATRIX_REQUEST,
    MATRIX_CHANGE_DATA_REQUEST,
    MATRIX_UPLOAD_FILE,
    MATRIX_EDITOR_MODE_CHANGED,
    CHANGE_MATRIX_PROPERTIES,
} from '../../actionsTypes/entities/matrix.actionTypes';
import { EditorMode } from '../../models/editorMode';
import { MatrixData, MatrixNode, NodeId } from '../../serverapi/api';
import {
    TMatrixOpenByNodeIdPayload,
    TMatrixChangeDataRequestAction,
    TMatrixDefaultAction,
    TMatrixDefaultRequestAction,
    TMatrixDefaultRequestFailureAction,
    TMatrixEditorModeChangedAction,
    TMatrixOpenByNodeIdAction,
    TMatrixPropertyAddAction,
    TMatrixRequestAction,
    TMatrixUploadFileAction,
} from './matrix.actions.types';

export const matrixOpen = (matrix: MatrixNode): TMatrixDefaultAction => ({
    type: MATRIX_OPEN,
    payload: {
        matrix,
    },
});

export const matrixOpenByNodeId = (nodeId: NodeId): TMatrixOpenByNodeIdAction => ({
    type: MATRIX_OPEN_BY_NODE_ID,
    payload: { nodeId },
});

export const matrixCreate = (matrix: MatrixNode): TMatrixDefaultAction => ({
    type: MATRIX_CREATE,
    payload: {
        matrix,
    },
});

export const matrixCreateSuccess = (matrix: MatrixNode): TMatrixDefaultAction => ({
    type: MATRIX_CREATE_SUCCESS,
    payload: {
        matrix,
    },
});

export const matrixSaveRequest = (matrix: MatrixNode): TMatrixDefaultRequestAction => ({
    type: MATRIX_SAVE_REQUEST,
    payload: {
        matrix,
    },
});

export const matrixSaveRequestSuccess = (matrix: MatrixNode): TMatrixDefaultRequestAction => ({
    type: MATRIX_SAVE_REQUEST_SUCCESS,
    payload: {
        matrix,
    },
});

export const changeMatrixProperties = (nodeId: NodeId, matrix: MatrixNode): TMatrixPropertyAddAction => ({
    type: CHANGE_MATRIX_PROPERTIES,
    payload: {
        nodeId,
        matrix,
    },
});

export const matrixSaveRequestFailure = (serverId: string, error: string): TMatrixDefaultRequestFailureAction => ({
    type: MATRIX_SAVE_REQUEST_FAILURE,
    payload: { serverId, error },
});

export const matrixRequest = (payload: TMatrixOpenByNodeIdPayload): TMatrixRequestAction => ({
    type: MATRIX_REQUEST,
    payload,
});

export const matrixRequestSuccess = (matrix: MatrixNode): TMatrixDefaultRequestAction => ({
    type: MATRIX_REQUEST_SUCCESS,
    payload: {
        matrix,
    },
});

export const matrixChangeDataRequest = (nodeId: NodeId, data: MatrixData): TMatrixChangeDataRequestAction => ({
    type: MATRIX_CHANGE_DATA_REQUEST,
    payload: {
        nodeId,
        data,
    },
});

export const matrixUploadFile = (
    nodeId: NodeId,
    data: MatrixData,
    draggedFile: File,
    serverId: string,
    description: string,
    setContent: (data: MatrixData) => void
): TMatrixUploadFileAction => ({
    type: MATRIX_UPLOAD_FILE,
    payload: {
        nodeId,
        data,
        draggedFile,
        serverId,
        description,
        setContent,
    },
});

export const matrixEditorModeChangedAction = (mode: EditorMode): TMatrixEditorModeChangedAction => ({
    type: MATRIX_EDITOR_MODE_CHANGED,
    payload: {
        mode,
    },
});
