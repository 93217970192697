import React from 'react';
import messages from '../../messages/ImportDialog.messages';
import { Alert, Button, Form } from 'antd';
import { injectIntl } from 'react-intl';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { TDialogProps } from '../../../UIKit/components/Dialog/Dialog.types';
import { FormGroup } from '../../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import theme from './ImportRepositoryReplaceDialog.scss';
import { TImportRepositoryReplaceDialogProps } from '../../types/ImportDialog.types';

class ImportRepositoryReplaceDialog extends React.Component<TImportRepositoryReplaceDialogProps> {
    handleSubmit = () => {
        this.props.onSubmit();
    };

    render() {
        const { intl, visible } = this.props;
        const dialogProps: Partial<TDialogProps> = {
            visible,
            title: intl.formatMessage(messages.dialogReplaceRepositoryName),
        };

        const footer = [
            <Button key="apply" size="large" onClick={this.handleSubmit} disabled={false}>
                {intl.formatMessage(messages.replace)}
            </Button>,
            <Button key="cancel" size="large" onClick={this.props.onCancel} disabled={false}>
                {intl.formatMessage(messages.cancelButton)}
            </Button>,
        ];

        return (
            <Dialog {...dialogProps} footer={footer} onCancel={this.props.onCancel}>
                {/* todo 2979 удалить алерт */}
                {this.props.error && <Alert type="error" message={intl.formatMessage(this.props.error)} showIcon />}
                <Form layout="vertical" hideRequiredMark>
                    <FormGroup>
                        <Form.Item
                            className={theme.itemType}
                            label={
                                <p style={{ whiteSpace: 'pre-wrap' }}>
                                    {`${intl.formatMessage(messages.dialogReplaceRepository)} - "${this.props.path}"
${intl.formatMessage(messages.dialogConfirmationReplaceRepository)}`}
                                </p>
                            }
                        />
                    </FormGroup>
                    <p />
                </Form>
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(ImportRepositoryReplaceDialog);

export { IntlComponent as ImportRepositoryReplaceDialog };
