import React, { Component } from 'react';
import { Button, Form, Radio, RadioChangeEvent, Tooltip, FormInstance } from 'antd';
import { injectIntl } from 'react-intl';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import theme from './DecompositionDialog.scss';
import messages from './messages/DecompositionDialog.messages';
import { ModelFormContainer } from '../../../ModelDialog/containers/ModelForm.container';
import { ModelType, NodeId, ModelAssignmentNodeTypeEnum } from '../../../../serverapi/api';
import { TreeNode } from '../../../../models/tree.types';
import { TDecompositionDialogProps } from './DecompositionDialog.types';
import { allowedDecompositionNodeType } from '../../../../services/consts/TreeConsts';
import { Tree } from '../../../Tree/components/Tree/Tree.component';

type TDecompositionDialogState = {
    modelState: TTypeSelectorState;
    existingModel?: TreeNode;
    modelType?: ModelType;
    parentNodeId?: NodeId;
    isSubmitEnabled: boolean;
    errors: {
        modelType: boolean;
        existingModel: boolean;
    };
};

enum TTypeSelectorState {
    NEW,
    EXISTING,
}

type TFormValues = {
    modelName: string;
};

class DecompositionDialog extends Component<TDecompositionDialogProps> {
    formRef = React.createRef<FormInstance>();
    state: TDecompositionDialogState = {
        modelState: TTypeSelectorState.NEW,
        modelType: undefined,
        parentNodeId: {
            repositoryId: this.props.repositoryId,
            id: this.props.repositoryId,
            serverId: this.props.serverId,
        },
        isSubmitEnabled: true,
        errors: {
            modelType: false,
            existingModel: false,
        },
    };

    handleSubmit = () => {
        const {
            onSubmit,
            objectDefinitionId,
            edgeDefinitionId,
            serverId,
            activeGraphId,
            setStateAssignments,
            stateAssignments,
        } = this.props;
        const { modelState, modelType, parentNodeId } = this.state;
        const { existingModel } = this.props;
        const isNewModel = modelState === TTypeSelectorState.NEW;
        const isExistingModel = modelState === TTypeSelectorState.EXISTING;

        const form = this.formRef.current;
        if (form) {
            form.validateFields().then((formValues: TFormValues) => {
                const alwaysData = {
                    isNewModel,
                    serverId,
                    objectDefinitionId,
                    edgeDefinitionId,
                    setStateAssignments,
                    stateAssignments,
                    oldGraphId: activeGraphId,
                };
                if (isNewModel) {
                    if (!modelType) {
                        this.setState({
                            errors: {
                                modelType: true,
                            },
                        });
                    } else {
                        onSubmit({
                            ...alwaysData,
                            modelName: formValues.modelName,
                            modelTypeId: modelType!.id,
                            parentNodeId: parentNodeId!,
                        });
                        this.setState({ isSubmitEnabled: false });
                    }
                } else if (isExistingModel) {
                    if (!existingModel) {
                        this.setState({
                            errors: {
                                existingModel: true,
                            },
                        });
                    } else if (allowedDecompositionNodeType(existingModel.type)) {
                        onSubmit({
                            ...alwaysData,
                            modelName: existingModel.name,
                            modelTypeId: existingModel.modelTypeId!,
                            parentNodeId: existingModel.parentNodeId!,
                            modelNodeId: existingModel.nodeId,
                            nodeType: existingModel.type! as ModelAssignmentNodeTypeEnum,
                        });
                        this.setState({ isSubmitEnabled: false });
                    } else {
                        this.setState({
                            errors: {
                                existingModel: true,
                            },
                        });
                    }
                }
            });
        }
    };

    onParentNodeIdChange = (parentNode: TreeNode): void => {
        this.setState({ parentNodeId: parentNode.nodeId });
        this.props.onTreeItemSelect(parentNode);
    };

    handleRadioChange = (e: RadioChangeEvent) => {
        this.setState({
            modelState: e.target.value,
        });
        this.props.onTreeItemSelect(undefined);
    };

    onSelectType = (modelType: ModelType) => {
        this.setState((state: TDecompositionDialogState) => ({
            modelType: state.modelType === modelType ? undefined : modelType,
            errors: {
                modelType: false,
            },
        }));
    };

    render() {
        const { intl, visible, objectName, treeData, storeName, onCancel, serverId } = this.props;

        const footer = [
            <Button key="cancel" size="large" onClick={onCancel}>
                {intl.formatMessage(messages.decompositionDialogCancelButton)}
            </Button>,
            <Tooltip title={this.props.isModelEditor ? '' : intl.formatMessage(messages.needLicense)}>
                <Button
                    data-test="create-decomposition_button"
                    key="ok"
                    size="large"
                    type="primary"
                    onClick={this.handleSubmit}
                    disabled={!this.state.isSubmitEnabled || !this.props.isModelEditor}
                    className={theme.buttonWithTooltipe}
                >
                    {intl.formatMessage(messages.decompositionDialogSaveButton)}
                </Button>
            </Tooltip>,
        ];

        return (
            <Dialog
                className={theme.dialog}
                open={visible}
                onCancel={onCancel}
                title={intl.formatMessage(messages.decompositionDialogTitle)}
                onOk={this.handleSubmit}
                footer={footer}
            >
                <Radio.Group onChange={this.handleRadioChange} value={this.state.modelState}>
                    <Radio
                        data-test="decomposition-dialog_radio_new-model"
                        className={theme.radio}
                        value={TTypeSelectorState.NEW}
                    >
                        {intl.formatMessage(messages.decompositionDialogRadioNew)}
                    </Radio>
                    <Radio
                        data-test="decomposition-dialog_radio_existing-model"
                        className={theme.radio}
                        value={TTypeSelectorState.EXISTING}
                    >
                        {intl.formatMessage(messages.decompositionDialogRadioExisting)}
                    </Radio>
                </Radio.Group>
                {this.state.modelState === TTypeSelectorState.NEW && (
                    <ModelFormContainer
                        serverId={serverId}
                        parentNodeId={this.state.parentNodeId}
                        availableModelTypes={this.props.validDecompositionModelTypesIds}
                        allowAnyModelType={this.props.allowAnyDecomposition}
                        onParentNodeIdChange={this.onParentNodeIdChange}
                        onSelectType={this.onSelectType}
                        defaultModelName={intl.formatMessage(messages.decompositionDialogNewModelName, {
                            objectName: objectName || intl.formatMessage(messages.edgeText),
                        })}
                        formRef={this.formRef}
                        modelType={this.state.modelType}
                        errors={this.state.errors}
                        openedSelectNode
                    />
                )}
                {this.state.modelState === TTypeSelectorState.EXISTING && (
                    <Form ref={this.formRef} layout="vertical" className={theme.tree}>
                        <Form.Item
                            data-test="decomposition-dialog_existing-model_tree"
                            required
                            name="existingModel"
                            label={intl.formatMessage(messages.decompositionDialogExistingModelLabel)}
                        >
                            <>
                                {this.state.errors.existingModel && (
                                    <div className="ant-form-item-explain ant-form-item-explain-error">
                                        <div role="alert">{intl.formatMessage(messages.requiredExistingModel)}</div>
                                    </div>
                                )}
                                <Tree
                                    data={treeData}
                                    treeName={storeName}
                                />
                            </>
                        </Form.Item>
                    </Form>
                )}
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(DecompositionDialog);

export { IntlComponent as DecompositionDialog };
