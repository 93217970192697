import type { TNameToSearch, TSearchableCells } from './SearchByModelBllService.types';
import type { BPMMxGraph } from '../../mxgraph/bpmgraph';
import { MxCell, MxPoint } from '../../mxgraph/mxgraph';

const DOWNGRADE_PIXELS = 200;
const RAISE_GRADE_PIXELS = 100000;

enum SearchableCellType {
    Object = 'object',
    Edge = 'edge',
    Shape = 'shape',
}

function sumPoints(points: MxPoint[]): MxPoint {
    return points.reduce((sum, point) => {
        /* eslint-disable no-return-assign, no-param-reassign */
        sum.x += point.x;
        sum.y += point.y;
        /* eslint-enable no-return-assign, no-param-reassign */

        return sum;
    }, new MxPoint(0, 0));
}

export const getCellCoordinate = (cell: MxCell | undefined): MxPoint => {
    const parentCoordinates = !cell?.parent ? new MxPoint(0, 0) : getCellCoordinate(cell?.parent);
    const parentPoint = new MxPoint(parentCoordinates.x, parentCoordinates.y);
    const cellPoint = new MxPoint(cell?.geometry?.x || 0, cell?.geometry?.y || 0);

    return sumPoints([parentPoint, cellPoint]);
};

export function getFoundCells(searchNameFragment: string, cells: TSearchableCells): MxCell[] {
    const cellsArray = Object.entries(cells);

    return cellsArray.flatMap(([name, cell]) => {
        return name.toLowerCase().includes(searchNameFragment) ? cell : [];
    });
}

export function onCentered(cell: MxCell, graph: BPMMxGraph): void {
    if (!cell) return;

    graph.scrollCellToVisible(cell, true);
    graph.getSelectionModel().setCell(cell);
}

export function onFoundVisible(cells: MxCell[], graph: BPMMxGraph): void {
    graph.setCellsInactive(Object.values(graph.getModel().cells));
    graph.setCellsActive(cells);
    graph.getSelectionModel().setSingleSelection(true);
}

export function viewCellsActive(graph: BPMMxGraph): void {
    graph.setCellsActive(Object.values(graph.getModel().cells));
}

/*
    Сортирует объекты на экране преимущественно по оси X, однако если разница между объектами по оси Y становится
    значительной, то правая часть формулы (после знака "+") начинает влиять на конечный результат. Функция должна
    вернуть массив объектов, где в левой части будут объекты с наименьшими значениями Y (в пределах заданных
    константами), отсортированные по X, потом объекты с бОльшим Y и так далее. Это создаст "построчную" сортировку
*/

export const defineOrderCell = (p1: MxPoint | undefined, p2: MxPoint | undefined): number => {
    if (!p1 || !p2) return 0;

    return (
        p1.x - p2.x + (Math.floor(p1.y / DOWNGRADE_PIXELS) - Math.floor(p2.y / DOWNGRADE_PIXELS)) * RAISE_GRADE_PIXELS
    );
};

export const sortResults = (cells: MxCell[]): MxCell[] => {
    const coordinates: Map<MxCell, MxPoint> = cells.reduce(
        (map, cell) => map.set(cell, getCellCoordinate(cell)),
        new Map(),
    );

    return [...cells].sort((a, b) => defineOrderCell(coordinates.get(a), coordinates.get(b)));
};

export function getSearchableCells(graph: BPMMxGraph, sorted = false): Record<TNameToSearch, MxCell[]> {
    const allCellsOnModel: MxCell[] = Object.values(graph.getModel().cells);
    const cells: MxCell[] = sorted ? sortResults(allCellsOnModel) : allCellsOnModel;
    const searchableCells: TSearchableCells = {};

    function addSearchableItem(nameToSearch: string | undefined, cell: MxCell): void {
        if (nameToSearch) {
            if (searchableCells[nameToSearch]) {
                // несколько ячеек могут содержать одинаковый текст
                searchableCells[nameToSearch] = [...searchableCells[nameToSearch], cell];
            } else {
                searchableCells[nameToSearch] = [cell];
            }
        }
    }

    cells.forEach((cell) => {
        switch (cell?.getValue()?.type) {
            case SearchableCellType.Object: {
                const nameToSearch = graph.complexSymbolManager.getSearchValue(cell);

                addSearchableItem(nameToSearch, cell);
                break;
            }
            case SearchableCellType.Edge: {
                const nameToSearch = cell.getValue()?.name;

                addSearchableItem(nameToSearch, cell);
                break;
            }
            case SearchableCellType.Shape: {
                const nameToSearch = cell.getValue()?.metaInfo;

                addSearchableItem(nameToSearch, cell);
                break;
            }
            default:
                break;
        }
    });

    return searchableCells;
}
