import React, { FC } from 'react';
import theme from './ApprovalDialog.scss';
import { cloneDeep } from 'lodash-es';
import { IntlShape, useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';
import messages from './ApprovalDialog.messages';
import { Button, Modal, Tabs } from 'antd';
import { ApprovalDTOStatus, ApprovalStageDTOStatus, ApprovalStageDTOType, TApprovalDialogProps } from './ApprovalDialog.types';
import icAddApprovalStage from '../../resources/icons/ic-add-approval-stage.svg';
import { Icon } from '../UIKit';
import { ApprovalStagesTab } from './ApprovalStagesTab.component';
import { StageTab } from './StageTab.component';
import { ApprovalTemplatesStagesTab } from './ApprovalTemplatesStagesTab.component';
import { ApprovalDTO, ApprovalDTOStatusEnum, ApprovalStageDTO, ApprovalTemplateDTO } from '@/serverapi/api';
import { useDispatch } from 'react-redux';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import classNames from 'classnames';
import { StagesBllService } from '@/services/bll/ApprovalDialogStagesBLLService';

export const GENERAL_SETTINGS = 'GeneralSettings';

export const APPROVAL_STAGES = 'ApprovalStages';

export const ApprovalDialog: FC<TApprovalDialogProps> = (props) => {
    const {
        visible,
        isTemplate,
        onCancel,
        tabName,
        setTabName,
        formRef,
        title,
        generalFormComponent,
        isCreateMode,
        approval,
        onChangeStages,
        submitModifiedApproval
    } = props;

    const intl: IntlShape = useIntl();
    const dispatch = useDispatch();

    const footer = [
        <Button key="cancel" size="large" onClick={onCancel}>
            {intl.formatMessage(messages.cancel)}
        </Button>,
        !isTemplate ? <Button
            data-test="save-as-draft-approval-btn"
            className={classNames({[theme.hidden]: !isCreateMode})}
            key="draft"
            size="large"
            onClick={() => onSubmit(ApprovalDTOStatus.DRAFT)}
        >
            {intl.formatMessage(messages.saveAsDraft)}
        </Button> : null,
        <Button
            data-test="edit-approval_save-btn"
            key="ok"
            size="large"
            type="primary"
            onClick={() => {
                if (isTemplate) {
                    onSubmit();

                    return;
                } 
                const submitStatus = isCreateMode ? ApprovalDTOStatus.IN_PROCESS : (approval as ApprovalDTO ).status;
                onSubmit(submitStatus);
            }}
        >
            {isCreateMode ? intl.formatMessage(messages.createAndBegin) : intl.formatMessage(messages.save)}
        </Button>,
    ];

    const onTabChange = (activeKey: string) => {
        if (activeKey === APPROVAL_STAGES) return;

        if (formRef.current) {
            formRef.current
                .validateFields()
                .then(() => {
                    if (!validateCurrentStageUsers()) {
                        enableCurrentStageUsersErrorDisplay();

                        return;
                    }

                    setTabName(activeKey);
                })
                .catch(() => {
                    enableCurrentStageUsersErrorDisplay();
                });
        }
    };

    const onStageAdd = () => {
        if (formRef.current) {
            formRef.current
                .validateFields()
                .then(() => {
                    addNewStage();
                })
                .catch((e) => {
                    if (e?.errorFields?.length === 0) {
                        addNewStage();
                    }

                    enableCurrentStageUsersErrorDisplay();
                });
        }
    };

    const onStageMove = (indexOfClickedStage: number, isDirectionUp: boolean) => {
        if (!approval.stages) return;

        const stagesClone = cloneDeep(approval.stages) || [];
        const indexOfNeighbourStage = isDirectionUp ? indexOfClickedStage - 1 : indexOfClickedStage + 1;
        stagesClone[indexOfClickedStage] = approval.stages[indexOfNeighbourStage];
        stagesClone[indexOfNeighbourStage] = approval.stages[indexOfClickedStage];

        onChangeStages(stagesClone);
    };

    const onStageRemove = (id: string) => {
        const deletingStage: ApprovalStageDTO | undefined = approval.stages?.find((stage) => stage.id === id);
        const filteredStages: ApprovalStageDTO[] | undefined= approval.stages?.filter((stage) => stage.id !== id);
        dispatch(
            openDialog(DialogType.CONFIRMATION, {
                onSubmit: () => {
                    if (id === tabName) {
                        setTabName(GENERAL_SETTINGS);
                    }
                    onChangeStages(filteredStages);
                },
                title: intl.formatMessage(messages.deletingApprovalStage),
                question: intl.formatMessage(messages.deleteStageConfirmationDescription, {
                    name: deletingStage?.name || '',
                }),
                OKButtonText: intl.formatMessage(messages.delete),
            }),
        );
    };

    const addNewStage = () => {
        if (!validateCurrentStageUsers()) {
            enableCurrentStageUsersErrorDisplay();

            return;
        }

        const stagesClone: ApprovalStageDTO[] = cloneDeep(approval.stages) || [];
        const id = uuid();
        stagesClone.push({
            id,
            name: '',
            type: ApprovalStageDTOType.PARALLEL,
            changeVoteAllowed: true,
            rateOfSuccess: 100,
        });
        onChangeStages(stagesClone);
        setTabName(id);
    };

    const validateStages = (): boolean => {
        return !!(approval.stages && approval.stages.length > 0);
    };

    const enableStagesErrorDisplay = () => {
        if (!approval.stages) {
            onChangeStages([]);
        }  
    };

    const validateCurrentStageUsers = (): boolean => {
        if (tabName === GENERAL_SETTINGS) return true;

        const currentStage = approval.stages?.find((stage) => stage.id === tabName);

        return !!(currentStage?.approvalUsersDTO && currentStage?.approvalUsersDTO?.length > 0);
    };

    const enableCurrentStageUsersErrorDisplay = () => {
        const currentApprovalStage = approval.stages?.find((stage) => stage.id === tabName);
        if (currentApprovalStage && !currentApprovalStage.approvalUsersDTO) {
            const stageList = StagesBllService.changeCurrentStageUsersErrorDisplay(approval.stages, tabName);

            onChangeStages(stageList)
        }
    };

    const onSubmitApproval = (status: ApprovalDTOStatusEnum | undefined) => {
        const stages: ApprovalStageDTO[] | undefined = StagesBllService.trimStagesName(approval.stages);
        
        submitModifiedApproval(stages, status)
    };

    const onSubmit = (status?: ApprovalDTOStatusEnum | undefined) => {
        if (formRef.current) {
            formRef.current
                .validateFields()
                .then(() => {
                    if (!validateStages()) {
                        enableStagesErrorDisplay();

                        return;
                    }

                    if (!validateCurrentStageUsers()) {
                        enableCurrentStageUsersErrorDisplay();

                        return;
                    }
                    onSubmitApproval(status)
                })
                .catch(() => {
                    enableStagesErrorDisplay();
                    enableCurrentStageUsersErrorDisplay();
                });
        }
    };

    return (
        <Modal
            open={visible}
            width={900}
            onCancel={onCancel}
            title={title}
            footer={footer}
            className={theme.approvalDialog}
        >
            <Tabs tabPosition="left" className={theme.tabs} activeKey={tabName} onChange={onTabChange}>
                <Tabs.TabPane tab={intl.formatMessage(messages.generalSettings)} key={GENERAL_SETTINGS}>
                    {generalFormComponent}
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={
                        <div className={theme.stagesTabContainer}>
                            <div className={theme.stagesTabPane}>{intl.formatMessage(messages.approvalStages)}</div>
                            {(!isTemplate && (approval as ApprovalDTO).status === ApprovalDTOStatus.APPROVED) ||
                            (approval as ApprovalDTO).status === ApprovalDTOStatus.NOT_APPROVED ? null : (
                                <button type="button" className={theme.addApprovalStageBtn} onClick={onStageAdd}>
                                    <Icon spriteSymbol={icAddApprovalStage} className={theme.approvalCreateIcon} />
                                </button>
                            )}
                        </div>
                    }
                    key={APPROVAL_STAGES}
                />
                {approval.stages?.map((stage, index, arr) => {
                    const isNotMovable = !isCreateMode && stage.status;
                    const isNotMovableUp = isNotMovable || (!isNotMovable && index > 0 && arr[index - 1].status);
                    const isNotMovableDown =
                        isNotMovable || (!isNotMovable && index < arr.length - 1 && arr[index + 1].status);
                    const isNotDeletable =
                        !isCreateMode &&
                        (stage.status === ApprovalStageDTOStatus.APPROVED ||
                            stage.status === ApprovalStageDTOStatus.NOT_APPROVED);

                    return (
                        <Tabs.TabPane
                            tab={
                                <StageTab
                                    onStageMove={onStageMove}
                                    onStageRemove={onStageRemove}
                                    stage={stage}
                                    index={index}
                                    arr={arr}
                                    isNotMovableUp={isNotMovableUp}
                                    isNotMovableDown={isNotMovableDown}
                                    isNotDeletable={isNotDeletable}
                                />
                            }
                            key={stage.id}
                        >
                            {isTemplate ? (
                                <ApprovalTemplatesStagesTab
                                    approval={approval as ApprovalTemplateDTO}
                                    formRef={formRef}
                                    isCreateMode={isCreateMode}
                                    stage={stage}
                                />
                            ) : (
                                <ApprovalStagesTab
                                    approval={approval as ApprovalDTO}
                                    formRef={formRef}
                                    isCreateMode={isCreateMode}
                                    stage={stage}
                                />
                            )}
                        </Tabs.TabPane>
                    );
                })}
            </Tabs>
        </Modal>
    );
};
