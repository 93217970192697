import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import messages from './RenameObjectDialog.messages';
import theme from './RenameObjectDialog.scss';
import { TRenameObjectDialogProps } from './RenameObjectDialog.types';

const objectNameValidator = (value: string = '') => value.trim().length <= 100;

const antdObjectNameValidator = (_, val: string) => (objectNameValidator(val) ? Promise.resolve() : Promise.reject());

export function RenameObjectDialog(props: TRenameObjectDialogProps) {
    const { visible, onClose, onSubmit, objectDefinition, serverId, value } = props;
    const [title, setTitle] = useState(value);
    const intl = useIntl();
    const footer = (
        <div>
            <Button data-test="rename-window_cancel_button" key="cancel" size="large" onClick={onClose}>
                {intl.formatMessage(messages.cancel)}
            </Button>

            <Button
                data-test="rename-window_rename_button"
                disabled={!title || !objectNameValidator(title)}
                key="ok"
                size="large"
                type="primary"
                onClick={() => onSubmit(serverId, objectDefinition, title)}
            >
                {intl.formatMessage(messages.submit)}
            </Button>
        </div>
    );

    return (
        <>
            <Dialog footer={footer} onCancel={onClose} open={visible} width="480px">
                <Form autoComplete="off" className={theme.formContainer}>
                    <Form.Item
                        name="object-title"
                        label={intl.formatMessage(messages.label)}
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage(messages.errorMessage),
                            },
                            {
                                required: true,
                                message: intl.formatMessage(messages.excessLengthMessage),
                                validator: antdObjectNameValidator,
                            },
                        ]}
                        initialValue={title}
                    >
                        <Input
                            data-test="rename-window_element-name_input"
                            disabled={false}
                            placeholder={intl.formatMessage(messages.placeholder)}
                            value={title}
                            name="object-title"
                            autoFocus
                            onChange={(e) => setTitle(e.target.value.trim())}
                        />
                    </Form.Item>
                </Form>
            </Dialog>
        </>
    );
}
