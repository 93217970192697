// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SymbolsTabDialog__creationTitle__yQzo0{height:450px}.SymbolsTabDialog__tableLabel__mjh1A{text-transform:none}.SymbolsTabDialog__tableCheckbox__E83Dh{padding-bottom:4px}.SymbolsTabDialog__tableRow__geOLT{border-bottom:1px solid rgba(222,222,222,.55)}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/Presets/ModelType/Dialogs/SymbolsTabDialog.scss"],"names":[],"mappings":"AAAA,wCACI,YAAA,CAGJ,qCACE,mBAAA,CAGF,wCACE,kBAAA,CAGF,mCACE,6CAAA","sourcesContent":[".creationTitle {\r\n    height: 450px\r\n}\r\n\r\n.tableLabel {\r\n  text-transform: none;\r\n}\r\n\r\n.tableCheckbox {\r\n  padding-bottom: 4px;\r\n}\r\n\r\n.tableRow {\r\n  border-bottom: 1px solid rgba(222, 222, 222, 0.55);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"creationTitle": "SymbolsTabDialog__creationTitle__yQzo0",
	"tableLabel": "SymbolsTabDialog__tableLabel__mjh1A",
	"tableCheckbox": "SymbolsTabDialog__tableCheckbox__E83Dh",
	"tableRow": "SymbolsTabDialog__tableRow__geOLT"
};
export default ___CSS_LOADER_EXPORT___;
