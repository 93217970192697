import React from 'react';
import { Button } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { TDialogProps } from '../../UIKit/components/Dialog/Dialog.types';
import messages from '../messages/PsdTableDeleteConfirmationDialog.messages';
import icWarning from '../../../resources/icons/ic-warning.svg';
import theme from './PsdTableDeleteConfirmationDialog.scss';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import { TPsdTableDeleteConfirmationDialogProps } from '../PsdTableDeleteConfirmationDialog.types';

type TPsdTableDeleteConfirmationDialogState = {
    isPreviousAnswerYes: boolean;
};

class RawPsdTableDeleteConfirmationDialog extends React.Component<
    TPsdTableDeleteConfirmationDialogProps,
    TPsdTableDeleteConfirmationDialogState
> {
    state = { isPreviousAnswerYes: false };

    render() {
        const { visible, isRow, onClose } = this.props;
        const { isPreviousAnswerYes } = this.state;
        const title = messages.title;
        let text;
        if (isPreviousAnswerYes) {
            text = isRow ? messages.removeChildrenRowText : messages.removeChildrenColumnText;
        } else {
            text = isRow ? messages.removeRowText : messages.removeColumnText;
        }
        const submitText = messages.submitText;

        const dialogProps: Partial<TDialogProps> = {
            visible,
            onCancel: onClose,
            title: <FormattedMessage {...title} />,
            onOk: this.handleSubmit,
        };

        const footer = [
            <Button key="cancel" size="large" onClick={this.handleCancel}>
                <div className={theme.cancel}>
                    <Icon spriteSymbol={icWarning} className={theme.cancelIcon} />
                    <FormattedMessage {...messages.cancelText} />
                </div>
            </Button>,
            <Button danger key="ok" size="large" type="primary" onClick={this.handleSubmit}>
                <FormattedMessage {...submitText} />
            </Button>,
        ];

        return (
            <Dialog {...dialogProps} footer={footer} className={theme.dialog}>
                <div className={theme.content}>
                    <FormattedMessage {...text} />
                </div>
            </Dialog>
        );
    }

    handleSubmit = () => {
        const { onSubmitRow, onSubmitColumn, cellId, isChildren, onCancel, isRow } = this.props;
        const { isPreviousAnswerYes } = this.state;
        const onSubmit = isRow ? onSubmitRow : onSubmitColumn;
        if (!isChildren) {
            if (onSubmit) {
                onSubmit(cellId);
            }
        } else if (isChildren && isPreviousAnswerYes) {
            if (onSubmit) {
                onSubmit(cellId);
            }
        } else if (isChildren && !isPreviousAnswerYes) {
            this.setState({ isPreviousAnswerYes: true });
        } else if (onCancel) {
            onCancel();
        }
    };

    handleCancel = () => {
        const { onCancel } = this.props;
        if (onCancel) {
            onCancel();
        }
    };
}

const IntlComponent = injectIntl(RawPsdTableDeleteConfirmationDialog);

export { IntlComponent as PsdTableDeleteConfirmationDialog };
