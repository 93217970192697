import type {
    IComplexSymbol,
    ISymbolPreviewGeometry,
    TComplexSymbolCreationProps,
    TRootCellValue,
} from './ComplexSymbol.class.types';
import type { IComplexSymbolSerializer } from './serializers/ComplexSymbolSerializer.class.types';
import type { SymbolTypeId, SequenceSymbolTypeId } from './ComplexSymbol.constants';
import type { ObjectDefinitionImpl, ObjectInstanceImpl } from '@/models/bpm/bpm-model-impl';
import type { BPMMxGraph } from '@/mxgraph/bpmgraph';
import type { ObjectInstance, Symbol } from '@/serverapi/api';
import type { MxCell } from 'MxGraph';
import { objectDefinitionService } from '@/services/ObjectDefinitionService';
import { isSystemStyle } from '@/services/SymbolsService';
import { isSymbolEditable } from './sideEffects';
import { cellsTreeWalkerDown } from '../utils';
import { isSequenceDiagramCell } from './Sequence/sequence.utils';

export abstract class ComplexSymbol implements IComplexSymbol {
    protected abstract serializer: IComplexSymbolSerializer;
    abstract complexSymbolTypeId: SymbolTypeId | SequenceSymbolTypeId;
    public abstract addToGraph(): MxCell;

    protected rootCell: MxCell;
    protected rootCellValue: TRootCellValue;
    protected graph: BPMMxGraph;
    protected customProps: any;
    private managedCells: MxCell[] = [];

    constructor({ graph, rootCellValue, customProps }: TComplexSymbolCreationProps) {
        this.graph = graph;
        this.rootCellValue = rootCellValue;
        this.customProps = customProps;
    }

    public getRootCell(): MxCell {
        return this.rootCell;
    }

    public getLabelCell(): MxCell | undefined {
        return;
    }

    public serialize() {
        return this.serializer.serialize();
    }

    protected addManagedCells(cells: MxCell[]) {
        this.managedCells.push(...cells);
    }

    public getManagedCells(): MxCell[] {
        return this.managedCells;
    }

    public getSymbolTypeId(): SymbolTypeId | SequenceSymbolTypeId {
        return this.complexSymbolTypeId;
    }

    protected afterCreate(rootCell: MxCell): MxCell {
        rootCell.setValue(this.rootCellValue);
        rootCell.complexSymbolRef = this;
        rootCell.setConnectable(this.isConnectable);

        // TODO сделать cellsTreeWalkerDown для всех символов или вынести в класс сиквенс символов
        if (isSequenceDiagramCell(rootCell)) {
            cellsTreeWalkerDown([rootCell], (cell: MxCell) => {
                cell.complexSymbolRef = this;
            });
        } else {
            rootCell.children?.forEach((child) => {
                if (!child.complexSymbolRef) {
                    child.complexSymbolRef = this;
                }
            });
        }

        this.rootCell = rootCell;
        this.serializer.afterCreate(rootCell);
        this.addManagedCells([rootCell]);
        this.graph.refresh(rootCell);

        return rootCell;
    }

    protected isTitleHidden(): boolean {
        return false;
    }

    protected isRootCell(cell: MxCell): boolean {
        return this.rootCell.getId() === cell.getId();
    }

    public get isUseRenameDialog(): boolean {
        return true;
    }

    public get isConnectable(): boolean {
        return false;
    }

    public redraw() {}

    public convertValueToString(cell: MxCell): string {
        const value: ObjectInstanceImpl | undefined = cell.getValue();

        if (this.isTitleHidden()) return '';
        if (value?.objectDefinitionId) {
            const { serverId, repositoryId } = this.graph.id;
            const objectDefinition = objectDefinitionService().getObjectDefinition({
                serverId,
                repositoryId,
                id: value.objectDefinitionId,
            });

            if (objectDefinition) {
                return objectDefinition.name;
            }
        }

        return '';
    }

    public getCopyableCell(): MxCell | null {
        return null;
    }

    public isCellDisabled(): boolean {
        const { objectDefinitionId: id, symbolId } = this.rootCellValue;

        if (!id) {
            return true;
        }

        return isSystemStyle(this.rootCell.getStyle()) || !isSymbolEditable(symbolId, { ...this.graph.id, id });
    }

    public isCellMovable(cell: MxCell): boolean {
        return true;
    }

    public isCellStyleEditable(cell: MxCell): boolean {
        return false;
    }

    public hasEditableLabel(): boolean {
        return false;
    }

    public setDefaultStyle(): void {
        return;
    }

    public setSymbolForCell(symbol: Symbol): void {
        return;
    }

    public getCellForRename(cell: MxCell): MxCell {
        return cell;
    }

    public getSymbolPreviewGeometry(symbol: Symbol): ISymbolPreviewGeometry {
        return {
            labelHeight: symbol.labelHeight || this.rootCell.geometry?.height,
            labelWidth: symbol.labelWidth || this.rootCell.geometry?.width,
            labelXOffset: symbol.labelXOffset,
            labelYOffset: symbol.labelYOffset,
        };
    }

    public setObjectToCell(cell: MxCell, object: ObjectDefinitionImpl): void {
        const cellValue: ObjectInstance = cell.getValue();

        cell.setValue({ ...cellValue, objectDefinitionId: object.nodeId.id });
    }
}
