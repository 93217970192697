// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CommentTooltipContainer__fullTooltip__TZDCO{overflow-y:auto}", "",{"version":3,"sources":["webpack://./src/modules/Comments/components/FullCommentToolTip.component/CommentTooltipContainer.scss"],"names":[],"mappings":"AAAA,6CACI,eAAA","sourcesContent":[".fullTooltip {\r\n    overflow-y: auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullTooltip": "CommentTooltipContainer__fullTooltip__TZDCO"
};
export default ___CSS_LOADER_EXPORT___;
