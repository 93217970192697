import React, { FC } from 'react';
import { Dialog } from '../../../../UIKit/components/Dialog/Dialog.component';
import { Radio, Table } from 'antd';
import theme from './ExistingDefinitionSelectView.scss';
import messages from '../SelectExistingDefinitionDialogs.messages';
import { useIntl } from 'react-intl';
import { TExistingDefinitionSelectViewProps } from './ExistingDefinitionSelectView.types';

export const ExistingDefinitionSelectView: FC<TExistingDefinitionSelectViewProps> = ({
    visible,
    title,
    text,
    radioProps,
    tableProps,
    onOk,
    onCancel,
}) => {
    const intl = useIntl();

    return (
        <Dialog
            open={visible}
            onOk={onOk}
            onCancel={onCancel}
            title={title}
            width="620px"
            okText={intl.formatMessage(messages.confirmButton)}
            cancelText={intl.formatMessage(messages.cancelButton)}
        >
            <div className={theme.container} data-test="existing_object_dialog">
                <div className={theme.message}>{text}</div>
                <div>
                    <Radio.Group value={radioProps.selectedStrategy} onChange={radioProps.handleSelectStrategy}>
                        {radioProps.data.map((radio) => (
                            <Radio
                                className={theme.radio}
                                value={radio.value}
                                data-test={radio.dataTest}
                                key={`select-strategy-${radio.value}`}
                            >
                                {radio.text}
                                {radio.warningText && (
                                    <span style={{ color: 'red' }}>&nbsp; ({radio.warningText})</span>
                                )}
                            </Radio>
                        ))}
                    </Radio.Group>
                </div>
                {tableProps.dataSource && (
                    <div className={tableProps.disabled ? theme.disabledContainer : ''}>
                        <div className={theme.tableContainer}>
                            <Table
                                className={theme.table}
                                rowKey={(record) => record.key}
                                onRow={tableProps.onRow}
                                dataSource={tableProps.dataSource}
                                bordered
                                pagination={false}
                                size="middle"
                                columns={tableProps.columns}
                                scroll={{
                                    y: 'max-content',
                                    x: 'max-content',
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </Dialog>
    );
};
