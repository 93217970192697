import WikiEditor from '../components/WikiEditor.component';
import { connect } from 'react-redux';
import { loadComments } from '@/actions/comments.actions';
import { wikiChangeRequest } from '@/actions/entities/wiki.actions';
import { IWikiNode } from '@/models/bpm/bpm-model-impl.types';
import { PictureSymbolConstants } from '@/models/pictureSymbolConstants';
import { TRootState } from '@/reducers/root.reducer.types';
import { ServerSelectors } from '@/selectors/entities/server.selectors';
import { CommentsSelectors } from '@/selectors/comments.selectors';
import { compareNodeIds } from '@/utils/nodeId.utils';

const mapStateToProps = (state: TRootState, ownProps) => {
    const content = ownProps.tab.content as IWikiNode;
    const server = ServerSelectors.server(content.serverId)(state);
    const baseUrl = server?.url ? `${server.url}/${PictureSymbolConstants.DOWNLOAD_LINK}` : '';
    const { zoomLevel } = ownProps.tab.params;
    const commentsEnabledSchemesIds = CommentsSelectors.commentsEnabledSchemesIds()(state);
    const isShowCommentsPanel = commentsEnabledSchemesIds.some((id) => compareNodeIds(id, ownProps.tab.nodeId));
    const { nodeId } = ownProps.tab;

    return {
        id: 'WikiModel',
        value: content?.source,
        zoomLevel,
        baseUrl,
        isShowCommentsPanel,
        nodeId
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const { nodeId } = ownProps.tab;

    return {
        onRendered: () => {
            dispatch(loadComments(nodeId));
        },
        onChange: (source: string) => {
            dispatch(wikiChangeRequest(nodeId, source));
        },

        handlers: {
            imageLinkMapper: (src, baseUrl) => (src?.startsWith('data:image/') ? src : `${baseUrl}/${src}/`),
            readFromClipboard: (callback) => {
                navigator.clipboard.readText().then(callback);
            },
            copyToClipboard: (text: string) => {
                navigator.clipboard.writeText(text);
            },
        },
    };
};

const mergeProps = (ownProps, mapProps, dispatchProps) => {
    const { handlers } = mapProps;
    const { baseUrl } = ownProps;

    const newMapProps = {
        ...mapProps,
        handlers: {
            ...handlers,
            imageLinkMapper: (src) => {
                return handlers.imageLinkMapper(src, baseUrl);
            },
        },
    };

    return { ...ownProps, ...newMapProps, ...dispatchProps };
};

export const WikiEditorContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(WikiEditor);
