export const fonts = [
    {
        value: 'Arial',
        label: 'Arial',
        style: {
            fontFamily: 'Arial, Helvetica, sans-serif',
        },
    },
    {
        value: 'Verdana',
        label: 'Verdana',
        style: {
            fontFamily: 'Verdana, Geneva, sans-serif',
        },
    },
    {
        value: 'Times New Roman',
        label: 'Times New Roman',
        style: {
            fontFamily: ' TimesNewRoman,"Times New Roman", Times, Baskerville, Georgia, serif',
        },
    },
    {
        value: 'Garamond',
        label: 'Garamond',
        style: {
            fontFamily: 'Garamond, Baskerville, "Baskerville Old Face", "Hoefler Text", "Times New Roman",serif',
        },
    },
    {
        value: 'Comic Sans MS',
        label: 'Comic Sans MS',
        style: {
            fontFamily: '"Comic Sans MS", "Comic Sans", cursive',
        },
    },
    {
        value: 'Courier New',
        label: 'Courier New',
        style: {
            fontFamily: '"Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace',
        },
    },
    {
        value: 'Georgia',
        label: 'Georgia',
        style: {
            fontFamily: 'Georgia, Times, "Times New Roman", serif',
        },
    },
    {
        value: 'Lucida Console',
        label: 'Lucida Console',
        style: {
            fontFamily: '"Lucida Console", "Lucida Sans Typewriter", monaco, "Bitstream Vera Sans Mono", monospace',
        },
    },
    {
        value: 'Tahoma',
        label: 'Tahoma',
        style: {
            fontFamily: 'Tahoma, Verdana, Segoe, sans-serif',
        },
    },
];

export const APP_PREFIX = 'bpm://';
