// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExtendableSelect__relativeTooltipContainer__KnTem{position:relative;display:inline}", "",{"version":3,"sources":["webpack://./src/modules/MainMenu/components/ExtendableSelect/ExtendableSelect.scss"],"names":[],"mappings":"AAAA,mDACI,iBAAA,CACA,cAAA","sourcesContent":[".relativeTooltipContainer {\r\n    position: relative;\r\n    display: inline;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"relativeTooltipContainer": "ExtendableSelect__relativeTooltipContainer__KnTem"
};
export default ___CSS_LOADER_EXPORT___;
