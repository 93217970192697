import { OPEN_COMPARE_MODELS_TAB, OPEN_GRAPHICAL_COMPARISON_TAB } from '@/actionsTypes/compareModels.actionTypes';
import { put, takeEvery } from 'redux-saga/effects';
import { getContentLoadingPageTab } from '../utils';
import { workspaceAddTab, workspaceRemoveTab, workspaceRemoveTabRequest } from '@/actions/tabs.actions';
import { TWorkspaceTab } from '@/models/tab.types';
import { WorkSpaceTabTypes } from '@/modules/Workspace/WorkSpaceTabTypesEnum';
import { EditorMode } from '@/models/editorMode';
import { defaultWorkspaceTabActions } from '../../models/tab';
import { TOpenCompareModelsTab, TOpenGraphicalComparisonTab } from '@/actions/compareModels.actions.types';
import { TreeDaoService } from '@/services/dao/TreeDaoService';
import { CompareResponse } from '@/serverapi/api';
import { v4 as uuid } from 'uuid';
import { LocalesService } from '@/services/LocalesService';
import messages from '../../modules/CompareModels/CompareModels.messages';
import { ModelDaoService } from '@/services/dao/ModelDaoService';

function* openCompareModelsTab({ payload: { comparedModelNodeId1, comparedModelNodeId2 } }: TOpenCompareModelsTab) {
    const intl = LocalesService.useIntl();
    const contentLoadingPageTab = yield getContentLoadingPageTab(comparedModelNodeId1);
    try {
        yield put(workspaceAddTab(contentLoadingPageTab));
        const { compareResults }: CompareResponse = yield TreeDaoService.compareModels(
            comparedModelNodeId1,
            comparedModelNodeId2,
        );

        const compareModelsTab: TWorkspaceTab = <TWorkspaceTab>{
            title: intl.formatMessage(messages.compareModels),
            type: WorkSpaceTabTypes.COMPARE_MODELS,
            nodeId: {
                ...comparedModelNodeId1,
                id: uuid(),
            },
            mode: EditorMode.Read,
            actions: {
                ...defaultWorkspaceTabActions,
            },
            params: {
                compareResults,
                comparedModelNodeId1,
                comparedModelNodeId2,
            },
        };
        yield put(workspaceRemoveTab(contentLoadingPageTab));
        yield put(workspaceAddTab(compareModelsTab));
    } catch (e) {
        yield put(workspaceRemoveTabRequest(contentLoadingPageTab));
        throw e;
    }
}

function* openGraphicalComparisonTab({
    payload: { comparedModelNodeId1, comparedModelNodeId2 },
}: TOpenGraphicalComparisonTab) {
    const intl = LocalesService.useIntl();
    const contentLoadingPageTab = yield getContentLoadingPageTab(comparedModelNodeId1);
    try {
        yield put(workspaceAddTab(contentLoadingPageTab));
        const model1Png: { url: string } = yield ModelDaoService.getPng(comparedModelNodeId1);
        const model2Png: { url: string } = yield ModelDaoService.getPng(comparedModelNodeId2);
        const compareModelsTab: TWorkspaceTab = <TWorkspaceTab>{
            title: intl.formatMessage(messages.graphicalComparison),
            type: WorkSpaceTabTypes.GRAPHICAL_COMPARISON,
            nodeId: {
                ...comparedModelNodeId1,
                id: uuid(),
            },
            mode: EditorMode.Read,
            actions: {
                ...defaultWorkspaceTabActions,
            },
            params: { model1Png, model2Png },
        };
        yield put(workspaceRemoveTab(contentLoadingPageTab));
        yield put(workspaceAddTab(compareModelsTab));
    } catch (e) {
        yield put(workspaceRemoveTabRequest(contentLoadingPageTab));
        throw e;
    }
}

export function* compareModelsSaga() {
    yield takeEvery(OPEN_COMPARE_MODELS_TAB, openCompareModelsTab);
    yield takeEvery(OPEN_GRAPHICAL_COMPARISON_TAB, openGraphicalComparisonTab);
}
