import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import messages from './FolderDialog.messages';
import { useIntl } from 'react-intl';
import { FolderType, Node, NodeId, PresetImage } from '../../serverapi/api';
import { CommonCreateDialog } from '../CommonCreateDialog/CommonCreateDialog.component';
import { closeDialog } from '../../actions/dialogs.actions';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import { folderDialogSubmit } from '../../actions/folderDialog.actions';
import { TPayloadFolderDialogSubmitDataAction } from '../../actions/folderDialog.actions.types';
import { TreeItemType } from '../Tree/models/tree';
import { getCreatableFolderTypes } from '../../services/bll/FolderTypeBLLService';
import { FolderTypeSelectors } from '../../selectors/folderType.selectors';
import { TreeSelectors } from '../../selectors/tree.selectors';
import { LocalesService } from '../../services/LocalesService';
import { PresetImageSelectors } from '../../selectors/presetSettings/presetImage.selectors';

type TFolderDialogProps = {
    parentNodeId: NodeId;
    type: TreeItemType;
    extraProperties?: Node;
};

export const FolderDialog: FC<TFolderDialogProps> = (props) => {
    const { parentNodeId, type, extraProperties } = props;
    const { serverId } = parentNodeId;
    const intl = useIntl();
    const dispatch = useDispatch();
    const presetId: string = useSelector(TreeSelectors.presetById(parentNodeId));
    const allFolderTypes: FolderType[] = useSelector(FolderTypeSelectors.listByPresetId({ serverId, presetId }));
    const parentFolderType: FolderType | undefined = useSelector(
        FolderTypeSelectors.byNodeId({ nodeId: parentNodeId, presetId }),
    );
    const folderTypes: FolderType[] = getCreatableFolderTypes(
        allFolderTypes,
        parentFolderType,
        LocalesService.getLocale(),
    );
    const presetImages: PresetImage[] = useSelector(PresetImageSelectors.listAllByPreset(serverId, presetId));

    return (
        <CommonCreateDialog
            type={type}
            presetImages={presetImages}
            title={intl.formatMessage(messages.folderFormName)}
            placeholder={intl.formatMessage(messages.folderNamePlaceholder)}
            cancelText={
                <span data-test="create-element_folder_cancel-button">
                    {intl.formatMessage(messages.folderFormDeclineButton)}
                </span>
            }
            okText={
                <span data-test="create-element_folder_save-button">
                    {intl.formatMessage(messages.folderFormConfirmButton)}
                </span>
            }
            requiredErrorMsg={intl.formatMessage(messages.folderNameRequiredError)}
            folderTypes={folderTypes}
            labelName={intl.formatMessage(messages.folderNameLabel)}
            labelFolderType={intl.formatMessage(messages.folderTypeLabel)}
            inputDataTestId="folder-form_folder-name-input"
            onSubmit={(name: string, folderTypeId: string) => {
                dispatch(
                    folderDialogSubmit({
                        folderName: name,
                        folderTypeId,
                        parentNodeId,
                        type,
                        extraProperties,
                    } as TPayloadFolderDialogSubmitDataAction),
                );
            }}
            onClose={() => dispatch(closeDialog(DialogType.FOLDER_DIALOG))}
        />
    );
};
