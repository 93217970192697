import { LocalesService } from '../services/LocalesService';
import messages from '../modules/ObjectPropertiesDialog/messages/ScriptContext.messages';
import { AllowedScriptContext, AllowedScriptModelSymbols, InternationalString, NodeId } from '../serverapi/api';
import {
    CTreeNodeKey,
    IDataNodeWithGrops,
    MAX_AMOUNT_OF_ITEMS_IN_TOOLTIP,
    scriptContextTypes,
    TModelSymbolNames,
} from '../modules/ObjectPropertiesDialog/components/ScriptContext/scriptContext.types';
import { uniq } from 'lodash-es';
import { Tooltip, TreeDataNode } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { scriptContextItemNamesSelectors } from '../../src/selectors/scriptContext.selectors';
import theme from '../modules/ObjectPropertiesDialog/components/ScriptContext/ScriptContextComponent.scss';
import { v4 as uuid } from 'uuid';
import { TreeItemType } from '../../src/modules/Tree/models/tree';
import TreeItemIcon from '../../src/modules/Tree/components/TreeItem/TreeItemIcon.component';

const getMessageEvery = (): JSX.Element => {
    const intl = LocalesService.useIntl();

    return (
        <Tooltip
            title={intl.formatMessage(messages.allContextIsChosen)}
            overlayClassName={theme.messageContainer}
            placement="left"
        >
            {intl.formatMessage(messages.every)}
        </Tooltip>
    );
};

const getMessageNotChosen = (): JSX.Element => {
    const intl = LocalesService.useIntl();

    return (
        <Tooltip
            title={intl.formatMessage(messages.contextNotChosen)}
            overlayClassName={theme.messageContainer}
            placement="left"
        >
            {intl.formatMessage(messages.dontCheck)}
        </Tooltip>
    );
};

const getMessageWithToolTip = (types: (string | InternationalString)[]): JSX.Element => {
    const locale = LocalesService.getLocale();
    const intl = LocalesService.useIntl();
    const typeNames = [...types];
    if (typeNames.length > MAX_AMOUNT_OF_ITEMS_IN_TOOLTIP) {
        typeNames.length = MAX_AMOUNT_OF_ITEMS_IN_TOOLTIP;
    }

    return (
        <ul className={theme.list}>
            {typeNames.map((typeName) => {
                return (
                    <li className={typeof typeName === 'string' ? theme.listItemRed : theme.listItemUsual} key={uuid()}>
                        <div className={theme.messageContainer}>
                            {LocalesService.internationalStringToString(typeName as InternationalString, locale) ||
                                typeName.toString()}
                        </div>
                    </li>
                );
            })}
            {types.length > MAX_AMOUNT_OF_ITEMS_IN_TOOLTIP && (
                <div className={theme.messageContainer}>
                    {intl.formatMessage(messages.andMore, { amount: types.length - MAX_AMOUNT_OF_ITEMS_IN_TOOLTIP })}
                </div>
            )}
        </ul>
    );
};

const getChosenModelsTypeNames = (allowedModelTypeIds: string[]): JSX.Element => {
    const modelTypeNames = useSelector(scriptContextItemNamesSelectors.getModelTypesNames(allowedModelTypeIds));

    return getMessageWithToolTip(modelTypeNames);
};

const getChosenObjectTypeNames = (allowedObjectTypeIds: string[]): JSX.Element => {
    const objectTypeNames = useSelector(scriptContextItemNamesSelectors.getObjectTypesNames(allowedObjectTypeIds));

    return getMessageWithToolTip(objectTypeNames);
};

const getChosenEdgeTypeNames = (allowedEdgeTypeIds: string[]): JSX.Element => {
    const edgeTypeNames = useSelector(scriptContextItemNamesSelectors.getEdgeTypesNames(allowedEdgeTypeIds));

    return getMessageWithToolTip(edgeTypeNames);
};

const getChosenFolderTypeNames = (allowedFolderTypeIds: string[]): JSX.Element => {
    const folderTypeNames = useSelector(scriptContextItemNamesSelectors.getFolderTypesNames(allowedFolderTypeIds));

    return getMessageWithToolTip(folderTypeNames);
};

const getChosenSymbolNames = (allowedSymbolTypeIds: string[]): JSX.Element => {
    const symbolNames = useSelector(scriptContextItemNamesSelectors.getSymbolTypesNames(allowedSymbolTypeIds));

    return getMessageWithToolTip(symbolNames);
};

export const getModelSymbolNamesByMaxAmount = (modelSymbolNames: TModelSymbolNames[]): TModelSymbolNames[] => {
    const symbolsAmount: number = modelSymbolNames.map((modelSymbolName) => modelSymbolName.symbolNames).flat().length;

    if (symbolsAmount < MAX_AMOUNT_OF_ITEMS_IN_TOOLTIP) return modelSymbolNames;
    let syms: number = 0;
    const modelSymbolNamesByModels = modelSymbolNames.filter((modelSymbolName) => {
        if (syms >= MAX_AMOUNT_OF_ITEMS_IN_TOOLTIP) {
            return false;
        }
        syms = syms + 1 + modelSymbolName.symbolNames.length;

        return true;
    });
    const modelSymbolNamesByMaxAmount = [...modelSymbolNamesByModels];
    const lastItem = modelSymbolNamesByMaxAmount[modelSymbolNamesByMaxAmount.length - 1];
    modelSymbolNamesByMaxAmount.pop();
    lastItem.symbolNames.length =
        MAX_AMOUNT_OF_ITEMS_IN_TOOLTIP -
        modelSymbolNamesByMaxAmount.length -
        1 -
        modelSymbolNamesByMaxAmount.map((name) => name.symbolNames).flat().length;
    modelSymbolNamesByMaxAmount.push(lastItem);

    return modelSymbolNamesByMaxAmount;
};

const getChosenModelSymbolsNames = (allowedModelSymbols: AllowedScriptModelSymbols[]): JSX.Element => {
    const locale = LocalesService.getLocale();
    const intl = LocalesService.useIntl();
    const allSymbolIds: string[] = allowedModelSymbols.map((modelSymbol) => modelSymbol.symbolIds).flat();

    const modelSymbolNames = useSelector(scriptContextItemNamesSelectors.getModelSymbolTypesNames(allowedModelSymbols));
    const modelSymbolNamesByMaxAmount = getModelSymbolNamesByMaxAmount(modelSymbolNames);
    const synbolINameByMax = modelSymbolNamesByMaxAmount.map((name) => name.symbolNames).flat();

    return (
        <ul className={theme.firstLevelList}>
            {modelSymbolNamesByMaxAmount.map((modelSymbolName) => {
                return (
                    <li
                        className={
                            typeof modelSymbolName.modelName === 'string' ? theme.listItemRed : theme.listItemUsual
                        }
                        key={uuid()}
                    >
                        <div className={theme.messageContainer}>
                            <TreeItemIcon {...{ nodeId: {} as NodeId, type: TreeItemType.Model }} />
                            <span className={theme.stringContainer}>
                                {LocalesService.internationalStringToString(
                                    modelSymbolName.modelName as InternationalString,
                                    locale,
                                ) || modelSymbolName.modelName.toString()}
                            </span>
                        </div>
                        <ul className={theme.secondLevelList}>
                            {modelSymbolName.symbolNames.map((symbolName) => {
                                return (
                                    <li
                                        className={
                                            typeof symbolName === 'string' ? theme.listItemRed : theme.listItemUsual
                                        }
                                        key={uuid()}
                                    >
                                        <div className={theme.messageContainer}>
                                            {LocalesService.internationalStringToString(
                                                symbolName as InternationalString,
                                                locale,
                                            ) || symbolName.toString()}
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                );
            })}
            {allSymbolIds.length > MAX_AMOUNT_OF_ITEMS_IN_TOOLTIP && (
                <div className={theme.secondLevelList}>
                    {intl.formatMessage(messages.andMore, { amount: allSymbolIds.length - synbolINameByMax.length })}
                </div>
            )}
        </ul>
    );
};

const getChosenNodesNames = (allowedNodeIds: NodeId[]): JSX.Element => {
    const nodeNames = useSelector(scriptContextItemNamesSelectors.getNodeNames(allowedNodeIds));

    return getMessageWithToolTip(nodeNames);
};

export const renderOnModelsTypeRow = (context: AllowedScriptContext | undefined): JSX.Element => {
    const intl = LocalesService.useIntl();
    const title = getChosenModelsTypeNames(context?.allowedModelTypeIds || []);
    if (context && (context.allowAll || context.allowAllModels)) {
        return getMessageEvery();
    }

    return context?.allowedModelTypeIds?.length ? (
        <Tooltip title={title} placement="leftTop">
            {intl.formatMessage(messages.amountOfContextTypesItems, { amount: context.allowedModelTypeIds.length })}
        </Tooltip>
    ) : (
        getMessageNotChosen()
    );
};

export const renderOnFilesRow = (context: AllowedScriptContext | undefined): JSX.Element => {
    if (context && (context.allowAll || context.allowAllFiles)) {
        return getMessageEvery();
    }

    return getMessageNotChosen();
};

export const renderOnDBRow = (context: AllowedScriptContext | undefined): JSX.Element => {
    if (context && (context.allowAll || context.allowAllDBs)) {
        return getMessageEvery();
    }

    return getMessageNotChosen();
};

export const renderOnScriptRow = (context: AllowedScriptContext | undefined): JSX.Element => {
    if (context && (context.allowAll || context.allowAllScripts)) {
        return getMessageEvery();
    }

    return getMessageNotChosen();
};

export const renderOnFolderRow = (context: AllowedScriptContext | undefined): JSX.Element => {
    const intl = LocalesService.useIntl();
    const title = getChosenFolderTypeNames(context?.allowedFolderTypeIds || []);
    if (context && (context.allowAll || context.allowAllFolders)) {
        return getMessageEvery();
    }

    return context?.allowedFolderTypeIds?.length ? (
        <Tooltip title={title} placement="leftTop">
            {intl.formatMessage(messages.amountOfContextTypesItems, { amount: context.allowedFolderTypeIds.length })}
        </Tooltip>
    ) : (
        getMessageNotChosen()
    );
};

export const renderOnObjectRow = (context: AllowedScriptContext | undefined): JSX.Element => {
    const intl = LocalesService.useIntl();
    const title = getChosenObjectTypeNames(context?.allowedObjectTypeIds || []);
    if (context && (context.allowAll || context.allowAllObjects)) {
        return getMessageEvery();
    }

    return context?.allowedObjectTypeIds?.length ? (
        <Tooltip title={title} placement="leftTop">
            {intl.formatMessage(messages.amountOfContextTypesItems, { amount: context.allowedObjectTypeIds.length })}
        </Tooltip>
    ) : (
        getMessageNotChosen()
    );
};

export const renderOnEdgeRow = (context: AllowedScriptContext | undefined): JSX.Element => {
    const intl = LocalesService.useIntl();
    const title = getChosenEdgeTypeNames(context?.allowedEdgeTypeIds || []);
    if (context && (context.allowAll || context.allowAllEdges)) {
        return getMessageEvery();
    }

    return context?.allowedEdgeTypeIds?.length ? (
        <Tooltip title={title} placement="leftTop">
            {intl.formatMessage(messages.amountOfContextTypesItems, { amount: context.allowedEdgeTypeIds.length })}
        </Tooltip>
    ) : (
        getMessageNotChosen()
    );
};

export const renderOnObjectInstancesRow = (context: AllowedScriptContext | undefined): JSX.Element => {
    const intl = LocalesService.useIntl();
    const title = getChosenSymbolNames(context?.allowedSymbolTypeIds || []);
    if (context && (context.allowAll || context.allowAllSymbols)) {
        return getMessageEvery();
    }

    return context?.allowedSymbolTypeIds?.length ? (
        <Tooltip title={title} placement="leftTop">
            {intl.formatMessage(messages.amountOfContextTypesItems, { amount: context.allowedSymbolTypeIds.length })}
        </Tooltip>
    ) : (
        getMessageNotChosen()
    );
};

export const renderOnObjectInstancesWithBindingModelsRow = (context: AllowedScriptContext | undefined): JSX.Element => {
    const intl = LocalesService.useIntl();
    const title = getChosenModelSymbolsNames(context?.allowedModelSymbols || []);
    if (context && (context.allowAll || context.allowAllSymbols)) {
        return getMessageEvery();
    }

    return context?.allowedModelSymbols?.length ? (
        <Tooltip title={title} placement="leftTop">
            {intl.formatMessage(messages.amountOfContextTypesItems, {
                amount: context.allowedModelSymbols.map((modelSymbol) => modelSymbol.symbolIds).flat().length,
            })}
        </Tooltip>
    ) : (
        getMessageNotChosen()
    );
};

export const renderOnNodesRow = (context: AllowedScriptContext | undefined): JSX.Element => {
    const intl = LocalesService.useIntl();
    const title = getChosenNodesNames(context?.allowedNodeIds || []);
    if (context && context.allowAll) {
        return getMessageEvery();
    }

    return context?.allowedNodeIds?.length ? (
        <Tooltip title={title} placement="leftTop">
            {intl.formatMessage(messages.amountOfContextTypesItems, { amount: context.allowedNodeIds.length })}
        </Tooltip>
    ) : (
        getMessageNotChosen()
    );
};

export const getChildren = (chidren: TreeDataNode[]): TreeDataNode[] => {
    const lastChildren: TreeDataNode[] = [];

    if (chidren) {
        const getLastChildren = (children) => {
            children.forEach((child) => {
                if (child.children?.length) {
                    getLastChildren(child.children);
                } else {
                    lastChildren.push(child);
                }
            });
        };
        getLastChildren(chidren);
    }

    return lastChildren;
};

export const getAllCheckedItems = (
    allItemKeys: string[],
    event: any,
    checkedKeys: string[],
    contextType: scriptContextTypes,
): string[] => {
    const addingItemKeys: TreeDataNode[] =
        event.node.children.length === 0 ? [event.node] : getChildren(event.node.children);
    if (event.node.children.length === 0 && !event.node?.isLeaf) return [...checkedKeys, event.node.key];
    if (contextType === scriptContextTypes.runningOnObjectInstancesWithBindingModelTypes) {
        const keys = addingItemKeys.map((child) =>
            CTreeNodeKey.keyToObject(child.key as string).getSymbolIdAndModelTypeId(),
        );

        return uniq([
            ...(checkedKeys || []),
            ...allItemKeys.filter((itemKey) =>
                keys.includes(CTreeNodeKey.keyToObject(itemKey as string).getSymbolIdAndModelTypeId()),
            ),
        ]);
    }

    return uniq([
        ...checkedKeys,
        ...allItemKeys.filter((itemKey) =>
            [...addingItemKeys.map((child) => CTreeNodeKey.keyToObject(child.key as string).getTypeId())].includes(
                CTreeNodeKey.keyToObject(itemKey as string).getTypeId(),
            ),
        ),
    ]);
};

export const getUncheckedItems = (checkedKeys: string[], event: any, contextType: scriptContextTypes): string[] => {
    if (!checkedKeys) return [];
    const uncheckedItemKeys: TreeDataNode[] =
        event.node.children.length === 0 ? [event.node] : getChildren(event.node.children);
    if (event.node.children.length === 0 && !event.node?.isLeaf)
        return checkedKeys.filter((key) => key !== event.node.key);
    if (contextType === scriptContextTypes.runningOnObjectInstancesWithBindingModelTypes) {
        return [
            ...checkedKeys.filter(
                (itemKey) =>
                    ![
                        CTreeNodeKey.keyToObject(event.node.key as string).getSymbolIdAndModelTypeId(),
                        ...uncheckedItemKeys.map((child) =>
                            CTreeNodeKey.keyToObject(child.key as string).getSymbolIdAndModelTypeId(),
                        ),
                    ].includes(CTreeNodeKey.keyToObject(itemKey as string).getSymbolIdAndModelTypeId()),
            ),
        ];
    }

    return [
        ...checkedKeys.filter(
            (itemKey) =>
                ![
                    CTreeNodeKey.keyToObject(event.node.key as string).getTypeId(),
                    ...uncheckedItemKeys.map((child) => CTreeNodeKey.keyToObject(child.key as string).getTypeId()),
                ].includes(CTreeNodeKey.keyToObject(itemKey as string).getTypeId()),
        ),
    ];
};

export function getAllTreeNodes(node: IDataNodeWithGrops): IDataNodeWithGrops[] {
    let nodes: IDataNodeWithGrops[] = [];

    if (node.children) {
        node.children.forEach((childNode) => {
            nodes = [...nodes, ...getAllTreeNodes(childNode)];
        });
    }

    return [...nodes, node];
}

export const getCheckedTreeChildren = (treeData: TreeDataNode[], checkedItems: string[]): IDataNodeWithGrops[] => {
    const flatTree = treeData.map((treePart) => getAllTreeNodes(treePart)).flat(1);

    return flatTree.filter((node) => node.id && checkedItems.includes(node.key as string));
};

export const getAllItemsKeysList = (treeData: IDataNodeWithGrops[]): string[] => {
    return treeData.flatMap((treePart) => getAllTreeNodes(treePart)).map((tree) => tree.key as string);
};

export const prepareToSaveKeys = (
    treeData: TreeDataNode[],
    checkedItems: string[] | undefined,
    defaultCheckedKeys: string[],
    contextType: scriptContextTypes,
    setItemsToPermit: (data: string[] | AllowedScriptModelSymbols[]) => void,
) => {
    if (contextType === scriptContextTypes.runningOnObjectInstancesWithBindingModelTypes) {
        // когда в дерево не внесено ни одного изменения, то checkedItems=underfined,
        // в этом кейсе сохраняем defaultCheckedKeys - соответствущие ключи из AllowScriptContext
        const items = getCheckedTreeChildren(treeData, checkedItems || defaultCheckedKeys);
        const models = uniq(items.map((child) => CTreeNodeKey.keyToObject(child.key as string).getModelTypeId()));
        const dataToSave: AllowedScriptModelSymbols[] = models.map((modelId) => {
            return {
                modelTypeIds: modelId,
                symbolIds: uniq(
                    items
                        .filter((item) => CTreeNodeKey.keyToObject(item.key as string).getModelTypeId() === modelId)
                        .map((item) => CTreeNodeKey.keyToObject(item.key as string).getSymbolId()),
                ),
            };
        });
        setItemsToPermit(dataToSave);
    } else {
        setItemsToPermit([
            ...uniq(
                // когда в дерево не внесено ни одного изменения, то checkedItems=underfined,
                // в этом кейсе сохраняем defaultCheckedKeys - соответствущие ключи из AllowScriptContext
                getCheckedTreeChildren(treeData, checkedItems || defaultCheckedKeys).map((el) =>
                    CTreeNodeKey.keyToObject(el.key as string).getTypeId(),
                ),
            ),
        ]);
    }
};

export const getTypeByContextType = (contextType: scriptContextTypes, typeId: string) => {
    switch (contextType) {
        case scriptContextTypes.runningOnEdges: {
            return { edgeTypeId: typeId };
        }
        case scriptContextTypes.runningOnFolders: {
            return { folderTypeId: typeId };
        }
        case scriptContextTypes.runningOnObjects: {
            return { objectTypeId: typeId };
        }
        case scriptContextTypes.runningOnModels: {
            return { modelTypeId: typeId };
        }
        case scriptContextTypes.runningOnObjectInstances: {
            return { objectTypeId: typeId };
        }
        case scriptContextTypes.runningOnObjectInstancesWithBindingModelTypes: {
            return { modelTypeId: typeId };
        }
        default:
            return {};
    }
};

export const getGroupByContextType = (contextType: scriptContextTypes, groupId: string) => {
    switch (contextType) {
        case scriptContextTypes.runningOnEdges: {
            return { edgeGroupId: groupId };
        }
        case scriptContextTypes.runningOnFolders: {
            return { folderGroupId: groupId };
        }
        case scriptContextTypes.runningOnObjects: {
            return { objectGroupId: groupId };
        }
        case scriptContextTypes.runningOnModels: {
            return { modelGroupId: groupId };
        }
        case scriptContextTypes.runningOnObjectInstancesWithBindingModelTypes: {
            return { modelGroupId: groupId };
        }
        case scriptContextTypes.runningOnObjectInstances: {
            return { objectGroupId: groupId };
        }
        default:
            return {};
    }
};
