import type { StyledAttributeType } from '../../../models/bpm/bpm-model-impl';
import type {
    TMetodologyEdgeAttributesEditorContainerOwnProps,
    TFloatingAttributesDialogProps,
} from '../FloatingAttributes.types';
import { connect } from 'react-redux';
import { edgeDisabledAreas } from '../FloatingAttributes.constants';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { FloatingAttributesDialog } from '../components/FloatingAttributesDialog.component';
import { updateModelTypeWithFloatingAttributesStyles } from '../../../actions/methodologySetting.actions';
import { LocalesService } from '../../../services/LocalesService';
import { getStyledAttributeTypes } from '../FloatingAttributes.utils';

const mapStateToProps = (
    _,
    props: TMetodologyEdgeAttributesEditorContainerOwnProps,
): Partial<TFloatingAttributesDialogProps> => {
    const { visible, instanceAttributes, definitionAttributes, presetStyles, edgeType, modelType } = props;
    const styledAttributeTypes = getStyledAttributeTypes(
        presetStyles,
        definitionAttributes,
        instanceAttributes,
        modelType.attributes,
    );
    const elementName = LocalesService.internationalStringToString(edgeType?.multilingualName);

    return {
        visible,
        styledAttributeTypes,
        disabledAreas: edgeDisabledAreas,
        elementName,
        presetId: modelType.presetId,
    };
};
const mapDispatchToProps = (dispatch, ownProps: TMetodologyEdgeAttributesEditorContainerOwnProps) => {
    return {
        onCancel() {
            dispatch(closeDialog(DialogType.EDIT_EDGE_TYPE_FLOATING_ATTRIBUTES_DIALOG));
        },
        onChange(attributeTypes: StyledAttributeType[]) {
            dispatch(
                updateModelTypeWithFloatingAttributesStyles({
                    attributeTypes,
                    serverNode: ownProps.serverNode,
                    edgeType: ownProps.edgeType,
                    modelType: ownProps.modelType,
                }),
            );
            dispatch(closeDialog(DialogType.EDIT_EDGE_TYPE_FLOATING_ATTRIBUTES_DIALOG));
        },
    };
};

export const EdgeTypeFloatingAttributesDialogContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FloatingAttributesDialog);
