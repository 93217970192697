import { BPMMxGraph } from '../bpmgraph';
import { MxCell, MxConstants, MxUtils, MxStackLayout, MxLayoutManager, MxEventObject, MxEvent } from '../mxgraph';
import { psdCellType } from '../psdDiagram/psdTable';
import { LayoutInstanceImpl } from '../../models/bpm/bpm-model-impl';
import { v4 as uuid } from 'uuid';

export class BPMN2Diagram {
    graph: BPMMxGraph;

    constructor(graph: BPMMxGraph) {
        this.graph = graph;
        const model = graph.getModel();

        const layout = new MxStackLayout(graph, false);
        layout.resizeParent = true;
        layout.fill = true;
        layout.isVertexIgnored = (vertex: MxCell) => {
            return !graph.isSwimlane(vertex) || graph.isPool(vertex);
        };

        const layoutMgr = new MxLayoutManager(graph);

        layoutMgr.getLayout = (parentCell: MxCell, e: MxEventObject, childCell: MxCell) => {
            if (e === MxEvent.MOVE_CELLS && childCell && childCell.value.type !== 'layout') {
                return null;
            }

            if (
                !model.isEdge(parentCell) &&
                graph.getModel().getChildCount(parentCell) > 0 &&
                (model.getParent(parentCell) === model.getRoot() || graph.isPool(parentCell))
            ) {
                layout.fill = graph.isPool(parentCell);

                return layout;
            }

            return null;
        };
    }

    styleLabel(label: MxCell) {
        label.setStyle(MxUtils.setStyle(label.getStyle(), MxConstants.STYLE_SHAPE, 'shape'));
    }

    insertPoolSymbol(parent: MxCell, id: string, x: number, y: number) {
        const graph = this.graph;

        const pool = graph.insertVertex(parent, id, 'Pool 1', x, y, 640, 0, 'pool');
        pool.setConnectable(true);
        const layoutInstance = new LayoutInstanceImpl({
            type: 'layout',
            isPSDCell: true,
            id: pool.getId(),
            psdCellMetaInfo: {
                name: 'Pool 1',
                allowedSymbols: [],
                type: psdCellType.BPMN_POOL,
                rowIndex: 0,
                columnIndex: 0,
            },
            style: '',
        });
        pool.setValue(layoutInstance);

        const lane1 = graph.insertVertex(pool, uuid(), 'Lane A', x, y, 640, 110, 'swimlane;horizontal=0;');
        lane1.setConnectable(false);
        const layoutInstanceLane1 = new LayoutInstanceImpl({
            type: 'layout',
            isPSDCell: true,
            id: lane1.getId(),
            psdCellMetaInfo: {
                name: 'Lane A',
                allowedSymbols: [],
                type: psdCellType.BPMN_LANE,
                rowIndex: 0,
                columnIndex: 0,
            },
            style: 'swimlane',
        });
        lane1.setValue(layoutInstanceLane1);

        const lane2 = graph.insertVertex(pool, uuid(), 'Lane B', 0, 0, 640, 110, 'swimlane;horizontal=0;');
        lane2.setConnectable(false);
        const layoutInstanceLane2 = new LayoutInstanceImpl({
            type: 'layout',
            isPSDCell: true,
            id: lane2.getId(),
            psdCellMetaInfo: {
                name: 'Lane B',
                allowedSymbols: [],
                type: psdCellType.BPMN_LANE,
                rowIndex: 0,
                columnIndex: 0,
            },
            style: 'swimlane',
        });
        lane2.setValue(layoutInstanceLane2);

        const lane3 = graph.insertVertex(pool, uuid(), 'Lane C', 0, 0, 640, 110, 'swimlane;horizontal=0;');
        lane3.setConnectable(false);
        const layoutInstanceLane3 = new LayoutInstanceImpl({
            type: 'layout',
            isPSDCell: true,
            id: lane3.getId(),
            psdCellMetaInfo: {
                name: 'Lane C',
                allowedSymbols: [],
                type: psdCellType.BPMN_LANE,
                rowIndex: 0,
                columnIndex: 0,
            },
            style: 'swimlane',
        });
        lane3.setValue(layoutInstanceLane3);

        return pool;
    }

    insertLaneSymbol(parent: MxCell, id: string, x: number, y: number) {
        const graph = this.graph;

        const lane = graph.insertVertex(parent, uuid(), 'New Lane', x, y, 640, 110, 'swimlane;horizontal=0;');
        lane.setConnectable(false);
        const layoutInstanceLane1 = new LayoutInstanceImpl({
            type: 'layout',
            isPSDCell: true,
            id: lane.getId(),
            psdCellMetaInfo: {
                name: 'New Lane',
                allowedSymbols: [],
                type: psdCellType.BPMN_LANE,
                rowIndex: 0,
                columnIndex: 0,
            },
            style: 'swimlane',
        });
        lane.setValue(layoutInstanceLane1);

        return lane;
    }
}
