import type { TRestoreDeletedNodeDialogProps } from './RestoreDeletedNodeDialog.types';
import type { TreeNode } from '@/models/tree.types';
import { Button, Checkbox, Form, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import messages from './RestoreDeletedNodeDialog.messages';
import theme from './RestoreDeletedNodeDialog.scss';
import { EditableText } from '@/modules/UIKit/components/EditableText/EditableText.component';
import { EditOutlined } from '@ant-design/icons';
import cx from 'classnames';

export function RestoreDeletedNodeDialog(props: TRestoreDeletedNodeDialogProps) {
    const {
        visible,
        nodeId,
        nodeId: { serverId, repositoryId },
        onClose,
        onSubmit,
        onChooseParent,
    } = props;
    const [restoreChildren, setRestoreChildren] = useState(false);
    const [restoreParent, setRestoreParent] = useState(true);
    const [restoreModelElements, setRestoreModelElements] = useState(true);
    const [restoreOnlySameDeleteOperation, setRestoreOnlySameDeleteOperation] = useState(false);
    const [newParentNode, setNewParentNode] = useState<TreeNode | null>(null);

    const intl = useIntl();
    const footer = (
        <div>
            <Button
                data-test="restore-deleted-object-window_restore_button"
                key="ok"
                size="large"
                type="primary"
                onClick={() =>
                    onSubmit({
                        nodeId,
                        newParentNodeId: !restoreParent && newParentNode ? newParentNode.nodeId : null,
                        restoreChildren,
                        restoreParent,
                        restoreModelElements,
                        restoreOnlySameDeleteOperation,
                    })
                }
            >
                {intl.formatMessage(messages.restore)}
            </Button>
            <Button data-test="restore-deleted-object-window_cancel_button" key="cancel" size="large" onClick={onClose}>
                {intl.formatMessage(messages.cancel)}
            </Button>
        </div>
    );

    const handleChooseParent = () =>
        !restoreParent &&
        onChooseParent({
            serverId,
            repositoryId,
            onSubmit: (nodeId, node) => setNewParentNode(node),
            onClear: () => setNewParentNode(null),
        });

    const newParentDisabled = !!restoreParent;

    return (
        <>
            <Dialog
                footer={footer}
                onCancel={onClose}
                open={visible}
                title={intl.formatMessage(messages.title)}
                width="520px"
            >
                <Form autoComplete="off">
                    <Form.Item
                        className={theme.checkbox}
                        valuePropName="checked"
                        initialValue={restoreChildren}
                        name="restoreChildren"
                    >
                        <Checkbox
                            data-test="check-box_restore_children"
                            onChange={(e) => setRestoreChildren(e.target.checked)}
                        >
                            {intl.formatMessage(messages.restoreChildren)}
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        className={theme.checkbox}
                        valuePropName="checked"
                        initialValue={restoreParent}
                        name="restoreParent"
                    >
                        <Checkbox
                            data-test="check-box_restore-parent"
                            onChange={(e) => setRestoreParent(e.target.checked)}
                        >
                            {intl.formatMessage(messages.restoreParent)}
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        className={theme.checkbox}
                        valuePropName="checked"
                        initialValue={restoreModelElements}
                        name="restoreModelElements"
                    >
                        <Checkbox
                            data-test="check-box_restore-model-elements"
                            onChange={(e) => setRestoreModelElements(e.target.checked)}
                        >
                            {intl.formatMessage(messages.restoreModelElements)}
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        className={theme.checkbox}
                        valuePropName="checked"
                        initialValue={restoreOnlySameDeleteOperation}
                        name="restoreOnlySameDeleteOperation"
                    >
                        <Checkbox
                            data-test="check-box_restore-only-same-delete-operation"
                            onChange={(e) => setRestoreOnlySameDeleteOperation(e.target.checked)}
                        >
                            {intl.formatMessage(messages.restoreOnlySameDeleteOperation)}
                        </Checkbox>
                    </Form.Item>
                    <Form.Item className={theme.chooseNewParent}>
                        <span>{intl.formatMessage(messages.chooseNewParent)}</span>
                        <Tooltip
                            mouseLeaveDelay={0}
                            title={newParentDisabled && intl.formatMessage(messages.chooseNewParentDisabled)}
                        >
                            <div
                                className={cx(
                                    theme.editableTextContainer,
                                    newParentDisabled && theme.disabledEditableTextContainer,
                                )}
                            >
                                <EditableText
                                    className={theme.newParentName}
                                    text={newParentNode?.name || ''}
                                    disabled={newParentDisabled}
                                    isUrlType
                                    onClickLink={handleChooseParent}
                                />
                                <Button
                                    disabled={newParentDisabled}
                                    icon={<EditOutlined />}
                                    onClick={handleChooseParent}
                                    data-test="button_choose-new-parent"
                                />
                            </div>
                        </Tooltip>
                    </Form.Item>
                </Form>
            </Dialog>
        </>
    );
}
