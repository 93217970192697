import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import messages from '../CommonToolbar.messages';
import icImage from 'icons/toolbar/controls/ic-attach-image.svg';
import { ToolbarButton } from 'UIKit';
import { insertAtomicBlock } from '../../common/editorState.utils';
import ControlsContext from '../Controls.context';
import { TRichEditorImage } from '../Toolbar.types';
import { useSharedState } from '../UseSharedState.hook';

const getChangedBlock = (editorState, image: TRichEditorImage) => {
    const entityKey = editorState.getCurrentContent().createEntity('IMAGE', 'MUTABLE', image).getLastCreatedEntityKey();

    return insertAtomicBlock(editorState, entityKey, ' ');
};

const ImageComponent = ({ openDialog }) => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState } = useSharedState<string, TRichEditorImage>({ stateObserver, getChangedBlock });

    const onRevieveImage = (image: TRichEditorImage) => setCurrentState(image);

    return (
        <ToolbarButton
            tooltip={intl.formatMessage(messages.insertImage)}
            spriteSymbol={icImage}
            onClick={() => openDialog(onRevieveImage)}
            dataTest="wiki_add-image_button"
        />
    );
};

export default ImageComponent;
