import React, { useCallback } from 'react';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { Button } from 'antd';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { useIntl } from 'react-intl';
import messages from './ConfirnationDialog.messages';

type TConfirmationDialogProps = {
    onSubmit: () => void;
    title: string;
    question: string;
    OKButtonText: string;
};

export const ConfirmationDialog = ({ onSubmit, title, question, OKButtonText }: TConfirmationDialogProps) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const onClose = useCallback(() => {
        dispatch(closeDialog(DialogType.CONFIRMATION));
    }, []);

    const handleSubmit = () => {
        onSubmit();
        onClose();
    };

    const footer = [
        <Button data-test="cancel-delete-comment_btn" key="cancel" size="large" onClick={onClose}>
            <div>{intl.formatMessage(messages.cancel)}</div>
        </Button>,
        <Button
            data-test="confirm-delete-comment_btn"
            key="ok"
            size="large"
            type="primary"
            danger
            onClick={handleSubmit}
        >
            {OKButtonText}
        </Button>,
    ];

    return (
        <Dialog visible onOk={handleSubmit} onCancel={onClose} title={title} footer={footer}>
            <div>{question}</div>
        </Dialog>
    );
};
