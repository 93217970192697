import React, { FC, useCallback, useMemo } from 'react';
import icAlignLeft from '../../../../../../resources/icons/ic-align-left.svg';
import icAlignCenterVert from '../../../../../../resources/icons/ic-align-center-vert.svg';
import icAlignRight from '../../../../../../resources/icons/ic-align-right.svg';
import icAlignCenterHorz from '../../../../../../resources/icons/ic-align-center-horz.svg';
import icDistributeHor from '../../../../../../resources/icons/ic-distribute-hor.svg';
import icDistributeVert from '../../../../../../resources/icons/ic-distribute-vert.svg';
import { createCompactableSelect } from '../../../CompactableSelect/CompactableSelect.component';
import { TCompactableSelectItem, TCompactableSelectItemType } from '../../../CompactableSelect/CompactableSelect.types';
import { Alignment } from '../../../../../../models/alignment';
import messages from './AlignSelect.messages';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { alignAction, distributeAction } from '../../../../../../actions/editor.actions';
import menuItemTheme from '../../../items/MenuItem.scss';

interface IAlignSelectProps {
    compact?: boolean;
    disabled?: boolean;
    disabledTitle?: string;
    minThreeObjectsSelected: boolean;
}

const AlignmentSelect = createCompactableSelect<Alignment>({ rotateSymbol: true });

export const AlignSelect: FC<IAlignSelectProps> = (props) => {
    const { disabledTitle, disabled, compact, minThreeObjectsSelected } = props;
    const dispatch = useDispatch();
    const intl = useIntl();

    const setAlign = (alignment: Alignment) => {
        dispatch(alignAction(alignment));
    };
    const setDistribute = (isHorizontal: boolean) => {
        dispatch(distributeAction(isHorizontal));
    };

    const onSelect = useCallback(
        (value: Alignment) => {
            if (value === Alignment.DistributeHor) {
                setDistribute(true);
            }
            if (value === Alignment.DistributeVert) {
                setDistribute(false);
            }
            setAlign(value);
        },
        [setDistribute, setAlign],
    );

    const items: TCompactableSelectItem<Alignment>[] = useMemo(
        () => [
            {
                type: TCompactableSelectItemType.Value,
                value: Alignment.Left,
                label: intl.formatMessage(messages.left),
                spriteSymbol: icAlignLeft,
            },
            {
                type: TCompactableSelectItemType.Value,
                value: Alignment.CenterVert,
                label: intl.formatMessage(messages.centered),
                spriteSymbol: icAlignCenterVert,
            },
            {
                type: TCompactableSelectItemType.Value,
                value: Alignment.Right,
                label: intl.formatMessage(messages.right),
                spriteSymbol: icAlignRight,
            },
            {
                type: TCompactableSelectItemType.Divider,
            },
            {
                type: TCompactableSelectItemType.Value,
                value: Alignment.Top,
                label: intl.formatMessage(messages.top),
                spriteSymbol: icAlignLeft,
            },
            {
                type: TCompactableSelectItemType.Value,
                value: Alignment.CenterHorz,
                label: intl.formatMessage(messages.middle),
                spriteSymbol: icAlignCenterHorz,
            },
            {
                type: TCompactableSelectItemType.Value,
                value: Alignment.Bottom,
                label: intl.formatMessage(messages.bottom),
                spriteSymbol: icAlignRight,
            },
            {
                type: TCompactableSelectItemType.Divider,
            },
            {
                type: TCompactableSelectItemType.Value,
                value: Alignment.DistributeHor,
                label: intl.formatMessage(messages.horizontally),
                spriteSymbol: icDistributeHor,
                disabled: !minThreeObjectsSelected,
            },
            {
                type: TCompactableSelectItemType.Value,
                value: Alignment.DistributeVert,
                label: intl.formatMessage(messages.vertically),
                spriteSymbol: icDistributeVert,
                disabled: !minThreeObjectsSelected,
            },
        ],
        [minThreeObjectsSelected],
    );

    return (
        <div data-test="graph-general-toolbar_align-button" className={menuItemTheme.tooltipContainer}>
            <AlignmentSelect
                placement="top"
                title={intl.formatMessage(messages.title)}
                disabledTitle={disabledTitle || intl.formatMessage(messages.onlyInEditModeAvailable)}
                compact={compact}
                items={items}
                disabled={disabled}
                value={Alignment.Left}
                onSelect={onSelect}
            />
        </div>
    );
};

AlignSelect.defaultProps = {
    compact: false,
};
