export const TREE_ITEM_COLLAPSE_SUCCESS = 'TREE_ITEM_COLLAPSE_SUCCESS';

export const TREE_ITEM_COLLAPSE_ALL = 'TREE_ITEM_COLLAPSE_ALL';

export const TREE_ITEM_EXPAND = 'TREE_ITEM_EXPAND';

export const TREE_ITEM_EXPANDING = 'TREE_ITEM_EXPANDING';

export const TREE_ITEM_EXPAND_SUCCESS = 'TREE_ITEM_EXPAND_SUCCESS';

export const TREE_ITEMS_EXPAND_WITHOUT_LOAD = 'TREE_ITEMS_EXPAND_WITHOUT_LOAD';

export const TREE_ITEM_UPDATE = 'TREE_ITEM_UPDATE';

export const NODE_PROPERTIES_UPDATE = 'NODE_PROPERTIES_UPDATE';

export const TREE_ITEM_TITLE_CHANGE_REQUEST = 'TREE_ITEM_TITLE_CHANGE_REQUEST';

export const TREE_ITEM_PROPERTIES_CHANGE_REQUEST = 'TREE_ITEM_PROPERTIES_CHANGE_REQUEST';

export const TREE_ITEM_ADD = 'TREE_ITEM_ADD';

export const TREE_ITEM_CHILD_ADD = 'TREE_ITEM_CHILD_ADD';

export const TREE_ITEM_FETCH_CHILD_REQUEST = 'TREE_ITEM_FETCH_CHILD_REQUEST';

export const TREE_ITEM_FETCH_CHILD_SUCCESS = 'TREE_ITEM_FETCH_CHILD_SUCCESS';

export const TREE_ITEM_FETCH_CHILD_FAILURE = 'TREE_ITEM_FETCH_CHILD_FAILURE';

export const TREE_PART_FETCH_REQUEST = 'TREE_PART_FETCH_REQUEST';

export const TREE_PART_FETCH_ALL_SCRIPTS_REQUEST = 'TREE_PART_FETCH_ALL_SCRIPTS_REQUEST';

export const TREE_PART_FETCH_SUCCESS = 'TREE_PART_FETCH_SUCCESS';

export const TREE_PART_FETCH_FAILURE = 'TREE_PART_FETCH_FAILURE';

export const TREE_ITEM_CONTEXT_MENU_ACTION = 'TREE_ITEM_CONTEXT_MENU_ACTION';

export const TREE_ITEM_OPEN_PROPERTY_ACTION = 'TREE_ITEM_OPEN_PROPERTY_ACTION';

export const TREE_ITEM_SECONDARY_ACTION = 'TREE_ITEM_SECONDARY_ACTION';

export const TREE_ITEM_RENAME_REQUEST = 'TREE_ITEM_RENAME_REQUEST';

export const TREE_ITEM_RENAME_CANCEL = 'TREE_ITEM_RENAME_CANCEL';

export const TREE_ITEM_DELETE = 'TREE_ITEM_DELETE';

export const TREE_ITEM_DELETE_NODE_SERVER = 'TREE_ITEM_DELETE_NODE_SERVER';

export const TREE_ITEM_DELETE_FROM_SERVER = 'TREE_ITEM_DELETE_FROM_SERVER';

export const TREE_ITEM_DELETE_FROM_SERVER_REQUEST = 'TREE_ITEM_DELETE_FROM_SERVER_REQUEST';

export const TREE_ITEM_ERASE_REQUEST = 'TREE_ITEM_ERASE_REQUEST';

export const TREE_ITEM_SELECT = 'TREE_ITEM_SELECT';

export const TREE_ITEMS_SELECT_SLICE = 'TREE_ITEMS_SELECT_SLICE';

export const TREE_ITEMS_SELECT_SEVERAL = 'TREE_ITEMS_SELECT_SEVERAL';

export const TREE_ITEMS_CLEAR_SELECTION = 'TREE_ITEMS_CLEAR_SELECTION';

export const TREE_ITEM_SCROLL = 'TREE_ITEM_SCROLL';

export const TREE_ITEM_START_DRAG = 'TREE_ITEM_START_DRAG';

export const TREE_ITEM_END_DRAG = 'TREE_ITEM_END_DRAG';

export const TREE_ITEM_CLICK_DRAG_ICON = 'TREE_ITEM_CLICK_DRAG_ICON';

export const TREE_ITEM_REFRESH = 'TREE_ITEM_REFRESH';

export const TREE_IMPORT_NODE = 'TREE_IMPORT_NODE';

export const TREE_EXPORT_NODE = 'TREE_EXPORT_NODE';

export const TREE_ITEM_MANAGE_PERMISSIONS_REQUEST = 'TREE_ITEM_MANAGE_PERMISSIONS_REQUEST';

export const TREE_FILTER = 'TREE_FILTER';

export const SET_SHOW_DELETED_OBJECTS_FILTER_REQUEST = 'SET_SHOW_DELETED_OBJECTS_FILTER_REQUEST';

export const SET_SHOW_DELETED_OBJECTS_FILTER_SUCCESS = 'SET_SHOW_DELETED_OBJECTS_FILTER_SUCCESS';

export const TREE_ITEM_LOAD_VISIBLE_NODES = 'TREE_ITEM_LOAD_VISIBLE_NODES';

export const TREE_ITEM_MOVE = 'TREE_ITEM_MOVE';

export const CONTEXT_MENU_DIVIDER = 'CONTEXT_MENU_DIVIDER';

export const CONTEXT_MENU_ACTION = 'CONTEXT_MENU_ACTION';

export const OPEN_CHOOSE_TREE_NODE = 'OPEN_CHOOSE_TREE_NODE';

export const TREE_ITEM_UML_CLASS_OBJECT_SAVE = 'TREE_ITEM_UML_CLASS_OBJECT_SAVE';

export const TREE_ITEM_UML_OBJECTS_UPDATE = 'TREE_ITEM_UML_OBJECTS_UPDATE';

export const TREE_NODE_SUBSCRIBE = 'TREE_NODE_SUBSCRIBE';

export const TREE_NODE_UNSUBSCRIBE = 'TREE_NODE_UNSUBSCRIBE';

export const TREE_NODE_ADD_SUBSCRIBTION_TO_STORE = 'TREE_NODE_ADD_SUBSCRIBTION_TO_STORE';

export const TREE_NODE_DELETE_SUBSCRIBTION_FROM_STORE = 'TREE_NODE_DELETE_SUBSCRIBTION_FROM_STORE';
