import { defineMessages } from 'react-intl';

export default defineMessages({
    defaultModelName: {
        id: 'GridDiagram.modelName',
        defaultMessage: 'gridModel',
    },
    addColumn: {
        id: 'GridDiagram.AddColumn',
        defaultMessage: 'Добавить колонку',
    },
    removeColumn: {
        id: 'GridDiagram.RemoveColumn',
        defaultMessage: 'Удалить колонку',
    },
    addRow: {
        id: 'GridDiagram.AddRow',
        defaultMessage: 'Добавить строку',
    },
    removeRow: {
        id: 'GridDiagram.RemoveRow',
        defaultMessage: 'Удалить строку',
    },
});
