import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { Form, Input } from 'antd';
import messages from './WikiLinkDialog.messages';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { TFormValues, TLinkDialogProps } from './WikiLinkDialog.types';
import theme from './WikiLinkDialog.scss';
import { TreeSelectors } from '../../../selectors/tree.selectors';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import { TreeNode, TTreeEntityState } from '../../../models/tree.types';
import { openChooseTreeNode, treeItemSelect, treeItemsClearSelection } from '../../../actions/tree.actions';
import { NodeId } from '../../../serverapi/api';
import { workspaceTabSetParams } from '../../../actions/tabs.actions';
import { closeDialog, openDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { prepareUrl } from '../../UIKit/components/EditableText/editableText.utils';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import iconSubmenu from '../../../resources/icons/ic-sidemenu-navigator.svg';
import { TreeItemType } from '../../Tree/models/tree';
import { TWikiLink } from '@/models/tab.types';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';

type TLinkDialogAllProps = WrappedComponentProps & TLinkDialogProps;

const LinkDialogComponent = (props: TLinkDialogAllProps) => {
    const { intl } = props;
    const dispatch = useDispatch();
    const selected: TreeNode | undefined = useSelector(SelectedNodesSelector.getNode());
    const activeScheme = useSelector(TabsSelectors.getActiveTab);
    const node: TTreeEntityState | undefined = useSelector(TreeSelectors.itemById(selected?.nodeId));
    const workspaceId: NodeId | undefined = activeScheme && activeScheme.nodeId;
    const [form] = Form.useForm();
    const emptyTreeNode = {
        nodeId: {
            serverId: '',
            id: '',
            repositoryId: '',
        },
        name: '',
        type: '' as TreeItemType,
        hasChildren: false,
        countChildren: 0,
    };

    useEffect(() => {
        const url = selected?.name ? `bpm://${selected?.name}` : '';
        form.setFieldsValue({ url });
    }, [selected]);

    useEffect(() => {
        dispatch(treeItemSelect(emptyTreeNode));
        form.resetFields();
    }, [form]);

    const onClose = () => {
        dispatch(closeDialog(DialogType.LINK_DIALOG));
        dispatch(treeItemsClearSelection());
    };

    const onSubmit = (workspaceNodeId: NodeId | undefined, link: TWikiLink) => {
        if (workspaceNodeId) {
            dispatch(
                workspaceTabSetParams(workspaceNodeId, {
                    selectionProps: {
                        link,
                    },
                }),
            );
            onClose();
            dispatch(treeItemSelect(emptyTreeNode));
        }
    };

    const handleSubmit = () => {
        if (form) {
            form.validateFields().then((formValues: TFormValues) => {
                onSubmit(workspaceId, {
                    url: prepareUrl(formValues, node),
                    text: formValues.text,
                } as TWikiLink);
            });
        }
    };
    const openChooseTreeNodeDialog = (serverId: string, repositoryId: string) => {
        dispatch(
            openDialog(DialogType.TREE_ITEM_SELECT_DIALOG, {
                serverId,
                repositoryId,
                onSubmit: (nodeId: NodeId) => {
                    dispatch(openChooseTreeNode(nodeId));
                },
                disableContextMenu: true,
            }),
        );
    };

    const children = (
        <Form form={form}>
            <Form.Item
                label={intl.formatMessage(messages.linkUrlLabel)}
                name="url"
                rules={[{ required: true, message: intl.formatMessage(messages.formValidationMessageRequiredUrl) }]}
            >
                <Input
                    addonAfter={
                        <div
                            className={theme.iconContainer}
                            data-test="wiki-toolbar-group_add-link-button_add-tree-node"
                        >
                            <Icon
                                spriteSymbol={iconSubmenu}
                                onClick={() => {
                                    if (workspaceId) {
                                        openChooseTreeNodeDialog(workspaceId.serverId, workspaceId.repositoryId);
                                    }
                                }}
                            />
                        </div>
                    }
                    data-test="wiki-toolbar-group_add-link-button_add-url-link"
                />
            </Form.Item>

            <Form.Item
                label={intl.formatMessage(messages.linkTextLabel)}
                name="text"
                rules={[
                    {
                        required: true,
                        message: intl.formatMessage(messages.formValidationMessageRequiredLabel),
                    },
                ]}
            >
                <Input data-test="wiki-toolbar-group_add-link-button_add-text-link" />
            </Form.Item>
        </Form>
    );

    return (
        <Dialog
            onOk={handleSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.formName)}
            visible
            width={DEFAULT_DIALOG_WIDTH}
            okText={intl.formatMessage(messages.formConfirmButton)}
            cancelText={intl.formatMessage(messages.formDeclineButton)}
        >
            {children}
        </Dialog>
    );
};

const LinkDialogWithIntl = injectIntl(LinkDialogComponent);

export { LinkDialogWithIntl as LinkDialog };
