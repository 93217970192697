import type { TBPMMxGraphOptions } from '../bpmgraph.types';
import { EditorMode } from '@/models/editorMode';
import { isNullOrUndefined } from 'is-what';
import { BPMMxGraphModel } from '../BPMMxGraphModel.class';
import { DefaultGraph } from '../DefaultGraph';
import { MxPopupMenu, MxCell } from '../mxgraph';
import { GridDiagram } from './grid/GridDiagram';
import { GridLayout } from '@/serverapi/api';

export default class GridModelGraph extends DefaultGraph {
    private grid: GridDiagram;

    constructor(options: TBPMMxGraphOptions & { mode?: EditorMode } = {}) {
        super({
            ...options,
            model: isNullOrUndefined(options.model) ? new BPMMxGraphModel() : options.model,
        });

        const name = this.modelType?.name;
        this.grid = new GridDiagram(this, options.gridLayout, name);
    }

    loadPopupMenu(menu: MxPopupMenu, cell: MxCell, disabled: boolean = false) {
        super.loadPopupMenu(menu, cell);
        this.grid.loadPopupMenu(menu, cell, disabled);
    }
    serializeGrid(): GridLayout {
        return this.grid.serialize();
    }
}
