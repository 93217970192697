import React from 'react';
import { useSelector } from 'react-redux';
import { CommentsSelectors } from '../../../../selectors/comments.selectors';
import { NodeId } from '../../../../serverapi/api';
import theme from './commentCircle.scss';
import cx from 'classnames';

type TCommentCircleProps = {
    modelId: NodeId;
    commentId: string;
    withBoarder?: boolean;
    moveToMarker?: () => void;
    addMarker?: () => void;
};

export const CommentCircle = (props: TCommentCircleProps) => {
    const { modelId, commentId, withBoarder, moveToMarker, addMarker } = props;
    const isOpenCriticalComment: boolean = useSelector(
        CommentsSelectors.checkIsOpenCriticalComment(modelId, commentId),
    );
    const initials: string = useSelector(CommentsSelectors.getAuthorInitials(modelId, commentId));
    const circleBackgroundColor: string = useSelector(
        CommentsSelectors.getCommentCircleBackgroundColor(modelId, commentId),
    );

    return (
        <div
            className={cx(
                isOpenCriticalComment ? theme.circleCritical : theme.circle,
                withBoarder && !isOpenCriticalComment && theme.withBoarder,
            )}
            style={{ background: circleBackgroundColor }}
            data-test="user-comment_circle"
        >
            <span
                className={theme.circleContent}
                draggable
                onClick={() => moveToMarker && moveToMarker()}
                onDragStart={(e) => {
                    e.preventDefault();
                    addMarker && addMarker();
                }}
                data-test="user-comment_initials"
            >
                {initials}
            </span>
        </div>
    );
};
