import { TRootState } from '../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { NodeId } from '../../serverapi/api';

const wikiStateSelector = (state: TRootState) => state.wiki;

export namespace WikiSelectors {
    export const byId = (nodeId: NodeId) => createSelector(wikiStateSelector, (state) => state.data.get(nodeId));
    export const clipboard = () => createSelector(wikiStateSelector, (state) => state.clipboard);
}
