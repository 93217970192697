import { NodeId } from '../../../../serverapi/api';

export enum Locale {
    ru = 'ru',
    en = 'en',
}

export type THeaderProps = {
    locale: Locale;
    onClose: () => void;
    isMaximized: boolean;
    isVisibleButtonActions: boolean;
    isVisibleMainMenu: boolean;
    onToggleSize: () => void;
    onMinimize: () => void;
    onCopyAction?: () => void;
    onPasteAction?: () => void;
    activeKey: NodeId;
    onUndoAction: (activeKey: NodeId) => void;
    onLocaleSelect: (selected: Locale) => void;
    onRedoAction: (activeKey: NodeId) => void;
    undoEnabled: boolean;
    redoEnabled: boolean;
    isStarted: boolean;
    isEnLocaleAllowed: boolean;
    clientIcon: string | null;
} & JSX.IntrinsicAttributes;
