import { createSelector } from 'reselect';
import { PrincipalType } from '../models/security/PrincipalType';
import { TRootState } from '../reducers/root.reducer.types';

export const principalsState = (state: TRootState) => state.principals.data;

export namespace PrincipalsSelectors {
    export const getAll = createSelector(principalsState, (state) => state);

    export const getUsers = createSelector(principalsState, (state) =>
        state.filter((el) => el.principalType === PrincipalType.USER),
    );

    export const getGroups = createSelector(principalsState, (state) =>
        state.filter((el) => el.principalType === PrincipalType.GROUP),
    );

    export const getUserNameById = (id: number) =>
        createSelector(principalsState, (state) => {
            const user = state.find((el) => el.id === id && el.principalType === PrincipalType.USER);
            if (user) {
                return { id: user.id, name: `${user.lastname || ''} ${user.middlename || ''} ${user.name || ''}` };
            }

            return null;
        });

    export const getUsersById = (ids: number[]) =>
        (state: TRootState) => {
            return ids?.map((id) => {
                return getUserNameById(id)(state);
            });
        };
}
