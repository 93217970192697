import type { TCrossFunctionalFlowchartSymbolCustomProps } from '../ComplexSymbol.class.types';
import { MxCell, MxConstants, MxPoint, MxPopupMenu, MxUtils } from 'MxGraph';
import { v4 as uuid } from 'uuid';
import { SymbolTypeId, FRAME_STYLES_INDICATOR } from '../ComplexSymbol.constants';
import { requestProps } from './sideEffects';
import { cellsTreeWalkerDown, createFrameFromString } from '../../utils';
import MetaInfoSerializer from '../serializers/MetaInfoSerializer.class';
import { EditorMode } from '@/models/editorMode';
import messages from '../ComplexSymbol.messages';
import { MxGeometry } from '@/mxgraph/mxgraph';
import { ComplexSymbol } from '../ComplexSymbol.class';
import { getIsTitleHidden } from '../Pool/sideEffects';

type ICrossSymbolColumn = {
    count: number;
    title: string;
};

const defaultProps = {
    width: 120,
    height: 120,
    colTitle: 'Phase',
    rowTitle: 'Actor',
    colCount: 3,
    rowCount: 3,
};

export class CrossFunctionalFlowchartSymbol extends ComplexSymbol {
    complexSymbolTypeId = SymbolTypeId.CROSS;
    serializer = new MetaInfoSerializer();

    public addToGraph() {
        const template = this.rootCellValue.metaInfo;

        if (template) {
            return this.restore(template);
        }

        if (this.customProps) {
            const root = this.insert(this.customProps);

            return root;
        }

        // TODO: validator

        console.error('bad parameters', this);

        const root = this.insert({});

        return root;
    }

    protected isTitleHidden(): boolean {
        return getIsTitleHidden(this.rootCellValue?.symbolId, this.graph.modelType?.symbols);
    }

    protected symbolCustomProps: TCrossFunctionalFlowchartSymbolCustomProps;

    insert(customProps): MxCell {
        const root = this.insertRootContainer();
        this.rootCell = root;

        if (customProps.requestProps) {
            requestProps()
                .then((props) => {
                    this.insertContent(root, props);
                    this.afterCreate(root);
                })
                .catch(() => {
                    this.remove();
                });

            return root;
        }
        this.insertContent(root, customProps);

        this.afterCreate(root);

        return root;
    }

    private insertRootContainer(): MxCell {
        const { x = 0, y = 0 } = this.rootCellValue;
        const parent = this.graph.getDefaultParent();

        const w = defaultProps.width;
        const h = defaultProps.height;
        const startSize = 30;
        const colCount = 2;
        const rowCount = 2;
        const { graph } = this;
        const s = 'html=1;whiteSpace=wrap;collapsible=0;recursiveResize=0;expand=0;pointerEvents=1;';
        const tableStyle = `${FRAME_STYLES_INDICATOR};shape=table;childLayout=tableLayout;rowLines=0;columnLines=0;startSize=${startSize};${s}`;

        const table = graph.createVertex(null, uuid(), '', 0, 0, colCount * w, rowCount * h, tableStyle);
        const t = MxUtils.getValue(
            graph.getCellStyle(table),
            MxConstants.STYLE_STARTSIZE,
            MxConstants.DEFAULT_STARTSIZE,
        );

        table.geometry.width += t;
        table.geometry.height += t;

        const [cells] = this.graph.importCellsWithIds([table], new MxPoint(x, y), parent);
        const [root] = cells;

        return root;
    }

    private insertContent(root, customProps) {
        this.graph.getModel().beginUpdate();
        const { col = 3, row = 3, colTitle, rowTitle, hideHeaders } = customProps;
        const { x = 0, y = 0 } = this.rootCellValue;

        const rawTable = this.createCrossSymbolCells(
            {
                count: col,
                title: hideHeaders ? '' : colTitle,
            },
            {
                count: row,
                title: hideHeaders ? '' : rowTitle,
            },
            hideHeaders ? '0' : '30',
        );

        const { children } = rawTable;

        const rawTableGeometry = rawTable.getGeometry();
        const result = this.graph.importCellsWithIds(children, new MxPoint(0, 0), root);
        const actualGeometry = new MxGeometry(x, y, rawTableGeometry.width, rawTableGeometry.height);
        root.setGeometry(actualGeometry);

        this.graph.layoutManager.getLayout(root).execute(root);
        this.graph.getModel().endUpdate();

        return result;
    }

    private restore(template): MxCell {
        const { parent = this.graph.getDefaultParent() } = this.rootCellValue;
        const clonedModelCells = createFrameFromString(template);
        const [cells] = this.graph.importCellsWithIds(clonedModelCells, new MxPoint(0, 0), parent);
        const [root] = cells;

        this.afterCreate(root);

        return root;
    }

    private setCellDefaultValue(cell: MxCell) {
        cellsTreeWalkerDown([cell], (childCell: MxCell) => {
            if (!childCell.getValue()) childCell.setValue('');
        });
    }

    public loadPopupMenu(menu: MxPopupMenu, cell: MxCell) {
        const { mode } = this.graph;

        menu.addItem(
            this.graph.intl.formatMessage(messages.addLineToFrame),
            null,
            () => this.addLine(cell),
            null,
            '',
            mode === EditorMode.Edit,
        );

        menu.addItem(
            this.graph.intl.formatMessage(messages.removeLineFromFrame),
            null,
            () => this.removeLine(cell),
            null,
            '',
            mode === EditorMode.Edit,
        );

        menu.addItem(
            this.graph.intl.formatMessage(messages.tableSymbolAddRow),
            null,
            () => this.addRow(cell),
            null,
            '',
            mode === EditorMode.Edit,
        );
        menu.addItem(
            this.graph.intl.formatMessage(messages.tableSymbolRemoveRow),
            null,
            () => this.removeRow(cell),
            null,
            '',
            mode === EditorMode.Edit,
        );
    }

    public addLine(cell: MxCell) {
        this.graph.insertTableRow(cell);
        this.setCellDefaultValue(cell);
    }

    public removeLine(cell: MxCell) {
        this.graph.deleteTableRow(cell);
    }

    public addRow(cell: MxCell) {
        this.graph.insertTableColumn(cell, false);
        this.setCellDefaultValue(cell);
    }

    public removeRow(cell: MxCell) {
        this.graph.deleteTableColumn(cell);
    }

    public convertValueToString(cell: MxCell): string {
        if (this.isRootCell(cell)) {
            return super.convertValueToString(cell);
        }

        return cell.getValue();
    }

    private createCrossSymbolCells(
        cols: ICrossSymbolColumn,
        rows: ICrossSymbolColumn,
        startSize = MxConstants.DEFAULT_STARTSIZE,
    ) {
        const { count: colCount = defaultProps.colCount, title: colTitle = defaultProps.colTitle } = cols;
        const { count: rowCount = defaultProps.rowCount, title: rowTitle = defaultProps.rowTitle } = rows;
        const w = defaultProps.width;
        const h = defaultProps.height;
        const { graph } = this;
        const s = 'html=1;whiteSpace=wrap;collapsible=0;recursiveResize=0;expand=0;pointerEvents=1;';
        const tableStyle = `${FRAME_STYLES_INDICATOR};shape=table;childLayout=tableLayout;rowLines=0;columnLines=0;startSize=${startSize};${s}`;

        const rowStyle = `swimlane;horizontal=0;vertical=0;portConstraint=eastwest;startSize=${startSize};movable=0;${s}`;
        const firstCellStyle = `swimlane;connectable=0;startSize=${startSize};${s}`;
        const cellStyle = `swimlane;connectable=0;startSize=0;${s}`;

        const table = graph.createVertex(null, uuid(), '', 0, 0, colCount * w, rowCount * h, tableStyle);
        const t = MxUtils.getValue(
            graph.getCellStyle(table),
            MxConstants.STYLE_STARTSIZE,
            MxConstants.DEFAULT_STARTSIZE,
        );
        const getNumerableTitle = (title: string, index: number) => (title?.length ? `${title} ${index}` : '');

        table.geometry.width += t;
        table.geometry.height += t;

        const firstRow = graph.createVertex(
            null,
            uuid(),
            getNumerableTitle(rowTitle, 1),
            0,
            t,
            colCount * w + t,
            h,
            rowStyle,
        );
        const firstCols = Array(colCount)
            .fill('')
            .map((item, index) =>
                graph.createVertex(null, uuid(), getNumerableTitle(colTitle, index + 1), t, 0, w, h, firstCellStyle),
            );

        table.insert(this.insertToContainer(firstRow, firstCols, w, 0));

        if (rowCount > 1) {
            firstRow.geometry.y = h + t;

            Array(rowCount - 1)
                .fill('')
                .forEach((item, index) => {
                    const row = graph.createVertex(
                        null,
                        uuid(),
                        getNumerableTitle(rowTitle, index + 2),
                        0,
                        t,
                        colCount * w + t,
                        h,
                        rowStyle,
                    );
                    const colsChildren = Array(colCount)
                        .fill('')
                        .map(() => graph.createVertex(null, uuid(), '', t, 0, w, h, cellStyle));

                    const finalRow = this.insertToContainer(row, colsChildren, w, 0);

                    table.insert(finalRow);
                });
        }

        return table;
    }

    private insertToContainer(container, children, dx, dy) {
        const { graph } = this;
        const parent = graph.cloneCell(container);

        parent.setId(container.getId());

        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            const cloned = graph.cloneCell(child);
            cloned.setId(child.getId());
            const geo = graph.getCellGeometry(cloned);

            if (geo != null) {
                geo.x += i * dx;
                geo.y += i * dy;
            }

            parent.insert(cloned);
        }

        return parent;
    }

    private remove() {
        this.graph.removeCells([this.rootCell], true);
    }

    public get isConnectable(): boolean {
        return true;
    }
}
